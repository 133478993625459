// adapters
import { adaptToRequest } from 'adapters/v1/approvers/formData';
import { adaptJsonApiToDetail } from 'adapters/v1/json_api';

export const useInlineUpdates = ({
  approvableUpdateQuery,
  approvers,
  destroy,
  jsonApi,
  onClose,
  resource,
  showParentActivity,
  update,
}) => {
  const mapRelationships = (payload) => {
    if (!jsonApi) return payload;

    payload.relationships = {
      approvers: {
        data: payload.approvers,
        meta: {
          data: 'array',
          type: 'approver',
        },
      },
    };
    return payload;
  };

  const approvable_id = String(approvers?.[0]?.approvable_id);

  const callback = async (formData) => {
    if (!formData || !update) return;

    const adapted = adaptToRequest(formData);
    const resp = await update(resource.id, adapted, true);

    if (!resp.success) return;

    const updatedApprovers = approvers.map((t) => {
      if (t.id === resource.id) {
        return adaptJsonApiToDetail(resp);
      } else return t;
    });

    const payload = {
      id: approvable_id,
      approvers: updatedApprovers,
    };

    approvableUpdateQuery && (await approvableUpdateQuery({ payload: mapRelationships(payload) }));

    showParentActivity && (await showParentActivity(resource.approvable_id));

    onClose && onClose();
  };

  const handleDelete = async () => {
    if (!resource.id || !destroy) return;

    const resp = await destroy(resource.id, true);
    if (!resp.success) return;

    const payload = {
      id: approvable_id,
      approvers: approvers.filter(({ id }) => id !== resource.id),
    };
    approvableUpdateQuery && approvableUpdateQuery({ payload: mapRelationships(payload) });

    onClose && onClose();
  };

  return {
    callback,
    handleDelete,
  };
};

export const getCompleteApprovers = (approvers = []) =>
  approvers.filter(({ approved_at, declined_at }) => approved_at !== null || declined_at !== null);
export const getIncompleteApprovers = (approvers = []) =>
  approvers.filter(({ approved_at, declined_at }) => approved_at === null && declined_at === null);

export const getMyApproval = (approvers = [], userId) =>
  approvers.find(({ user_id }) => user_id === userId);

export const getSortedApprovals = (approvers) =>
  getCompleteApprovers(approvers).sort((a, b) => a.updated_at?.localeCompare(b.updated_at));

export const getRequiredApprovers = (approvers = []) =>
  approvers.filter(({ required }) => required);
export const getOptionalApprovers = (approvers = []) =>
  approvers.filter(({ required }) => !required);
