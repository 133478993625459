import { combineReducers } from 'redux';
import createGeneric from 'store/v1/generic';
import CONST from 'store/v1/authentication/constants';

import authentication from 'store/v1/authentication';
import documents from 'store/v1/documents';
import error from 'store/v1/error';
import feed from 'store/v1/feed';
import flashMessages from 'store/v1/flash-messages';
import forms from 'store/v1/forms';

import inbox from 'store/v2/inbox';
import jpas from 'store/v1/jpas';
import labels from 'store/v1/labels';
import lookups from 'store/v1/lookups';
import profile from 'store/v1/profile';
import signatures from 'store/v1/signatures';
import signatureRequests from 'store/v2/signatureRequests';
import types from 'store/v1/types';
import users from 'store/v1/users';
import version from 'store/v1/version';

// v2
import { analyticsExplores, analyticsReports, analyticsDashboards } from 'store/v1/analytics';
import activity from 'store/v2/activity/reducer';
import assets from 'store/v2/assets/reducer';
import assetControls from 'store/v2/assetControls/reducer';
import assetAudits from 'store/v2/assetAudits/reducer';
import assignableUsers from 'store/v2/assignableUsers/reducer';
import assignmentsV2 from 'store/v2/assignments/reducer';
import audits from 'store/v2/audits/reducer';
import attachments from 'store/v2/attachments/reducer';
import attachedDigitalForms from 'store/v2/attachedDigitalForms/reducer';
import badgeControls from 'store/v2/badgeControls/reducer';
import cardTemplates from 'store/v2/cardTemplates/reducer';
import cards from 'store/v2/cards/reducer';
import clientCredentials from 'store/v2/clientCredentials/reducer';
import contacts from 'store/v2/contacts/reducer';
import containerControls from 'store/v2/containerControls/reducer';
import containerControlUsers from 'store/v2/containerControlUsers/reducer';
import contractsV2 from 'store/v2/contracts/reducer';
import incidents from 'store/v2/incidents/reducer';
import incidentTemplates from 'store/v2/incidentTemplates/reducer';
import library from './v2/library/reducer';
import themes from 'store/v2/themes/reducer';
import messageUsers from 'store/v2/messageUsers/reducer';
import notifications from 'store/v2/notifications/reducer';
import travels from 'store/v2/travels/reducer';
import travelSettings from 'store/v2/travelSettings/reducer';
import travelFormsV2 from 'store/v2/travelForms/reducer';

import boilerplateScaffolding from 'store/v2/boilerplateScaffolding/reducer';

// resourceful assign/unassign patterns
import { contractUsers, contractUsersUnassigned } from 'store/v2/contractUsers/reducer';
import { contractContracts, contractContractsUnassigned } from 'store/v2/contractContracts/reducer';
import accesses from 'store/v2/accesses/reducer';
import customRoles from 'store/v2/customRoles/reducer';
import customFields from 'store/v2/customFields/reducer';
import comments from 'store/v2/comments/reducer';
import controls from 'store/v2/controls/reducer';
import company from 'store/v2/company/reducer';
import documentsV2 from 'store/v2/documents/reducer';
import eligibilities from 'store/v2/eligibilities/reducer';
import facilitiesV2 from 'store/v2/facilities/reducer';
import facilityUsers from 'store/v2/facilityUsers/reducer';
import formTemplates from 'store/v2/formTemplates/reducer';
import imports from 'store/v2/imports/reducer';
import importUnits from 'store/v2/importUnits/reducer';
import integrationJobSettings from 'store/v2/integrationJobSettings/reducer';
import serviceIntegrations from 'store/v2/serviceIntegrations/reducer';
import polygraphs from 'store/v2/polygraphs/reducer';
import requirements from 'store/v2/requirements/reducer';
import reduxManager from 'store/v2/reduxManager/reducer';
import requirementControls from 'store/v2/requirementControls/reducer';
import people from 'store/v2/people/reducer';
import secretKeys from 'store/v2/secretKeys/reducer';
import tagsV2 from 'store/v2/tags/reducer';
import tagNames from 'store/v2/tagNames/reducer';
import tasks from 'store/v2/tasks/reducer';
import trainingsV2 from 'store/v2/training/reducer';
import transferEvents from 'store/v2/transferEvents/reducer';
import destructionEvents from 'store/v2/destructionEvents/reducer';
import receipts from 'store/v2/receipts/reducer';
import riskProfile from 'store/v2/risk_profile/reducer';
import userAssets from 'store/v2/userAssets/reducer';
import userAccesses from 'store/v2/userAccesses/reducer';
import userEligibilities from 'store/v2/userEligibilities/reducer';
import userPolygraphs from 'store/v2/userPolygraphs/reducer';
import visitRequests from 'store/v2/visitRequests/reducer';
import webhookSettings from 'store/v2/webhookSettings/reducer';

// TODO: Remove redundant digitalForms
import digitalFormRequests from 'store/v2/digitalFormRequests/reducer';
import digitalFormTemplates from 'store/v2/digitalFormTemplates/reducer';
import digitalForms from 'store/v2/digitalForms/reducer';

// resourceful assign/unassign patterns
import { userFacilities, userFacilitiesUnassigned } from 'store/v2/userFacilities/reducer';

const reducers = {
  accesses,
  activity,
  analyticsReports,
  analyticsDashboards,
  analyticsExplores,
  assets,
  assetControls,
  assetAudits,
  assignableUsers,
  assignmentsV2,
  attachments,
  attachedDigitalForms,
  audits,
  authentication,
  badgeControls,
  boilerplateScaffolding,
  cards,
  cardTemplates,
  clientCredentials,
  comments,
  contacts,
  containerControls,
  containerControlUsers,
  contractsV2,
  contractContracts,
  contractContractsUnassigned,
  contractUsers,
  contractUsersUnassigned,
  controls,
  company,
  customRoles,
  customFields,
  destructionEvents,
  documents,
  documentsV2,
  digitalForms,
  digitalFormRequests,
  digitalFormTemplates,
  eligibilities,
  error,
  facilitiesV2,
  facilityUsers,
  feed,
  flashMessages,
  forms,
  formTemplates, // forms and formTemplates are technically the same but formTemplates is v2 store
  inbox,
  imports,
  importUnits,
  incidents,
  incidentTemplates,
  integrationJobSettings,
  serviceIntegrations,
  jpas,
  labels,
  library,
  lookups,
  messageUsers,
  notifications,
  people,
  polygraphs,
  profile,
  receipts,
  reduxManager,
  requirements,
  requirementControls,
  riskProfile,
  secretKeys,
  signatures,
  signatureRequests,
  tagsV2,
  tagNames,
  tasks,
  themes,
  trainingsV2,
  transferEvents,
  travelFormsV2,
  travels,
  travelSettings,
  types,
  users,
  userAssets,
  userAccesses,
  userEligibilities,
  userFacilities,
  userFacilitiesUnassigned,
  userPolygraphs,
  version,
  visitRequests,
  webhookSettings,
};

// facilities v1 is in widespread usage.
['facilities', 'roles'].forEach((d) => {
  reducers[d] = createGeneric(d.toUpperCase());
});

const appReducer = combineReducers(reducers);

const index = (state, action) => {
  if (action.type === CONST.LOGIN_SUCCESS || action.type === CONST.LOGOUT_SUCCESS) {
    state = { flashMessages: state.flashMessages };
  }

  return appReducer(state, action);
};

export default index;
