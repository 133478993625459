import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// mappers
import { mapPermissionsToProps } from 'mappers/v1/permissions';
import { mapPreferencesToProps } from 'mappers/v1/preferences';
import { mapTableToPropsFactory, mergePropsToTable } from 'mappers/v1/table';

// actions
import actions from 'store/v2/cardTemplates/actions';
import cardActions from 'store/v2/cards/actions';
import paths from 'config/paths/workflowControls';
import ticketPaths from 'config/paths/tickets';

// adapters
import { cellLinkAdapter, cellAdapter, adaptToDetail } from 'adapters/v1/cardTemplates/formData';
import { adaptToDetail as adaptCardToDetail } from 'adapters/v1/cards/formData';

export const stateBuilder = () => {
  const mapDataToTable = mapTableToPropsFactory('cardTemplates');
  const mapCardsDataToTable = mapTableToPropsFactory('cards');

  const mapResourceToProps = ({ cards, cardTemplates }, ownProps) => {
    const { match, resource } = ownProps;
    const resourceId = match && match.params ? Number(match.params['id']) : resource && resource.id;
    const tickets = Object.values(cards.data).map(adaptCardToDetail);
    return { resourceId, resource: adaptToDetail(cardTemplates.data[resourceId]), tickets };
  };

  const mapStateToProps = ({ profile, people, cardTemplates = {}, cards = {} }) => {
    const { activity, loading, schema } = cardTemplates;
    const { schema: cardSchema } = cards;

    return {
      activity: Object.values(activity || {}),
      cardTemplates: Object.values(cardTemplates.data),
      users: people.dropdown,
      loading: loading || cards.loading,
      profile,
      schema,
      cardSchema,
      ...mapPermissionsToProps({ profile }),
      ...mapPreferencesToProps({ profile }),
    };
  };

  const listInclude = { include: '' };
  const include = {
    include:
      'approvers,attached_digital_forms,attachments,cards,comments,documents,instance_custom_fields,lanes,reviewers,tasks',
  };
  const cardInclude = { include: 'members,created_by,reviewers,submitter' };

  const mapDispatchToProps = {
    ...actions,
    list: () => actions.list({ ...listInclude, showAll: true }),
    show: (id) => actions.show(id, { ...include }),
    create: (id, payload, silent) => actions.create(id, { ...payload, ...include }, silent),
    update: (id, payload, silent) => actions.update(id, { ...payload, ...include }, silent),
    createCard: (id, payload, silent) =>
      cardActions.create(id, { ...payload, ...cardInclude }, silent),
    cardListSchema: cardActions.listSchema,
  };

  const withPaths = (Component) => (props) => (
    <Component paths={paths} ticketPaths={ticketPaths} {...props} />
  );

  return {
    withTableState: (Component) => {
      return connect(
        (store) => ({ ...mapPermissionsToProps(store), ...mapDataToTable(store) }),
        mapDispatchToProps,
        mergePropsToTable
      )(
        withPaths((props) => (
          <Component {...props} cellLinkAdapter={cellLinkAdapter} cellAdapter={cellAdapter} />
        ))
      );
    },
    withFeatureState: (Component) => {
      return connect(mapStateToProps, mapDispatchToProps)(withRouter(withPaths(Component)));
    },
    withResourceState: (Component) => {
      return withRouter(
        connect(
          (store, ownProps) => ({
            ...mapStateToProps(store),
            ...mapCardsDataToTable(store),
            ...mapResourceToProps(store, ownProps),
            ...mapPreferencesToProps(store),
          }),
          mapDispatchToProps,
          mergePropsToTable
        )(withPaths(Component))
      );
    },
  };
};

// by encapsulating all of our HOCs into a single function definition, I can cleanly swap out minimal state to reuse components across routes.
// This is literally a function, that returns an object, that contains 3 higher order components composed of 1 or more higher order components..
export const { withFeatureState, withResourceState, withTableState } = stateBuilder();
