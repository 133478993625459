import React from 'react';
import PropTypes from 'prop-types';
import ConfirmButtons from 'components/v1/ConfirmButtons';
import { styled } from '@mui/material/styles';

// Todo: Convert to TypeScript
// interface FormLayoutProps {
//   children: React.ReactNode;
//   displayName?: string;
//   onClose: () => void;
//   onSubmit: (selected: any[]) => void;
// }

const StyledFormLayout = styled('div')({
  backgroundColor: '#fff',
  border: '1px solid #ddd',
  boxShadow: '1px 3px 11px rgba(0, 0, 0, 0.08)',
  fontSize: '13px',
  minWidth: '350px',
  width: '40vw',
  position: 'absolute',
  top: '50px',
  zIndex: '50',
});

const StyledFormTitle = styled('div')({
  fontSize: '15px',
  borderBottom: '1px solid #eee',
  padding: '14px 0',
});

const StyledFormSingle = styled('div')({
  margin: '10px 0 5px 0',
});

const FormLayout = ({ onClose, onSubmit, children, displayName }) => {
  const [selected, setSelected] = React.useState([]);

  const callback = (props) => setSelected(props);

  const handleOnSubmit = (e) => {
    e.preventDefault();
    if (!onClose || !onSubmit) return;
    onSubmit(selected);
    onClose();
  };

  const formTitle = displayName ? `Add ${displayName} Filter` : 'Add Filter';

  return (
    <StyledFormLayout>
      <form className="Manage-section" onSubmit={handleOnSubmit}>
        <StyledFormTitle>{formTitle}</StyledFormTitle>
        <StyledFormSingle>
          {children ? React.cloneElement(children, { callback }) : undefined}
        </StyledFormSingle>
        <div className="Manage-row">
          <ConfirmButtons loading={false} onClose={onClose} text="Apply" noPadding />
        </div>
      </form>
    </StyledFormLayout>
  );
};

FormLayout.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FormLayout;
