import { adaptToIso, adaptToDisplay } from 'adapters/v1/dates';
import { cellAdapterFactory, cellLinkAdapterFactory } from 'adapters/v2/factories';
import { adaptToOriginalRowIds } from 'adapters/v1/table/rows';
import { adaptToJsonApiRequest, adaptJsonApiToDetail } from 'adapters/v1/json_api';

export const dateFields = [
  'updated_at',
  'created_at',
  'due_at',
  'completed_at',
  'assigned_at',
  'certified_at',
];

export const adaptToRequest = (formData) => {
  let out = { ...formData };

  if (out.certificate && out.certificate.file) {
    out.certificate = out.certificate.file;
  }

  return adaptToJsonApiRequest({
    attributes: adaptToIso(out, dateFields),
    type: 'training_assignment',
  });
};

export const adaptToDetail = (resource) => {
  if (!resource) return {};
  const adapted = adaptToDisplay(adaptJsonApiToDetail(resource), dateFields);

  adapted.training_title = adapted.training && adapted.training.title;
  adapted.user_name = adapted.user && adapted.user.name;

  adapted.display_name = `${adapted.user_name} - ${adapted.training_title}`;

  // Note: the entire status field isn't even necessary
  adapted.subtitle = adapted.completed_at ? `Completed ${adapted.completed_at}` : 'Incomplete';

  const isOverdue =
    adapted.due_at && !adapted.completed_at && new Date() > new Date(adapted.due_at);
  adapted.overdue = isOverdue ? 'Overdue' : '';

  adapted.instructions = adapted.description || 'Review the course materials.';

  if (
    adapted.documents &&
    adapted.training &&
    adapted.training.link_url &&
    adapted.training.link_title
  ) {
    adapted.documents.unshift({
      link: adapted.training.link_url,
      name: adapted.training.link_title,
    });
  }

  return adapted;
};

export const adaptToForm = (resource) => {
  if (!resource) return {};
  return adaptJsonApiToDetail({ ...resource });
};

export const adaptToBulkRemindRequest = ({ selected = [] } = {}) => {
  const ids = adaptToOriginalRowIds(selected);

  const data = {
    attributes: { notify: true },
    ids,
    type: 'training_assignment',
  };

  return { data };
};

export const adaptToBulkUpdateRequest = ({ selection = [], ...attributes } = {}) => {
  const ids = adaptToOriginalRowIds(selection);

  const data = {
    attributes: adaptToIso(attributes, dateFields),
    ids,
    type: 'training_assignment',
  };

  return { data };
};

export const adaptToBulkCreateRequest = ({ resourceId, selected = [], formData = {} } = {}) => {
  const data = adaptToOriginalRowIds(selected).map((id) => {
    return {
      attributes: { training_id: resourceId, user_id: id, ...adaptToIso(formData, dateFields) },
      type: 'training_assignment',
    };
  });

  return { data };
};

export const cellAdapter = cellAdapterFactory(adaptToDetail);
export const cellLinkAdapter = cellLinkAdapterFactory(adaptToDetail);
