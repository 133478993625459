import tinycolor from 'tinycolor2';

// Components
import { workflowIcons as icons } from 'lib/v2/icons';
import BasicAccordion from 'components/v2/PageElements/Accordion';

// FormElements
import NotifyCard from './NotifyCard';

// MUI
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';

// Paths
import actionRequestPaths from 'config/paths/actionRequests';
import incidentPaths from 'config/paths/reportablesV2';
import travelPaths from 'config/paths/travels';
import { userPathsBuilder as userVisitRequestsPathsBuilder } from 'config/paths/visitRequests';

// State
import { withFeatureState } from './state';

import { blue, logoCyan, sisGreen5 } from 'lib/v2/colors';

const ResponsiveGrid = ({ data, history, onClose, permissions, profile }) => {
  const matches = useMediaQuery((theme) => theme.breakpoints.up('sm'));

  // Set description length based on screen size
  const media = matches ? '16' : '8';

  const defaultColors = {
    default: tinycolor(blue),
    actionRequests: tinycolor('rgba(255, 166, 7, 0.9)'),
    travels: tinycolor(logoCyan),
    visits: tinycolor(sisGreen5),
  };

  function colorShift(color) {
    return tinycolor(color).spin(15).setAlpha(0.85).lighten().desaturate(15).toString();
  }

  const iconProps = ({ color }) => {
    const color2 = colorShift(tinycolor(color).clone());

    return {
      background: `linear-gradient(60deg, ${color} 0%, ${color2} 100%)`,
      borderRadius: '5px',
      height: 'inherit',
      width: 'inherit',
      padding: '0.2em',
    };
  };

  function Icon({ icon, color, ...rest }) {
    const Icon = icons[icon];
    return <Icon sx={{ ...iconProps({ color }) }} {...rest} />;
  }

  const handleClick = ({ name, overrideLink }) => {
    onClose && onClose();
    const newLocation = overrideLink || incidentPaths.root + `?create=true?type=${name}`;

    // without a page change, the current url check to open the create won't rerun if
    // they use the header notify while on the main reportables page
    if (history.location.pathname.includes(incidentPaths.root)) {
      history.push('/');
      setTimeout(() => history.push(newLocation, 100));
    } else history.push(newLocation);
  };

  // overrride legacy items
  data = data.map((props) => {
    let overrideLink;
    if (props.name === 'Travel Report') {
      overrideLink = travelPaths.create;
    }
    return { ...props, overrideLink };
  });

  if (permissions?.actionRequests.create) {
    data.unshift({
      id: 'action_request',
      description: 'Request action from your security team',
      color: defaultColors.actionRequests,
      icon: 'ModelTrainingIcon',
      name: 'Action Request',
      active: true,
      overrideLink: actionRequestPaths.create,
    });
  }

  // if legacy travel doesn't exist, add it manually. We should migrate it away from any companies that don't have legacy data
  const hasTravelItem = data.find(({ name, active }) => active && name === 'Travel Report');
  if (permissions.travels.create && !hasTravelItem) {
    data.push({
      id: 'travel',
      description:
        'Traveling outside the United States is a matter of security interest in view of the clearances you hold. Any travel outside US borders should be reported and includes Canada, the Caribbean, and Mexico. Knowledge of your whereabouts is needed primarily for personal protection and as a guide in locating you should an official search be required. Your itinerary should be adhered to as closely as possible. Ideally, you will complete this form 30 days before departure. If you have a top secret or an SCI, you may have additional requirements; check with your security manager.',
      color: defaultColors.travels,
      icon: 'FlightIcon',
      name: 'Travel',
      active: true,
      overrideLink: travelPaths.create,
    });
  }

  if (permissions?.visits.create) {
    data.push({
      id: 'visit',
      description: 'Log a visit request between facilities',
      color: defaultColors.visits,
      icon: 'LocationOnIcon',
      name: 'Visit Request',
      active: true,
      overrideLink: userVisitRequestsPathsBuilder(profile.id).root + '?create=true',
    });
  }

  return (
    <Box sx={{ paddingTop: '1em' }}>
      <Grid container spacing={{ xs: 2, md: 3 }} minHeight={'10em'}>
        {data
          ?.filter(({ active }) => !!active)
          ?.map(({ color, description, icon, id, name, overrideLink }) => (
            <Grid xs={12} sm={6} md={3} key={id}>
              <NotifyCard
                icon={Icon({ icon: icon || 'SendIcon', color: color ?? defaultColors.default })}
                title={name}
                onClick={() => handleClick({ name, overrideLink })}
              >
                <Typography variant="body2">
                  {description?.split(' ').slice(0, media).join(' ').concat('...')}
                </Typography>
              </NotifyCard>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

const why = {
  title: 'Why do I need to report information in Compliance?',
  description: (
    <>
      <div>
        We get it -- the buttons below look a little intimidating. But they are really important.
        When we find out that a coworker might be doing things that may be a security concern, it’s
        easy to tell ourselves that it’s none of our business or to believe that someone else, like
        our manager, our customers, or security personnel, will report the information.
      </div>
      <br />
      <div>
        The same thing applies to ourselves. When “stuff happens” in our own lives that are of
        security concern, it’s understandable we would be a little worried to report that
        information for fear might lose access to classified information, or even our jobs.
      </div>
      <br />
      <div>
        Some of the things you need to report are just routine -- your travel to foreign countries,
        relationships with foreign nationals, or changes in your personal status like marriage or
        divorce. Some are truly “adverse information,” or things that might be a security concern --
        like a bankruptcy, an arrest, a suspicious contact, or accidentally leaving a SECRET
        briefing on a city bus.
      </div>
      <br />
      <div>
        We signed up for this: as cleared employees, we -- and our managers, customers, supervisors,
        commanders, and coworkers -- have a responsibility to report anything that might be a
        security concern, whether we commit them ourselves or observe someone else‘s behavior.
        Reporting “adverse information” (the stuff below) stops damage to our national security and
        loss of life at the hands of those entrusted with our nation’s secrets.
      </div>
      <br />
      <div>-Adapted from CDSE Adverse Reporting Student Guide"</div>
    </>
  ),
};

const what = {
  title: 'What Not to Report?',
  description: (
    <>
      <div>
        OK, some of us go a little overboard in reporting. That’s probably better than not reporting
        at all! Here are a few tips on what not to report, courtesy of the Center for Development of
        Security Excellence (CDSE):
      </div>
      <br />
      <div>
        Do NOT report:
        <ul>
          <li>
            -Someone else’s poor attendance at meetings, or otherwise unprofessional and annoying
            habits.
          </li>
          <li>-Marital, Family, or Grief Counseling that isn’t related to violence by you</li>
          <li>-Counseling or healthcare resulting from a sexual assault</li>
        </ul>
      </div>
    </>
  ),
};

const Notify = ({ reportableTemplates, ...rest }) => {
  return (
    <>
      {/* <Box>
        <Typography variant="h2" align="center" mb={3}>
          Select Report Type
        </Typography>
      </Box> */}
      <BasicAccordion title={why.title} description={why.description} elevation={0} />
      <BasicAccordion title={what.title} description={what.description} />
      <ResponsiveGrid data={reportableTemplates} {...rest} />
    </>
  );
};

export default withFeatureState(Notify);
