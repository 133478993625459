import React, { useEffect } from 'react';

import { withFeatureState } from 'components/v2/Tickets/state';

import Card from 'components/v2/Tickets/Ticket';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

const TicketGrid = ({ children }) => (
  <Grid item xs={12} sm={6} md={6} lg={4} xl={4}>
    {children}
  </Grid>
);

export const Inbox = ({ tickets = [], profile, list, history, paths, loading, ...rest }) => {
  useEffect(() => {
    const forcedFilters = { approvers: [profile.id], approval_status: [null], active: true };

    const queryParams = {
      forcedFilters,
      showAll: true,
    };

    list(queryParams);
  }, []); // eslint-disable-line

  const onClick = () => {
    history.push(paths.list);
  };

  const hasItems = tickets.length > 0;
  const seeMore = tickets.length > 3;

  return (
    <>
      {hasItems && (
        <Box sx={{ mb: 3 }}>
          <Typography variant="h4" sx={{ mt: 2, mb: 1 }}>
            {`You have ${tickets.length} ticket${
              tickets.length > 1 ? 's' : ''
            } waiting on your approval `}

            {seeMore && (
              <Link sx={{ cursor: 'pointer' }} onClick={onClick} variant="inherit" underline="none">
                (see all)
              </Link>
            )}
          </Typography>

          <Grid container spacing={2}>
            {tickets.slice(0, 3).map((ticket, index) => (
              <TicketGrid key={`active-ticket-${index}`}>
                <Card ticket={ticket} />
              </TicketGrid>
            ))}
          </Grid>
        </Box>
      )}
    </>
  );
};

export default withFeatureState(Inbox);
