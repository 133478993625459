import { connect } from 'react-redux';

// MUI
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';

// Components
import {
  AtachmentLink,
  DeleteIcon,
  StyledLink,
  StyledListItem,
} from 'components/v2/PageElements/Attachments/Item';
import DocumentAvatar from './Avatar';

// Mappers
import { mapPermissionsToProps } from 'mappers/v1/permissions';

import { userFormsPath } from 'config/paths/userDigitalForms';

import format from 'lib/v1/format';

export const FormItem = ({
  attachment = {},
  destroy,
  show,
  permissions = {},
  isReviewer,
  icon,
  isDialog = false,
  profile,
}) => {
  const {
    id,
    completed_at,
    submitted_at,
    due_date,
    status,
    title,
    user,
    attached_digital_form_id,
    user_id,
  } = attachment;

  let date;
  if (status === 'Incomplete') {
    date = due_date ? `Due: ${format.relativeTime(due_date)}` : 'No Due Date';
  }
  if (status === 'Complete') {
    date = format.relativeTime(completed_at);
  }
  if (status === 'Submitted') {
    date = format.relativeTime(submitted_at);
  }
  const description = `${status} - ${date}`;
  const text = `${title} - ${user ? user.name : 'Unknown'}`;

  const canDelete = permissions.digitalFormRequests.destroy || isReviewer;
  const canView = profile.id === user_id || profile.role === 'admin';

  const linkProps = {
    link: canView,
    path: userFormsPath(user_id, id),
    text,
  };

  return (
    <>
      <StyledListItem>
        <ListItemAvatar sx={{ display: 'flex', flexDirection: 'row', marginTop: 0 }}>
          <DocumentAvatar md icon={icon} />
        </ListItemAvatar>

        <StyledLink dense primary={<AtachmentLink {...linkProps} />} secondary={description} />

        {canDelete && (
          <ListItemSecondaryAction>
            <DeleteIcon aria-label="delete" onClick={() => destroy(id, attached_digital_form_id)}>
              <DeleteOutlineOutlinedIcon />
            </DeleteIcon>
          </ListItemSecondaryAction>
        )}
      </StyledListItem>
    </>
  );
};

const mapStateToProps = ({ profile }) => ({
  profile,
  ...mapPermissionsToProps({ profile }),
});
export default connect(mapStateToProps)(FormItem);
