import React from 'react';

// MUI
import ListItem from '@mui/material/ListItem';

// Styles
import { styled } from '@mui/material/styles';

const StyledListItem = styled(ListItem)({
  display: 'block',
  marginLeft: 0,
  paddingLeft: 10,
  paddingRight: 10,
});

const Index = ({ permission = true, ...rest }) => {
  if (!permission) return null;

  return <StyledListItem {...rest} />;
};

export default Index;
