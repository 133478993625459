import store from 'store';
import { add_message } from 'store/v1/app/actions';
import { logout } from 'store/v1/authentication/actions';

export const defaultMessage = 'There was a problem with your request';

export default function handleError(err, suppress = false) {
  console.error(err); // eslint-disable-line

  if (!err.response) {
    const message = err.toString();

    if (message.includes('Timeout')) {
      return store.dispatch(
        add_message('timeout_error', [
          'The network is experiencing connectivity problems.  Please try again later.',
        ])
      );
    }

    // don't show generic message when we're suppressing errors
    return !suppress && displayError(defaultMessage);
  }

  if (err.response) {
    if (err.response.status === 500) {
      return !suppress && store.dispatch(add_message('resource_error', [defaultMessage]));
    } else if (err.response.status === 422) {
      return store.dispatch(
        add_message('unprocessable_error', unprocessableEdgeCase('errors', err.response))
      );
    } else if (err.response.status === 400) {
      return store.dispatch(add_message('other_error', getOutput('message', err.response)));
    } else if (err.response.status === 403) {
      return store.dispatch(
        add_message('permissions_error', [
          'You do not have the permissions to perform this action.',
        ])
      );
    } else if (err.response.status === 404) {
      return (
        !suppress && store.dispatch(add_message('route_error', ['The resource was not found']))
      );
    } else if (err.response.status === 401) {
      if (import.meta.env.NODE_ENV === 'test') {
        return store.dispatch(add_message('unauthorized_error', ['Authorized users only.']));
      }

      store.dispatch(add_message('error', ['Automatically signed out due to inactivity.']));
      return store.dispatch(logout());
    } else if (err.response.status === 409) {
      return store.dispatch(
        add_message('processing_error', [
          'This request has already been completed or is currently being processed.',
        ])
      );
    } else if (err.response.status === 429) {
      const returnedMessage = err.response?.body?.message;
      return store.dispatch(
        add_message('rate_limit_error', [
          returnedMessage ||
            'Too many requests. Please try again in a few minutes or contact support.',
        ])
      );
    } else {
      return !suppress && store.dispatch(add_message('other_error', [defaultMessage]));
    }
  } else {
    return !suppress && displayError(defaultMessage);
  }
}

// Safely retrieve an array of errors or messages from response object
export function unprocessableEdgeCase(key, response) {
  const body = response.body ? response.body : {};
  const value = body[key];

  if (typeof value === 'object') {
    return value.password ? [value.password] : [defaultMessage];
  }

  return Array.isArray(value) ? value : [value];
}

export function getOutput(key, response = {}) {
  const body = response.body ? response.body : {};
  return Array.isArray(body[key]) ? body[key] : [body[key]];
}

export function displayError(message, type = 'other_error') {
  return store.dispatch(add_message(type, [message]));
}
