import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import FlashMessages from 'components/v2/PageElements/FlashMessages';
import IdleTimerDialog from 'components/v2/PageElements/IdleTimerDialog';

const StyledWrapper = styled('div')({
  height: '100%',
});

const AppLayout = ({ children }) => {
  return (
    <StyledWrapper>
      <IdleTimerDialog />
      <FlashMessages />
      {children}
    </StyledWrapper>
  );
};

export default connect()(withRouter(AppLayout));
