import { defaultMoment as moment } from 'config';

const capitalize = (string = '') => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const humanizeSnake = (value) => {
  const temp = value.toLowerCase().replace(/_/g, ' ');
  return temp[0].toUpperCase() + temp.slice(1);
};

export const titleizeSnake = (value) => {
  if (value.includes('custom_field')) value = value.replace(value.split('_')[2], ':');

  return value
    .toLowerCase()
    .split('_')
    .map((i) => capitalize(i))
    .join(' ');
};

const format = {
  name: (user = {}) => {
    const { first_name, last_name, active } = user;
    if (last_name || first_name) {
      const str = `${last_name}, ${first_name}`;
      return active === undefined || active ? str : `${str} (inactive)`;
    } else {
      return '';
    }
  },
  full_name: (user = {}) => {
    const { first_name, middle_name, last_name, active } = user;
    if (last_name || first_name) {
      const str = `${first_name} ${middle_name || ''} ${last_name}`;
      return active === undefined || active ? str : `${str} (inactive)`;
    } else {
      return '';
    }
  },
  first_last: (user = {}) => {
    const { first_name, last_name, active } = user;
    if (last_name || first_name) {
      const str = `${first_name} ${last_name}`;
      return active === undefined || active ? str : `${str} (inactive)`;
    } else {
      return '';
    }
  },
  date: (string) => {
    return string ? normalizeTimezone(string).format('MM/DD/YYYY') : '';
  },
  full_date: (string) => {
    return string ? normalizeTimezone(string).format('MMMM Do, YYYY') : '';
  },
  time: (string) => {
    return string ? normalizeTimezone(string).format('h:mm A') : '';
  },
  datetime: (date, time) => {
    const string = `${date} ${time ? time : ''}`;
    return moment(string, 'MM/DD/YYYY h:mm A');
  },
  ssn: (ssn) => `${ssn.slice(0, 3)}-${ssn.slice(3, 5)}-${ssn.slice(5, 9)}`,
  training_status: (status, due_at) => {
    if (status === 1) return 'complete';
    if (status === 0) return 'incomplete';
  },
  risk_label: (num) => {
    const levels = [
      { range: [0, 0], value: 'No information' },
      { range: [1, 3], value: 'Low' },
      { range: [4, 5], value: 'Caution' },
      { range: [6, 9], value: 'Needs attention' },
      { range: [10, Infinity], value: 'Urgent' },
    ];
    for (var i = 0; i < levels.length; i++) {
      const { range, value } = levels[i];
      if (num >= range[0] && num <= range[1]) {
        return value;
      }
    }
  },
  relativeTime: (value, locale = 'en-US') => {
    const diff = moment(value).diff(moment(), 'days');
    if (diff < -3) return format.date(value);
    const formatter = new Intl.RelativeTimeFormat(locale, { style: 'short', numeric: 'auto' });
    return formatter.format(diff, 'days');
  },
};

function normalizeTimezone(time) {
  // the incoming timestamp may be a different instance of moment and not moment-timezone
  // so this effectively clones into our timezone version so we can perform adjustments
  const tzTime = moment(time);
  return time.utcOffset ? tzTime.add(time.utcOffset(), 'm') : tzTime;
}

export default format;
