import React from 'react';
import { withRouter } from 'react-router-dom';

import { useAppContext } from 'components/v2/AppContext';

// mui
import Box from '@mui/material/Box';

import clsx from 'clsx';

import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import BackButton from 'components/v2/PageElements/BackButton';

import Avatar from './Avatar';

const useStyles = makeStyles((theme) => {
  return {
    title: {
      color: theme.palette.text.primary,
      fontSize: '2rem',
      fontWeight: 100,
      padding: 0,
      margin: 0,
    },
    subtitle: {
      color: theme.palette.text.secondary,
      fontSize: '0.75rem',
      fontWeight: 300,
      padding: 0,
      margin: 0,
    },
    container: {
      margin: '10px 0 18px 0',
      padding: 0,
      maxWidth: 'calc(100vw - 130px)',
      '& .withSubtitle h2': {
        fontWeight: 300,
        fontSize: '1.5rem',
      },
    },
    index: { maxWidth: 'calc(100vw - 170px)' },
    detail: { maxWidth: '45rem' },
    icon: {
      height: '2rem',
      width: '2rem',
      marginRight: '0.75rem',
      borderRadius: 16,
      '&.updated': {
        height: '2.5rem',
        width: '2.5rem',
      },
    },
    actionContainer: {
      display: 'flex',
      flexDirection: 'row',
      marginLeft: 'auto',
      '& > *': {
        marginLeft: '0.5em',
      },
    },
    contained: { paddingRight: theme.spacing(2) },
  };
});

const Icon = ({ icon, rounded, updatedIcon }) => {
  const classes = useStyles();

  if (!icon) return null;

  if (typeof icon === 'string') {
    return (
      <img
        src={icon}
        alt="header icon"
        className={classes.icon + ' rounded' + (updatedIcon ? ' updated' : '')}
      />
    );
  }

  // this assumes it's using the a MUI icon since our icons use strings as the source
  return React.cloneElement(icon, {
    xl: 'true',
    sx: { marginRight: '0.75rem', color: '#fff', background: '#666', padding: '5px' },
    className: `${classes.icon} ${classes.rounded}`,
  });
};

export default withRouter(
  ({
    icon,
    updatedIcon,
    title,
    subtitle,
    children,
    rounded = false,
    history,
    backPath,
    backText,
    preferHistoryGoBack = false,
    variant,
    contained,
    user,
  }) => {
    const context = useAppContext();
    const classes = useStyles();

    // Tethering to index for context v2 for the moment
    const variantClass = context.v2 ? 'index' : classes[variant] || 'index';

    const clsxClasses = [classes.container, variantClass];

    if (contained || variant === 'detail') clsxClasses.push(classes.contained);

    const handleBackButtonClick = (event) => {
      if (event) event.preventDefault();
      if (!history) return;

      // make sure there is history to go back to. A new tab and pasted URL will give a length of 2
      if (preferHistoryGoBack && history.length > 2) {
        return history.goBack();
      }

      history.push(backPath);
    };

    const backButtonProps = {
      onClick: handleBackButtonClick,
      text: backText,
    };

    return (
      <>
        {backPath && <BackButton {...backButtonProps} />}

        <Box
          display="flex"
          flexDirection="row"
          p={1}
          m={1}
          alignItems="center"
          className={clsx(clsxClasses)}
        >
          {title && (
            <>
              {user ? (
                <Avatar {...{ user, profile: user }} />
              ) : (
                <Icon {...{ icon, rounded, updatedIcon }} />
              )}

              <Box className={!!subtitle ? 'withSubtitle' : ''}>
                <Typography variant="h2" className={classes.title}>
                  {title}
                </Typography>

                <Typography variant="h3" className={classes.subtitle}>
                  {subtitle}
                </Typography>
              </Box>
            </>
          )}

          <Box className={classes.actionContainer}>{children}</Box>
        </Box>
      </>
    );
  }
);
