import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

/*
 * This is a convenience layout for determining if the user is viewing their own resourceful route.  It is such a common case that we need to solve it.
 *
 */

export const IdentityManager = ({ match, profile, paramId = 'id', children }) => {
  const userId = match && match.params ? Number(match.params[paramId]) : null;
  const isSelf = profile && userId ? userId === profile.id : false;

  return React.cloneElement(children, { isSelf, userId });
};

const mapStateToProps = ({ profile }) => ({
  profile,
});

export const ConnectedIdentityManager = connect(mapStateToProps)(withRouter(IdentityManager));

export const withIdentityManager = (Component) => {
  return ({ paramId, ...rest }) => (
    <ConnectedIdentityManager paramId={paramId}>
      <Component {...rest} />
    </ConnectedIdentityManager>
  );
};

export default ConnectedIdentityManager;
