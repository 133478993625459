import React from 'react';

// new designer made icons
import Ticket from 'assets/images/icons/ticket.svg';
import Workflow from 'assets/images/icons/workflow.svg';
import Calendar from 'assets/images/icons/calendar.svg';
import CalendarOverdue from 'assets/images/icons/calendar_overdue.svg';

const iconMap = { Ticket, Workflow, Calendar, CalendarOverdue };

const Icon = ({ icon: iconName }) => {
  const mappedIcon = iconMap[iconName];
  if (!mappedIcon) return null;

  return <img src={mappedIcon} alt={`${iconName} icon`} />;
};

export default Icon;
