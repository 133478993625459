import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { isNotProduction } from 'config';

// Mappers
import { mapPermissionsToProps } from 'mappers/v1/permissions';
import { mapPreferencesToProps } from 'mappers/v1/preferences';

// MUI
import Box from '@mui/material/Box';
import ScienceIcon from '@mui/icons-material/Science';
import { styled } from '@mui/material/styles';
import Tooltip from 'components/v2/FormElements/Tooltip';
import Typography from '@mui/material/Typography';

import moment from 'moment';

const IconWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginRight: theme.spacing(5),
}));

const StyledIcon = styled(ScienceIcon)(({ theme }) => ({
  border: '1px solid',
  borderRadius: '4px',
  padding: '2px',
  // width: '2rem',
  // height: '2rem',
  cursor: 'help',
}));

const tswVersion = import.meta.env.VERSION;
const node = import.meta.env.NODE;
const date = import.meta.env.DATE;
const authHost = window.localStorage.getItem('auth_host');

const versionInfo = window.localStorage.getItem('api_version')?.split('::');
const apiVersion = versionInfo?.[0] ?? '';
const rails = versionInfo?.[1] ?? '';

const buildNodeVersion = node?.split('node/v')?.[1]?.split('/')?.[0];
const buildDateFE = moment.utc(date).format('MM/DD HH:mm');
const buildDateBE = moment.utc(new Date()).format('MM/DD HH:mm');

const Watermark = ({ preferences }) => {
  const Info = () => {
    return (
      <>
        <Typography>BETA ENABLED</Typography>
        <Typography>{`HOST: ${authHost}`}</Typography>
        <Typography>{`Client: ${tswVersion}`}</Typography>
        <Typography>{`Node: ${buildNodeVersion}`}</Typography>
        <Typography>{`FD: ${buildDateFE}`}</Typography>
        <Typography>{`Api: ${apiVersion}`}</Typography>
        <Typography>{`Rails: ${rails}`}</Typography>
        <Typography>{`BD: ${buildDateBE}`}</Typography>
      </>
    );
  };

  return (
    <>
      {isNotProduction && preferences.betaTester && (
        <IconWrapper>
          <Tooltip title={<Info />}>
            <StyledIcon color="info" fontSize="large" />
          </Tooltip>
        </IconWrapper>
      )}
    </>
  );
};

function mapStateToProps(store) {
  const { profile } = store;
  return {
    ...mapPreferencesToProps({ profile }),
    profile,
    id: profile.id,
    ...mapPermissionsToProps({ profile }),
  };
}

export default withRouter(connect(mapStateToProps)(Watermark));
