import React from 'react';

// MUI
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';

// components
import ActiveFilters from 'components/v2/Filters/ActiveFilters';
import FilterButton from './FilterButton';
import ReportButton from './ReportButton';
import Search from './Search';

const MainBar = styled('div')({
  display: 'flex',
  alignItems: 'center',
  padding: 5,
  position: 'relative',
});

const SearchWrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  marginRight: 'auto',
});

const ArchiveTextWrapper = styled('div')({
  position: 'absolute',
  right: '5px',
  bottom: '-10px',
  fontSize: '0.75rem',
  fontStyle: 'italic',
});

// https://mui.com/components/progress/#customization
// TODO: if we like this, abstract it from here and replace all vanilla CircularProgress in app
const StyledCircularProgress = (props) => {
  return (
    <Box sx={{ position: 'relative', marginLeft: '10px' }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) => theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
        }}
        size={30}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: 'info',
          animationDuration: '550ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={30}
        thickness={4}
        {...props}
      />
    </Box>
  );
};

const SearchAndFilterLayout = ({ SearchComponent, searchProps, ...rest }) => {
  const { filters, loading, query } = rest;

  const hasArchiveFilterOption = (filters?.filterOptions || []).some(
    ({ value }) => value === 'archived'
  );
  const hasArchiveFilterActive = !!query?.filters?.archived;
  const showArchiveText = !loading && hasArchiveFilterOption && !hasArchiveFilterActive;

  return (
    <>
      <MainBar>
        <SearchWrapper>
          {SearchComponent ? <SearchComponent {...searchProps} /> : <Search {...rest} />}
          <ReportButton {...rest} />
          <FilterButton {...rest} />
          <ActiveFilters {...rest} />
        </SearchWrapper>
        {loading && <StyledCircularProgress />}
        {showArchiveText && (
          <ArchiveTextWrapper>Use filters to view archived items</ArchiveTextWrapper>
        )}
      </MainBar>
    </>
  );
};

export default SearchAndFilterLayout;
