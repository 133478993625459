import React from 'react';
import { Suspense, lazy } from 'react';
import { connect } from 'react-redux';

// PageElements
import ReduxManager from 'components/v2/PageElements/ReduxManager';
import Route, { Switch } from 'components/v2/PageElements/Routes';

// Redux State Mappers
import { mapPermissionsToProps } from 'mappers/v1/permissions';

// Config
import paths from 'config/paths/tickets';

// Components
const Index = lazy(() => import('./index'));
const TicketDetail = lazy(() => import('./Detail'));

export const Routes = ({ paths, permissions }) => {
  const ticketData = ['cardsSchema', 'cardTemplatesDropdown', 'peopleDropdown', 'usersSchema'];
  if (permissions.contacts?.list) ticketData.push('contactsDropdown');

  return (
    <ReduxManager data={ticketData}>
      <Suspense fallback={<></>}>
        <Switch>
          <Route permission={true} exact path={paths.root}>
            <Index paths={paths} />
          </Route>
          <Route permission={true} exact path={paths.resource.detail(':id')}>
            <TicketDetail paths={paths} />
          </Route>
        </Switch>
      </Suspense>
    </ReduxManager>
  );
};

export default connect(mapPermissionsToProps, null)((props) => <Routes paths={paths} {...props} />);
