import { adaptToIso, adaptToDisplay } from 'adapters/v1/dates';
import { cellAdapterFactory, cellLinkAdapterFactory } from 'adapters/v2/factories';
import { adaptToJsonApiRequest, adaptJsonApiToDetail } from 'adapters/v1/json_api';
import { adaptSingleSelectFieldsToRequest } from 'adapters/v1';

import moment from 'moment';

import constants from 'config/constants/reportables';

const dateFields = ['updated_at', 'created_at', 'incident_date', 'archived_at'];
const singleSelectFields = ['incident_template_id, reviewer'];

export const adaptToRequest = (formData) => {
  const out = { ...formData };

  // User Members
  if (out.subjectsEdit) out.subject_user_ids = out.subjectsEdit?.map((s) => s.label) || [];
  if (out.watchersEdit) out.watcher_user_ids = [out.watchersEdit.label];
  if (out.reviewerEdit) out.reviewer_id = out.reviewerEdit.label;

  const { template, additional_custom_fields = [] } = out;

  if (out.incident_template_id) out.incident_template_id = out.incident_template_id.label;

  // Name/Display Name, can be removed after backend validation is
  const displayName = constants[out.type]?.display || out.incident_template?.name || 'Incident';
  const joinedSubjects = out.subjects?.map((s) => s.name).join(', ') || 'No Subjects';
  const incidentDate = moment(out.incident_date).format('MM/DD/YYYY');

  if (out.kind === 'life-changes') out.type = 'life';
  out.name = `${displayName} - ${joinedSubjects} - ${incidentDate}`;

  // Custom Fields
  const additionalCustomFields = template
    ? template.instance_custom_fields
    : additional_custom_fields;

  const timeFields = additionalCustomFields
    ?.filter(({ field_type }) => field_type === 'time')
    ?.map(({ field_name }) => field_name);

  const allCustomFields = [
    ...new Set([...additionalCustomFields.map(({ field_name }) => field_name)]),
  ];

  // Instead of grabbing the entire object, just the items that might need sent
  allCustomFields.forEach((attr) => {
    if (formData[attr] === undefined) return;

    // Format Time fields
    if (timeFields.includes(attr.toString())) return (out[attr] = formData[attr].format('HH:mm'));

    const hasLabel = formData[attr] && formData[attr].label !== undefined;

    out[attr] = hasLabel ? formData[attr].label : formData[attr];
  });

  const adapted = {
    ...adaptToIso(out, dateFields),
    ...adaptSingleSelectFieldsToRequest({ out, singleSelectFields }),
  };

  return adaptToJsonApiRequest({
    attributes: adapted,
    type: 'incident',
  });
};

export const adaptToDetail = (resource) => {
  if (!resource) return {};
  const mapUsersToSelect = (users = []) =>
    users.map((u) => ({ title: u.display_name, label: u.id }));

  const adapted = adaptJsonApiToDetail({ ...resource });

  adapted.archivedDate = !!adapted.archived ? new Date(adapted.archived_at) : null;
  adapted.reportDate = new Date(adapted.created_at);
  const displayName = constants[adapted.type]?.display || adapted.incident_template?.name;
  const joinedSubjects = adapted.subjects?.map((s) => s.name).join(', ');
  const incidentDate = adapted?.incident_date
    ? moment(adapted?.incident_date).format('MM/DD/YYYY')
    : '';

  // Alias for Display Name
  if (adapted.kind === 'life-changes') adapted.type = 'life';
  adapted.display_name = `${displayName || 'Incident'} - ${joinedSubjects || 'No Subjects'}${
    incidentDate ? ` - ${incidentDate}` : ''
  }`;

  if (adapted.created_by) adapted.created_by = adapted.created_by?.name;
  // TODO: Get reviewers, subjects, watchers from members
  if (adapted.reviewer) {
    adapted.reviewerEdit = { title: adapted.reviewer.name, label: parseInt(adapted.reviewer.id) };
    adapted.reviewerName = adapted.reviewer.name;
  }

  if (adapted.subjects) adapted.subjectsEdit = mapUsersToSelect(adapted.subjects);
  if (adapted.watchers) {
    adapted.watchersEdit = mapUsersToSelect(adapted.watchers)[0];
    adapted.reporterName = adapted.watchersEdit?.title;
  }

  // Format time fields
  if (adapted.additional_custom_fields) {
    const timeFields = adapted.additional_custom_fields.filter(
      ({ field_type }) => field_type === 'time'
    );
    timeFields.forEach(({ field_name }) => {
      adapted[field_name] = moment(adapted[field_name], 'HH:mm');
    });
  }

  if (adapted.incident_template) {
    adapted.incident_template_id = {
      title: adapted.incident_template.name,
      label: parseInt(adapted.incident_template.id),
    };
  }

  return adaptToDisplay(adapted, dateFields);
};

export const adaptToForm = (resource) => {
  if (!resource) return {};

  const out = { ...resource };

  return { ...out };
};

export const cellAdapter = cellAdapterFactory(adaptToDetail);
export const cellLinkAdapter = cellLinkAdapterFactory(adaptToDetail);
