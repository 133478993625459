import { connect } from 'react-redux';

// MUI
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Typography from '@mui/material/Typography';

// components
import { icons } from 'lib/v2/icons';
import {
  AtachmentLink,
  DeleteIcon,
  StyledLink,
  StyledListItem,
} from 'components/v2/PageElements/Attachments/Item';
import Avatar from './Avatar';
import ConfirmModal from 'components/v2/FormElements/ConfirmModal';
import { useDialog } from 'components/v2/FormElements/Dialog';

import { adaptToDetail } from 'adapters/v1/assignments/formData';
import { trainingAssignmentPaths } from 'config/paths/training';

import attachmentActions from 'store/v2/attachments/actions';

export const TrainingItem = ({
  destroyAttachment,
  permissions = {},
  reload,
  resourceable_type,
  trainingAssignment = {},
  ...rest
}) => {
  const adaptedAssignment = adaptToDetail(trainingAssignment);
  const { attachmentId, id, completed_at, due_at, training, user } = adaptedAssignment;
  const deleteDialogProps = useDialog();

  const onDelete = async () => {
    // really weird to require a resourceable_type here, but Justin... I'm just following the pattern
    await destroyAttachment(attachmentId, { resourceable_type });
    await reload();
    deleteDialogProps.onClose();
  };

  const secondary = completed_at ? `Completed ${completed_at}` : `Due ${due_at}`;

  const linkProps = {
    link: true,
    path: trainingAssignmentPaths.resource.detail(id),
    text: training?.title || 'Training Not Found',
  };

  const modalProps = {
    title: 'Delete Training Attachment',
    isOpen: deleteDialogProps.open,
    onConfirm: onDelete,
    confirmText: 'Delete',
    onClose: deleteDialogProps.onClose,
  };

  return (
    <>
      <ConfirmModal {...modalProps}>
        <Typography variant="body1">
          Are you sure you want to permanently delete this attachment?
        </Typography>
        <Typography variant="body1">
          The training assignment will not be deleted from the user's account, just the attachment
          to this ticket.
        </Typography>
      </ConfirmModal>

      <StyledListItem>
        <ListItemAvatar sx={{ marginTop: 0 }}>
          <Avatar md icon={icons.training} />
        </ListItemAvatar>

        <StyledLink
          dense
          primary={<AtachmentLink {...linkProps} />}
          secondary={`${secondary} by ${user.name}`}
        />

        {permissions.destroy && (id || permissions.skipId) && (
          <ListItemSecondaryAction>
            <DeleteIcon aria-label="delete" onClick={deleteDialogProps.onClick}>
              <DeleteOutlineOutlinedIcon />
            </DeleteIcon>
          </ListItemSecondaryAction>
        )}
      </StyledListItem>
    </>
  );
};

export default connect(null, {
  destroyAttachment: attachmentActions.destroy,
})(TrainingItem);
