import { memo, useState } from 'react';
import { styled } from '@mui/material/styles';

import Button from 'components/v1/Button';
import Icon from 'components/v1/Icon';
import Input from 'components/v1/Input';

export const StyledDiv = styled('div')(({ style }) => ({
  display: 'inline-flex',
  '& form': {
    display: 'flex',
  },
  '& form button': {
    position: 'relative',
    left: -24,
    top: 0,
  },
  ...style,
}));

const StyledButton = styled(Button)({
  border: 'none',
  background: 'none',
  cursor: 'pointer',
  // position: 'absolute',
  // right: '7px',
  // top: '8px',
  padding: '0',
});

const StyledIcon = styled(Icon)({
  cursor: 'pointer',
  fill: '#9b9b9b',
  height: '16px',
  width: '16px',
  '&:hover': {
    opacity: 0.8,
  },
});

const StyledInput = styled((props) => <Input {...props} />)({
  fontSize: '14px !important',
  fontWeight: '300 !important',
  padding: '10px 32px 10px 12px !important',
  borderRadius: '8px !important',
});

function Search({ onChange, onClear, onSubmit, placeholder, value }) {
  const [submitted, setSubmitted] = useState(!!value);

  const handleSubmit = (e) => {
    e.preventDefault();

    setSubmitted(true);
    onSubmit(e);

    e.target.firstChild.blur();
  };

  const handleChange = (e) => {
    setSubmitted(false);
    onChange(e);
  };

  const handleClear = () => {
    setSubmitted(false);
    onClear();
  };

  return (
    <form css="position: relative; width: 250px;" onSubmit={handleSubmit}>
      <StyledInput
        attributes={{ maxLength: 100 }}
        type="text"
        placeholder={placeholder || 'Search'}
        value={value}
        onChange={handleChange}
      />
      {!submitted && <StyledButton icon={<StyledIcon name="Search" />} type="submit" />}
      {submitted && (
        <StyledButton icon={<StyledIcon name="Cancel" />} onClick={handleClear} transparent />
      )}
    </form>
  );
}

export default memo(Search);
