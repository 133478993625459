import React from 'react';
import { Suspense, lazy } from 'react';
import { connect } from 'react-redux';

// PageElements
import ReduxManager from 'components/v2/PageElements/ReduxManager';
import Route, { Switch } from 'components/v2/PageElements/Routes';

// Redux State Mappers
import { mapPermissionsToProps } from 'mappers/v1/permissions';

// Config
import paths from 'config/paths/workflowControls';

// Components
const Index = lazy(() => import('./index'));
const Settings = lazy(() => import('./Settings'));
const Tickets = lazy(() => import('./Tickets'));
const Ticket = lazy(() => import('../Tickets/Detail'));

/*
 * Important thing to note about Resourceful layouts.
 *
 * If you want sublayouts such as those defined in "Detail" to successfully render
 * you cannot use exact paths, you are partial matching on the parent to ensure
 * the top level layout always gets loaded so that any child pages also get loaded.
 */

export const Routes = ({ paths, permissions }) => {
  return (
    <ReduxManager
      data={[
        'cardsSchema',
        'contactsDropdown',
        'customFieldsDropdown',
        'digitalFormTemplatesDropdown',
        'facilitiesDropdown',
        'peopleDropdown',
        'formTemplateActions',
        'usersSchema',
      ]}
    >
      <Suspense fallback={<></>}>
        <Switch>
          <Route permission={permissions.workflows.list} exact path={paths.root}>
            <Index paths={paths} />
          </Route>
          <Route permission={permissions.workflows.update} exact path={paths.resource.edit(':id')}>
            <Settings paths={paths} />
          </Route>
          <Route permission={permissions.workflows.view} exact path={paths.resource.detail(':id')}>
            <Tickets paths={paths} />
          </Route>
          <Route permission={permissions.workflows.view} exact path={paths.resource.ticket(':id')}>
            <Ticket />
          </Route>
        </Switch>
      </Suspense>
    </ReduxManager>
  );
};

export default connect(mapPermissionsToProps, null)((props) => <Routes paths={paths} {...props} />);
