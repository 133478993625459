import React from 'react';
import { useProfileContext } from 'components/v2/Profile/Context';

import { iconComponents } from 'lib/v2/icons';

// Layouts
import HeaderLayout from 'layouts/v2/HeaderLayout';
import TabLayout, { Tab } from 'layouts/v2/TabLayout';

// FormElements
import { useDialog } from 'components/v2/FormElements/Dialog';
import { useQueryString } from 'components/v2/PageElements';

// factories
import { withFeatureState } from 'components/v2/DigitalForms/Templates/state';

// paths
import digitalFormTemplatesPaths from 'config/paths/digitalFormTemplates';
import digitalFormRequestsPaths from 'config/paths/digitalFormRequests';

// Components
import CreateTemplate from 'components/v2/DigitalForms/Templates/Create';
import CreateRequest from 'components/v2/DigitalForms/Requests/Create';
import TemplatesTable from './Templates/Table';
import RequestsTable from './Requests/Table';

import Copy from './Templates/Copy';
import AddIcon from '@mui/icons-material/Add';

export const Index = ({ location, children, preferences, ...props }) => {
  const { pathname } = location || {};
  const { isSelf, userId } = useProfileContext();

  const isRequests = pathname.includes(digitalFormRequestsPaths.root) || !!userId;

  let CreateDialog = CreateTemplate;
  let buttonText = 'Add a Digital Form';
  let allowCreate = props.permissions.digitalFormTemplates.create && !userId;

  if (isRequests) {
    buttonText = 'Request Signature';
    allowCreate = props.permissions.digitalFormRequests.create;
    CreateDialog = CreateRequest;
  }

  const { onClick, onClose, open } = useDialog({
    isOpen: 'create' in useQueryString({ history: props.history }),
  });

  const [openCopy, setOpenCopy] = React.useState(false);
  const CopyButton = {
    startIcon: <AddIcon />,
    onClick: () => setOpenCopy(true),
    text: 'Copy Template',
  };

  const headerProps = {
    title: isSelf ? 'My Digital Forms' : 'Digital Forms',
    icon: iconComponents('forms'),
    allowCreate: allowCreate,
    buttonText,
    onClick,
    extraButtons: preferences && preferences.betaTester ? [CopyButton] : [],
  };

  const dialogProps = {
    onClose,
    open,
    userId,
  };

  return (
    <>
      <Copy onClose={() => setOpenCopy(false)} open={openCopy} />
      <CreateDialog {...dialogProps} />
      <HeaderLayout {...headerProps} {...props}>
        {children}
      </HeaderLayout>
    </>
  );
};

export default withFeatureState((props) => (
  <Index {...props}>
    <TabLayout>
      {props.permissions.digitalFormTemplates.create && (
        <Tab title="Forms" path={digitalFormTemplatesPaths.root} component={TemplatesTable} />
      )}
      <Tab title="Signatures" path={digitalFormRequestsPaths.root} component={RequestsTable} />
    </TabLayout>
  </Index>
));
