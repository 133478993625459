import React from 'react';

// MUI
import Box from '@mui/material/Box';
import { styled } from '@mui/material/styles';

import TextField from 'components/v2/FormElements//TextField';
import TswAutocomplete from 'components/v2/FormElements/Autocomplete';
import * as colors from 'lib/v2/colors';
import { workflowIcons as icons } from 'lib/v2/icons';

// we could make this functional, but easier with this small set to just define them
const availableColors = {
  Purple: 'ticketsColor',
  Blue: 'blueMed',
  Green: 'greenLight',
  Teal: 'tealLight',
  Red: 'redMed',
};

export const colorOptions = Object.keys(availableColors).map((label) => ({
  color: colors[availableColors[label]],
  label,
  title: label,
}));

export const defaultColor = colorOptions.find(({ title }) => title === 'Purple');

const Swatch = styled(Box)(({ theme }) => ({
  width: '30px',
  height: '20px',
  display: 'inline',
  marginRight: '0.5rem',
  borderRadius: 7,
}));

export const ColorsAutocomplete = ({
  textFieldProps = {},
  label,
  placeHolder = 'Choose a custom color',
  ...rest
}) => (
  <TswAutocomplete
    options={colorOptions}
    renderOption={(props, option) => (
      <li {...props} key={option.label}>
        <Swatch sx={{ backgroundColor: option.color }} />
        {option.label}
      </li>
    )}
    renderInput={(params) => {
      const mappedColor = availableColors[params.inputProps.value];
      const selectedColor = colors[mappedColor];
      return (
        <TextField
          {...params}
          label={label}
          placeholder={placeHolder}
          InputProps={{
            ...params.InputProps,
            startAdornment: (
              <>{selectedColor && <Swatch sx={{ backgroundColor: selectedColor }} />}</>
            ),
          }}
          {...textFieldProps}
        />
      );
    }}
    {...rest}
  />
);

const labelToIcon = (label) => {
  if (!label) return;
  const trim = label.slice(0, -4);
  return trim.replace(/([A-Z])/g, ' $1').trim();
};

const iconToLabel = (value) => {
  if (!value) return;
  const selectedIconName = value.replace(' ', '');
  return icons[selectedIconName + 'Icon'];
};

export const iconOptions = Object.keys(icons).map((label) => ({
  icon: label,
  Icon: icons[label],
  label,
  title: labelToIcon(label),
}));

export const defaultIcon = iconOptions.find(({ icon }) => icon === 'MemoryIcon');

export const IconsAutocomplete = ({
  textFieldProps = {},
  label,
  placeHolder = 'Choose a custom icon',
  ...rest
}) => (
  <TswAutocomplete
    options={iconOptions}
    renderOption={(props, option) => (
      <li {...props} key={option.label}>
        <option.Icon />
        {option.title}
      </li>
    )}
    renderInput={(params) => {
      const Icon = iconToLabel(params.inputProps.value);

      return (
        <TextField
          {...params}
          label={label}
          placeholder={placeHolder}
          InputProps={{
            ...params.InputProps,
            startAdornment: <>{Icon && <Icon />}</>,
          }}
          {...textFieldProps}
        />
      );
    }}
    {...rest}
  />
);
