import { adaptToIso, adaptToDisplay } from 'adapters/v1/dates';
import { adaptJsonApiToDetail, adaptToJsonApiRequest } from 'adapters/v1/json_api';

import { cellAdapterFactory, cellLinkAdapterFactory } from 'adapters/v2/factories';

import { getCustomFieldPropNames } from 'lib/v1/customFields';

import { colorOptions, iconOptions } from 'components/v2/WorkflowControls/Autocomplete';

import { getUserDetails } from 'lib/v1/users';

const dateFields = ['updated_at', 'created_at'];
const baseAttributes = [
  'name',
  'instructions',
  'active',
  'icon',
  'approval_flow_enable',
  'force_approval_order',
  'onboarding_flow_enable',
];

export const adaptToRequest = (formData) => {
  const out = {};
  const relationships = {};

  const { reviewerEdit } = formData;

  const customFields = getCustomFieldPropNames('cardTemplates');

  // Instead of grabbing the entire object, just the items that might need sent
  [...customFields, ...baseAttributes].forEach((attr) => {
    if (formData[attr] === undefined) return;
    const hasLabel = formData[attr] && formData[attr].label !== undefined;
    out[attr] = hasLabel ? formData[attr].label : formData[attr];
  });

  out.color = formData.color && formData.color.color;

  if (formData.icon && formData.icon.icon) {
    out.icon = formData.icon && formData.icon.icon;
  }

  if (formData.statuses) {
    relationships.lanes = {
      data: formData.statuses.map(({ label: name, title: id }, i) => {
        // Filter out id from new statuses
        const originalId = `${id}`.includes('new') ? null : id;
        return { id: originalId, name, weight: i };
      }),
    };
  }

  if (typeof formData.attached_forms !== 'undefined') {
    const attached_forms = formData.attached_forms.map(({ label: formId, id }) => {
      return {
        resourceable_type: 'CardTemplate',
        resourceable_id: formData.id,
        attachable_id: formId,
        attachable_type: 'DigitalFormTemplate',
        id, // attachment id, null if being added
      };
    });

    const attached_documents = formData.attached_documents.map(({ label: docId, id }) => {
      return {
        resourceable_type: 'CardTemplate',
        resourceable_id: formData.id,
        attachable_id: docId,
        attachable_type: 'Document',
        id, // attachment id, null if being added
      };
    });

    relationships.attachments = { data: [...attached_forms, ...attached_documents] };
  }

  if (reviewerEdit !== undefined) {
    out.reviewer_id = reviewerEdit ? reviewerEdit.label : null;
    relationships.reviewers = {
      data: reviewerEdit ? [{ user_id: reviewerEdit.label }] : [],
    };
  }

  return adaptToJsonApiRequest({
    attributes: adaptToIso(out, dateFields),
    relationships,
    type: 'card_template',
  });
};

export const adaptToDetail = (resource) => {
  if (!resource) return {};
  const obj = adaptToDisplay(adaptJsonApiToDetail(resource), dateFields);

  const { reviewers = [], approvers = [] } = obj;

  const reviewer = reviewers[0] || {};
  obj.reviewer_id = reviewer.id;
  obj.reviewer = reviewer.name;

  obj.reviewerEdit = { title: obj.reviewer, label: reviewer.id };

  const approver = approvers[0] || {};
  obj.approver_id = approver.id;
  obj.approver = approver.name;
  obj.approverEdit = { title: obj.approver, label: approver.user_id };
  obj.approversEdit = approvers.map(({ name, user_id }) => ({ title: name, label: user_id }));

  obj.activeStatus = obj.active === true ? 'Yes' : 'No';
  if (obj.deleted_at) obj.activeStatus = 'Archived';

  if (obj.attachments) {
    const documents = obj.attachments
      .filter((obj) => obj.attachable && obj.attachable_type === 'Document')
      .map((obj) => ({ id: obj.id, title: obj.attachable.name, label: obj.attachable_id }));

    // TODO: Fix after migrating
    const forms = obj.attachments
      .filter((obj) => obj.attachable && ['DigitalFormTemplate'].includes(obj.attachable_type))
      .map((obj) => ({ id: obj.id, title: obj.attachable.title, label: obj.attachable_id }));

    obj.attached_forms = forms;
    obj.attached_documents = documents;
  }

  // not doing require anymore
  // obj.requireResponse = obj.attachments.some((a) => a.required);

  obj.statuses = (obj.lanes || []).map(({ id, name }) => ({
    title: id,
    label: name,
  }));

  if (obj.color) {
    obj.color = colorOptions.find(({ color }) => color === obj.color);
  }

  if (obj.icon) {
    obj.icon = iconOptions.find(({ icon }) => icon === obj.icon);
  }

  obj.reviewer_user = getUserDetails(obj.reviewer_id) || {};

  return obj;
};

export const adaptToForm = (resource) => {
  if (!resource) return {};
  return { ...resource };
};

// table adapters
export const cellAdapter = cellAdapterFactory(adaptToDetail);
export const cellLinkAdapter = cellLinkAdapterFactory(adaptToDetail);
