import React from 'react';

import Typography from '@mui/material/Typography';

// Shared
import AppContent from 'layouts/v2/AppContent';

// Components
import Loader from './Loader';
import InboxZero from './InboxZero';
import InboxFeed from './InboxFeed';
import MyStuff from 'components/v2/InboxV2/MyStuff';
import ReduxManager from 'components/v2/PageElements/ReduxManager';
import TicketsModule from 'components/v2/InboxV2/Tickets';

// Styles
import { StyledInboxContainer, StyledFeedContainer } from 'styles/Inbox/styles';

import { withFeatureState } from './state';

export const Inbox = ({
  actions,
  adminNotifications, // adminNotifications comes in from v2 notifications actions
  notifications, // notifications comes in from v2 notifications actions
  reminders,
  activities,
  isEmployee,
  listEmployeeItems,
  loading,
  preferences,
  profile,
  profileId,
  basic = false,
  onRefresh = listEmployeeItems,
}) => {
  // use V2 notifications if beta
  const effectiveActions = adminNotifications || notifications;

  React.useEffect(() => {
    !adminNotifications && listEmployeeItems();
  }, [listEmployeeItems, adminNotifications]);

  const filterAction = ({ type }) => {
    switch (type) {
      case 'card_template':
        return preferences.actionRequests || preferences.workflows;
      case 'draft_cards':
        return preferences.actionRequests || preferences.workflows;
      case 'training_assignments':
        return preferences.trainings;
      case 'signature_requests':
        return preferences.forms;
      case 'visitor_requests':
        return preferences.visits;
      case 'foreign_travel':
        return preferences.travels;
      case 'certificate_audits':
        return preferences.assets;
      default:
        return true;
    }
  };

  const visibleActions = effectiveActions.filter(filterAction);

  const Feed = () => (
    <>
      {loading && <Loader />}
      {!loading && (
        <InboxFeed
          feedItems={visibleActions}
          isEmployee={isEmployee}
          onRefresh={onRefresh}
          profileId={profileId}
        />
      )}
      {!loading && !visibleActions.length && <InboxZero />}
    </>
  );

  if (basic) return <Feed />;

  return (
    <ReduxManager data={['facilitiesDropdown', 'notifications']}>
      <AppContent>
        <Typography variant="h2" gutterBottom pt="12px">
          Welcome {profile.first_name} {profile.last_name}!
        </Typography>

        <TicketsModule />

        <StyledInboxContainer>
          <StyledFeedContainer>
            <Feed />
          </StyledFeedContainer>
        </StyledInboxContainer>
        <MyStuff />
      </AppContent>
    </ReduxManager>
  );
};

export default withFeatureState(Inbox);
