import { connect } from 'react-redux';

// Mui
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

// Components
import Buttons from './Dialog/Buttons';
import KeyboardDatePicker from 'components/v2/FormElements/KeyboardDatePicker';
import { TrainingsAutocomplete, UsersAutocomplete } from 'components/v2/FormElements/Autocomplete';
import { useForm, useRequiredFields } from 'components/v2/FormElements';

import { adaptToBulkTrainingRequest } from 'adapters/v1/people/formData';

import peopleActions from 'store/v2/people/actions';
import trainingAssignmentActions from 'store/v2/assignments/actions';
import attachmentActions from 'store/v2/attachments/actions';

const AddTraining = ({
  bulkAssignTraining,
  callback,
  createAttachment,
  loading,
  resourceable_id,
  resourceable_type,
  resource = {},
  subjects = [],
  onClose = () => {},
} = {}) => {
  const onSubmit = async ({ due_at, training_id, user_id }) => {
    // technically both users and training assignments are arrays and could be made multiple easily
    const attributes = adaptToBulkTrainingRequest({
      training_id: [training_id],
      notify: true,
      due_at,
    });

    const adapted = {
      data: {
        attributes,
        ids: [user_id.label],
        type: 'training_assignment',
      },
    };
    const trainingResp = await bulkAssignTraining(adapted);
    if (!trainingResp?.success) return;

    const assignmentIds = trainingResp.data?.map(({ id }) => id);

    const attachment = {
      resourceable_type,
      resourceable_id,
      attachable_type: 'TrainingAssignment',
      attachable_id: assignmentIds[0],
    };
    const attachmentResp = await createAttachment(attachment);
    if (!attachmentResp?.success) return;
    callback && callback(attachmentResp);
    onClose();
  };

  const validate = useRequiredFields(['due_at', 'training_id', 'user_id']);

  const {
    handleSubmit,
    getRequiredTextProps,
    getSelectHandlerProps,
    getTextFieldHandlerProps,
    formData,
  } = useForm({
    validate,
    resource,
    afterCancel: onClose,
    callback: onSubmit,
    defaults: { due_at: new Date(Date.now() + 12096e5) },
  });

  const quickAssignOptions = subjects.map(({ id, title }) => ({ label: id, title }));
  const userPrependOptions = [...quickAssignOptions];

  return (
    <Box>
      <List>
        <ListItem>
          <UsersAutocomplete
            {...getSelectHandlerProps('user_id')}
            textFieldProps={{ ...getRequiredTextProps('user_id') }}
            groupByLabel={'All Users'}
            label="User"
            multiple={false}
            prependOptions={userPrependOptions || []}
            value={formData.user_id || []}
          />
        </ListItem>
        <ListItem>
          <TrainingsAutocomplete
            {...getSelectHandlerProps('training_id')}
            textFieldProps={{ ...getRequiredTextProps('training_id') }}
            label="Training Course"
            multiple={false}
            value={formData.training_id || []}
          />
        </ListItem>
        <ListItem>
          <KeyboardDatePicker
            {...getTextFieldHandlerProps('due_at')}
            {...getRequiredTextProps('due_at')}
            label="Due Date"
            placeholder=""
          />
        </ListItem>
      </List>
      <Buttons
        clickText="Assign Training"
        onClick={handleSubmit}
        onCancel={onClose}
        loading={loading}
      />
    </Box>
  );
};

export default connect(({ documentsV2: { loading } }) => ({ loading }), {
  bulkAssignTraining: peopleActions.bulkAssignTraining,
  createAssignment: trainingAssignmentActions.create,
  createAttachment: attachmentActions.create,
})(AddTraining);
