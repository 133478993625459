import actionRequests from 'permissions/v1/actionRequests';
import activity from 'permissions/v1/activity';
import admin from 'permissions/v1/admin';
import analytics from 'permissions/v1/analytics';
import assets from 'permissions/v1/assets';
import assetControls from 'permissions/v1/assetControls';
import badges from 'permissions/v1/badges';
import badgeControls from 'permissions/v1/badgeControls';

import boilerplateScaffolding from 'permissions/v1/boilerplateScaffolding';

import clientCredentials from 'permissions/v1/clientCredentials';
import comments from 'permissions/v1/comments';
import company from 'permissions/v1/company';
import contacts from 'permissions/v1/contacts';
import containerControls from 'permissions/v1/containerControls';
import contractUsers from 'permissions/v1/contractUsers';
import contracts from 'permissions/v1/contracts';
import controls from 'permissions/v1/controls';
import customRoles from 'permissions/v1/customRoles';
import customFields from 'permissions/v1/customFields';
import documents from 'permissions/v1/documents';
import facilities from 'permissions/v1/facilities';
import forms from 'permissions/v1/forms';
import imports from 'permissions/v1/imports';
import library from 'permissions/v1/library';
import people from 'permissions/v1/people';
import reportable from 'permissions/v1/reportable';
import reportableTemplates from 'permissions/v1/reportableTemplates';
import requestTemplates from 'permissions/v1/requestTemplates';
import requirements from 'permissions/v1/requirements';
import requirementControls from 'permissions/v1/requirementControls';
import secretKeys from 'permissions/v1/secretKeys';
import tags from 'permissions/v1/tags';
import tagNames from 'permissions/v1/tagNames';
import tasks from 'permissions/v1/tasks';
import tickets from 'permissions/v1/tickets';
import training from 'permissions/v1/training';
import travels from 'permissions/v1/travels';
import travelSettings from 'permissions/v1/travelSettings';
import users from 'permissions/v1/users';
import userAccess from 'permissions/v1/userAccess';
import userEligibility from 'permissions/v1/userEligibility';
import userFacilities from 'permissions/v1/userFacilities';
import userPolygraph from 'permissions/v1/userPolygraph';
import visits from 'permissions/v1/visits';
import workflows from 'permissions/v1/workflows';

import attachedDigitalForms from 'permissions/v1/attachedDigitalForms';
import digitalFormRequests from 'permissions/v1/digitalFormRequests';
import digitalFormTemplates from 'permissions/v1/digitalFormTemplates';

// Profile permissions are atypical and highly granular.
import _profile from 'permissions/v1/users/profile';

// We'll just iteratively do this based on profile and view
export const mapPermissionsToProps = ({ profile }, id = null) => {
  return {
    permissions: {
      activity: activity(profile),
      actionRequests: actionRequests(profile),
      admin: admin(profile),
      analytics: analytics(profile),
      assets: assets(profile),
      assetControls: assetControls(profile),
      badges: badges(profile),
      badgeControls: badgeControls(profile),

      boilerplateScaffolding: boilerplateScaffolding(profile),

      attachedDigitalForms: attachedDigitalForms(profile),
      clientCredentials: clientCredentials(profile),
      contacts: contacts(profile),
      containerControls: containerControls(profile),
      contracts: contracts(profile),
      controls: controls(profile),
      contractUsers: contractUsers(profile),
      comments: comments(profile),
      company: company(profile),
      customRoles: customRoles(profile),
      customFields: customFields(profile),
      documents: documents(profile),
      digitalFormRequests: digitalFormRequests(profile),
      digitalFormTemplates: digitalFormTemplates(profile),
      facilities: facilities(profile),
      forms: forms(profile),
      imports: imports(profile),
      library: library(profile),
      people: people(profile),
      profile: _profile(profile, id), // Profile can take additional params including an "id"
      reportable: reportable(profile),
      reportableTemplates: reportableTemplates(profile),
      requestTemplates: requestTemplates(profile),
      requirements: requirements(profile),
      requirementControls: requirementControls(profile),
      secretKeys: secretKeys(profile),
      tags: tags(profile),
      tagNames: tagNames(profile),
      tasks: tasks(profile),
      tickets: tickets(profile),
      training: training(profile),
      travels: travels(profile),
      travelSettings: travelSettings(profile),
      users: users(profile),
      userAccess: userAccess(profile),
      userEligibility: userEligibility(profile),
      userFacilities: userFacilities(profile),
      userPolygraph: userPolygraph(profile),
      visits: visits(profile),
      workflows: workflows(profile),
    },
  };
};
