import React from 'react';
import Tooltip from 'components/v2/FormElements/Tooltip';

import { uniqueId } from 'lib/v1';
import Checkbox from 'components/v2/Checkbox';

const IndeterminateCheckbox = ({ indeterminate, ...rest }, ref) => {
  // rest: checked, onChange, style, title
  const defaultRef = React.useRef();
  const resolvedRef = ref ?? defaultRef;
  const id = `check-id-${uniqueId()}`;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <Checkbox id={id} ref={resolvedRef} checked={rest.checked} onClick={rest.onChange} {...rest} />
  );
};

export const IndeterminateTooltipCheckbox = ({ indeterminate, tooltip, ...rest }, ref) => {
  const defaultRef = React.useRef();
  const resolvedRef = ref ?? defaultRef;
  const id = `check-id-${uniqueId()}`;

  React.useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate]);

  return (
    <Tooltip title={tooltip} disableHoverListener={!rest.disabled}>
      <Checkbox
        id={id}
        ref={resolvedRef}
        checked={rest.checked}
        onClick={rest.onChange}
        {...rest}
      />
    </Tooltip>
  );
};

export default IndeterminateCheckbox;
