import React from 'react';

// DND Kit
import { DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { SortableContext, arrayMove, sortableKeyboardCoordinates } from '@dnd-kit/sortable';

// Icons
import AddCircleIcon from '@mui/icons-material/AddCircle';

// MUI
import List from '@mui/material/List';
import { styled } from '@mui/material/styles';

// Components
import Button from '../Button';
import ListItem from '../ListItem';
import SortableItem from './SortableItem';
import { SortableOverlay } from './SortableOverlay';

const StyledListItem = styled(ListItem)({
  display: 'flex',
  backgroundColor: 'rba(255, 255, 255, 0.8)',
  '& .MuiInputAdornment-root': {
    margin: '0 !important',
  },
  '& .MuiIconButton-root': {
    padding: 0,
  },
  '& .MuiSvgIcon-root': {
    width: '2rem',
  },
});

const noop = () => {};

const SortableList = ({
  addLabel = 'Add New',
  allowEmpty = true,
  items = [],
  itemLabel,
  onChange = noop,
  onItemAdd = noop,
  ...rest
}) => {
  const showDelete = allowEmpty ? true : items.length > 1;

  const [active, setActive] = React.useState(null);
  const activeItem = React.useMemo(
    () => items.find(({ id }) => id === active?.id),
    [active, items]
  );

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const ActiveItem = (props) => (
    <SortableItem {...sortableProps(props)}>
      <SortableItem.TextField {...sortableProps(props)} />
    </SortableItem>
  );

  const sortableProps = ({ id, title, label }) => {
    return {
      fieldLabel: `${itemLabel} ${id + 1}`,
      id,
      title,
      showDelete,
      value: label,
      ...rest,
    };
  };

  return (
    <>
      <DndContext
        sensors={sensors}
        onDragStart={({ active }) => {
          setActive(active);
        }}
        onDragEnd={({ active, over }) => {
          if (over && active.id !== over?.id) {
            const activeIndex = items.findIndex(({ id }) => id === active.id);
            const overIndex = items.findIndex(({ id }) => id === over.id);

            onChange(arrayMove(items, activeIndex, overIndex));
          }
          setActive(null);
        }}
        onDragCancel={() => {
          setActive(null);
        }}
      >
        <SortableContext items={items}>
          <List key="sortable-list">
            {items.map((props) => (
              <>
                <SortableItem {...sortableProps(props)} key={`sortable-item-${props.id}`}>
                  <SortableItem.TextField
                    {...sortableProps(props)}
                    key={`sortable-item-field-${props.id}`}
                  />
                </SortableItem>
              </>
            ))}
          </List>
        </SortableContext>
        <SortableOverlay>{activeItem ? ActiveItem(activeItem) : null}</SortableOverlay>
      </DndContext>

      <StyledListItem disableGutters>
        <Button color="primary" variant="text" onClick={onItemAdd} startIcon={<AddCircleIcon />}>
          {addLabel}
        </Button>
      </StyledListItem>
    </>
  );
};

export default SortableList;
