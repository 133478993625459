import {
  isAdmin,
  isAnyAdmin,
  isSecurityManager,
  isAsstSecurityManager,
  isCustomRole,
  isEmployee,
} from 'permissions/v1/roles';
import userPermissions from 'permissions/v1/users';

const piiCustomRole = (profile) =>
  profile.custom_role ? profile.custom_role.secure_info_authorized : false;

// TODO: I can't say I love this implementation exactly
// but I do like that the isSelf logic is encapsulated in a single
// easily unit testable place rather than elsewhere
// The challenge though is getting the ID param from the router
// to all permissions....
const permissions = (profile, id = null) => {
  const parentPermissions = userPermissions(profile);
  const update = parentPermissions.update;
  const view = parentPermissions.view;
  const hasPii = piiCustomRole(profile);
  const isSelf = profile.id === id;

  const _isAdmin = isAdmin(profile);
  const _isAnyAdmin = isAnyAdmin(profile);
  const _isSecurityManager = isSecurityManager(profile);
  const _isAsstSecurityManager = isAsstSecurityManager(profile);
  const _isCustomRole = isCustomRole(profile);

  const employeeProfileEditEnabled = profile?.company?.preference?.employee_profile_edit_enable;

  return {
    isAdmin: _isAdmin,
    isAnyAdmin: _isAnyAdmin,
    isAsstSecurityManager: _isAsstSecurityManager,
    isCustomRole: _isCustomRole,
    isEmployee: isEmployee(profile),
    isSecurityManager: _isSecurityManager,
    isSelf: isSelf,
    account: {
      update: update,
      view: view || isSelf,
      activate: !isSelf && update && (_isAnyAdmin || _isCustomRole),
      sso_disable: !isSelf && update && (_isAnyAdmin || _isCustomRole),
      two_factor: isSelf || (update && _isAdmin),
      role: !isSelf && _isAnyAdmin,
      password: isSelf,
      picture: isSelf || update,
      welcomeEmail: !isSelf && update && (_isAnyAdmin || _isCustomRole),
    },
    basic: {
      update: update,
      view: view || isSelf,
    },
    employee: {
      update: update,
      view: view || isSelf,
    },
    contact: {
      update: update || (isSelf && employeeProfileEditEnabled),
      view: view || isSelf,
    },
    emergencyContact: {
      update: update || (isSelf && employeeProfileEditEnabled),
      view: view || isSelf,
    },
    assignedFacilities: {
      update: update,
      view: view || isSelf,
    },
    pii: {
      update: (hasPii || _isAnyAdmin) && update,
      view: ((hasPii || _isAnyAdmin) && view) || isSelf,
    },
    passport: {
      update: update || (isSelf && employeeProfileEditEnabled),
      view: view || isSelf,
    },
  };
};

export default permissions;
