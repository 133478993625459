import 'core-js/stable';
import 'regenerator-runtime/runtime';
import raygun from 'raygun4js';
import { createRoot } from 'react-dom/client';
import { LicenseInfo } from '@mui/x-license-pro';

import App from 'App';

LicenseInfo.setLicenseKey(import.meta.env.MUI_X_PRO_KEY);

if (import.meta.env.REACT_APP_RAYGUN_ENABLED) {
  raygun('apiKey', import.meta.env.REACT_APP_RAYGUN_KEY);
  raygun('enableCrashReporting', true);
  raygun('enablePulse', true);
  raygun('withTags', ['react', import.meta.env.REACT_APP_ENVIRONMENT ?? 'unknown']);
  raygun('options', {
    ignoreAjaxAbort: true,
    ignoreAjaxError: true,
    ignore3rdPartyErrors: true,
  });
}

const container = document.getElementById('app');
const root = createRoot(container);
root.render(<App />);
