import React from 'react';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';

import 'react-dates/lib/css/_datepicker.css';

const DateRange = ({ callback }) => {
  const [value, setValue] = React.useState(null);
  const [focusedInput, setFocusedInput] = React.useState(null);

  const handleChange = (value) => {
    const { startDate, endDate } = value;

    if (startDate && endDate && callback) {
      callback([startDate, endDate]);
    }

    setValue(value);
  };

  return (
    <DateRangePicker
      startDate={value?.startDate}
      endDate={value?.endDate}
      isOutsideRange={() => false}
      onDatesChange={handleChange}
      focusedInput={focusedInput}
      onFocusChange={(i) => setFocusedInput(i)}
    />
  );
};

export default DateRange;
