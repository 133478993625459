import { adaptToIso, adaptToDisplay } from 'adapters/v1/dates';
import {
  cellAdapterFactory,
  cellLinkAdapterFactory,
  arrayCountCellAdapterFactory,
  chipCellAdapterFactory,
  comboCellAdapterFactory,
} from 'adapters/v2/factories';
import {
  adaptToJsonApiRequest,
  adaptJsonApiToDetail,
  adaptTagsToRequest,
} from 'adapters/v1/json_api';
import { adaptSelectToRequest, adaptSingleSelectToForm } from 'adapters/v1/index';
import { adaptTagsToForm } from 'adapters/v1';
import { getCustomFieldPropNames } from 'lib/v1/customFields';

const dateFields = ['start_date', 'end_date'];

const singleSelectFields = [
  'facility_clearance_level',
  'safeguarding_level',
  'contract_type',
  'department',
  'status',
];

export const types = {
  prime: 'Prime',
  subcontract: 'Subcontract',
  subcontract_generated: 'Subcontract Generated',
  subcontract_received: 'Subcontract Received', // note the R.  We'll need to resolve this at some point.  But today is not that day.
};

export const adaptContractTagsToRequest = (formData) =>
  adaptTagsToRequest({ formData, type: 'contract' });

export const adaptToRequest = (formData) => {
  if (!formData) return {};
  const adapted = adaptToIso(formData, dateFields);
  const relationships = {};

  singleSelectFields.forEach((key) => {
    adapted[key] = adaptSelectToRequest(adapted ? adapted[key] : {});
  });

  if (adapted.programManagerEdit !== undefined) {
    adapted.program_manager_id = adapted.programManagerEdit?.label || null;
  }

  // Adapt model level select-type custom fields
  getCustomFieldPropNames('contractsV2').forEach((fieldName) => {
    if (!formData[fieldName]) return;
    if (!formData[fieldName]?.label) return;
    adapted[fieldName] = formData[fieldName]?.label;
  });

  if (adapted.facilities) {
    relationships.facilities = {
      data: adapted.facilities.map(({ label }) => ({ id: label })),
    };
    adapted.facilities = undefined;
  }

  return adaptToJsonApiRequest({ attributes: adapted, relationships, type: 'contract' });
};

export const adaptToDetail = (resource) => {
  if (!resource) return {};
  const adapted = adaptToDisplay(adaptJsonApiToDetail(resource), dateFields);

  if (adapted.tag_names) adapted.tag_array = adapted.tag_names?.map((obj) => obj.name);

  if (adapted.contract_type) {
    adapted.contract_type = types[adapted.contract_type?.toLowerCase()] || adapted.contract_type;
  }

  adapted.programManagerName = adapted.program_manager?.name || '';
  adapted.facility_name = adapted.facility ? adapted.facility.display_name : '';

  return adapted;
};

export const adaptToForm = (resource) => {
  if (!resource) return {};
  const out = resource;
  const { tag_names, facilities, program_manager } = adaptToDetail(resource);
  const { tags } = adaptTagsToForm({ tag_names });

  if (program_manager) {
    out.programManagerEdit = { title: program_manager.name, label: parseInt(program_manager.id) };
  }

  if (facilities) {
    out.facilities = facilities.map((f) => ({ title: f.display_name, label: f.id }));
  }

  return {
    ...out,
    tags,
    ...adaptSingleSelectToForm({ resource, fieldNames: singleSelectFields }),
  };
};

export const cellAdapter = cellAdapterFactory(adaptToDetail);
export const cellLinkAdapter = cellLinkAdapterFactory(adaptToDetail);
export const arrayCountCellAdapter = arrayCountCellAdapterFactory(adaptToDetail);
export const chipCellAdapter = chipCellAdapterFactory(adaptToDetail);
export const comboCellAdapter = comboCellAdapterFactory();
