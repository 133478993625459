import { memo, useRef } from 'react';
import { uniqueId } from 'lib/v1';

import Checkbox from '@mui/material/Checkbox';

import { styled } from '@mui/material/styles';

export const StyledWrapper = styled('div')({
  alignItems: 'flex-start',
  display: 'flex',
  position: 'relative',
  lineHeight: '20px',
  margin: '-1em', // ? Not sure if we need this, or if the margin is better handled elsewhere
});

const StyledIcon = styled('div')({
  padding: '4px',
});

const StyledText = styled('label')({
  color: '#9b9b9b',
  fontSize: '16px',
  paddingLeft: '12px',
});

const CheckBox = ({ checked, className, disabled = false, icon, label, onClick, ...rest }) => {
  const innerLabel = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const identifier = useRef(uniqueId());
  const id = `${label}${identifier.current}`;

  const props = { checked, disabled, id, name: id, onClick, ...innerLabel, ...rest };
  return (
    <>
      <StyledWrapper className={className}>
        <Checkbox {...props} />
        {label && <StyledText htmlFor={id}>{label}</StyledText>}
        <StyledIcon>{icon}</StyledIcon>
      </StyledWrapper>
    </>
  );
};

export default memo(CheckBox);
