import React from 'react';

// Mui
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { styled } from '@mui/material/styles';

// Components
import Button from 'components/v2/FormElements/Button';
import CardButton from 'components/v2/FormElements/CardButton';
import Dialog, { useDialog } from 'components/v2/FormElements/Dialog';
import { icons } from 'lib/v2/icons';
import ReduxManager from 'components/v2/PageElements/ReduxManager';

import AddLinkForm from 'components/v2/FormElements/AddLinkForm';
import AddDocumentForm from 'components/v2/FormElements/AddDocumentForm';
import AddDigitalForm from 'components/v2/FormElements/AddDigitalForm';
import AddTrainingForm from 'components/v2/FormElements/AddTrainingForm';

// Icons
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ArticleIcon from '@mui/icons-material/Article';
import LinkIcon from '@mui/icons-material/Link';

const DEFAULT_LABEL = 'Add Attachment';

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  display: 'grid',
  gridAutoColumns: '1fr',
  gap: '0',
}));

const AddAttachment = ({
  callbackDocument,
  callbackForm,
  callbackTraining,
  loading,
  includeForm = false,
  includeTraining = false,
  label = DEFAULT_LABEL,
  preferences,
  ...rest
} = {}) => {
  const { onClick, onClose, open } = useDialog();
  const [content, setContent] = React.useState('attachment');

  const handleChange = (event, content) => {
    if (content !== null) {
      setContent(content);
    }
  };

  const documentProps = {
    callback: callbackDocument,
    onClose,
    ...rest,
  };

  const formProps = {
    callback: callbackForm,
    onClose,
    ...rest,
  };

  const trainingProps = {
    callback: callbackTraining,
    onClose,
    ...rest,
  };

  const TrainingIcon = icons.training;

  const reduxData = [];
  if (includeForm) reduxData.push('digitalFormTemplatesDropdown');
  if (includeTraining) reduxData.push('trainingsDropdown');

  return (
    <ReduxManager data={reduxData}>
      <Box display="flex">
        <Button
          component="span"
          color="primary"
          variant="text"
          disableRipple
          onClick={onClick}
          startIcon={loading ? <CircularProgress size={'1em'} /> : <AddCircleIcon />}
        >
          {label}
        </Button>

        <Dialog title={label} onCancel={onClose} onClose={onClose} open={open} maxWidth="md">
          <StyledToggleButtonGroup value={content} exclusive onChange={handleChange}>
            <CardButton icon={<LinkIcon />} label="Link" value="link" />
            <CardButton icon={<AttachFileIcon />} label="File" value="attachment" />
            {includeForm && <CardButton icon={<ArticleIcon />} label="Form" value="form" />}
            {includeTraining && (
              <CardButton icon={<TrainingIcon />} label="Training" value="training" />
            )}
          </StyledToggleButtonGroup>

          {content === 'link' && (
            <>
              <AddLinkForm {...documentProps} />
            </>
          )}

          {content === 'attachment' && (
            <>
              <AddDocumentForm {...documentProps} />
            </>
          )}

          {content === 'form' && includeForm && (
            <>
              <AddDigitalForm {...formProps} />
            </>
          )}

          {content === 'training' && (
            <>
              <AddTrainingForm {...trainingProps} />
            </>
          )}
        </Dialog>
      </Box>
    </ReduxManager>
  );
};

export default AddAttachment;
