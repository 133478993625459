import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';

import AppRoutes from 'routes';
import store from 'store';

import 'assets/styles/manticore.css';
import 'assets/styles/global.css';

import TswTheme from 'components/v2/Theme';
import history from 'config/history';
import PageTitle from 'components/v2/PageElements/PageTitle';

const devTools = import.meta.env.__REDUX_DEVTOOLS_EXTENSION__;
devTools && devTools();

const App = () => {
  return (
    <Provider store={store}>
      <Router history={history}>
        <PageTitle>
          <TswTheme>
            <AppRoutes />
          </TswTheme>
        </PageTitle>
      </Router>
    </Provider>
  );
};

export default App;
