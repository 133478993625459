import { createActions, createAction } from 'redux-actions';
import { add_message } from 'store/v1/app/actions';

import history from 'config/history';
import { apiHosts } from 'config/index';

import { currentUserCompany } from 'lib/v1/users';
import request, { newRequest } from 'lib/v2/request';
import handleError from 'lib/v1/error-handling';
import CONST from './constants';

export const loginSuccess = createAction(CONST.LOGIN_SUCCESS);
export const refreshProfileSuccess = createAction(CONST.REFRESH_PROFILE_SUCCESS);

const { loginPending } = createActions(CONST.LOGIN_PENDING);
const { logoutPending } = createActions(CONST.LOGOUT_PENDING);
const { logoutSuccess } = createActions(CONST.LOGOUT_SUCCESS);

const resetHistory = () => {
  history.entries = [];
  history.index = -1;
  history.push(`/`);
};

export function login({ email, password, code, remember }) {
  return (dispatch) => {
    dispatch(loginPending());
    return request
      .post('/v1/auth/sign_in')
      .send({ email, password, code, remember })
      .then((res) => {
        resetHistory();
        // Restricting to only the minimally necessary values
        // We need id to persist between reloads and use it to
        // refresh the user profile.  Previous data about permissions
        // roles was stored here and was being pulled into the app
        // by the legacy /lib/v1/permissions implementation.
        //
        // This made it possible for a lower privilege user with
        // technical knowledge to escalate privileges potentially
        const { id } = res.body.data;

        // I'm pulling company because I want to make sure we don't run into any
        // weird statefulness issues for company logos etc. I encountered
        // this in development but I think they are unlikely to be possible
        // outside of the context of tweaking with localStorage.

        window.localStorage.setItem('profile', JSON.stringify({ id }));
        window.sessionStorage.removeItem('sso_auto_redirect_paused');
        return dispatch(loginSuccess(res.body.data));
      })
      .catch((e) => {
        const response = e.response;
        if (response && response.body && response.body.redirect_url) {
          window.localStorage.setItem('password_expired', 'true');
          setTimeout(() => (window.location.href = response.body.redirect_url), 500);
          return;
        } else if (response && response.status === 403) {
          window.localStorage.setItem('two_factor_required', 'true');
          return { mfaEnabled: true };
        } else if (response && response.status === 401) {
          const message = (response && response.body && response.body.errors) || [
            'Invalid username or password.',
          ];
          return dispatch(add_message('login_error', Array.isArray(message) ? message : [message]));
        } else {
          handleError(e);
          return e;
        }
      });
  };
}

// TODO these all need to be cleaned up and split out.
// This is core auth + password stuff.
export function resetInitiation({ email }) {
  return (dispatch) => request.post('/v2/auth/passwords').send({ email }).catch(handleError);
}

export function show(token_id, id) {
  return (dispatch) => {
    return request.get(`/v2/auth/passwords/${token_id}/users/${id}`).catch(handleError);
  };
}

export const settings = ({ email } = {}) => {
  return (dispatch) => {
    const requests = apiHosts.map((host) => {
      return newRequest({ host }).get('/v1/auth').query({ email }).catch(handleError);
    });

    return Promise.allSettled(requests);
  };
};

export const changeAccount = () => {
  return (dispatch) => {
    return request.del('/v1/auth').catch(handleError);
  };
};

export function reset({ password, password_confirmation }, headers) {
  return (dispatch) => {
    return request
      .put('/v2/auth/passwords')
      .send({ password, password_confirmation })
      .catch(handleError);
  };
}

export const update = ({ password, password_confirmation }) => {
  return (dispatch) => {
    return request
      .put('/v2/auth/passwords')
      .send({ password, password_confirmation })
      .then((response) => {
        const { id } = response.body.data;
        // Still have to do this until I remove the dependency on local storage
        window.localStorage.setItem('profile', JSON.stringify({ id }));
        dispatch(loginSuccess(response.body.data));
        return true;
      })
      .catch(handleError);
  };
};

export const handleLogout = () => {
  // window.localStorage.clear(); //removeItem('profile');
  window.localStorage.removeItem('profile');
  window.localStorage.removeItem('apc_user_id');
  window.sessionStorage.setItem('sso_auto_redirect_paused', true);
  try {
    window.Intercom('shutdown');
  } catch (e) {
    console.log(e); // eslint-disable-line
  }
};

// Yay Oracle!
const ssoLogout = async () => {
  const { sso_required, saml_provider_logout_url } = currentUserCompany();

  if (!sso_required) return;
  if (!saml_provider_logout_url) return;

  // with the new window below, this is redundant but leaving it for now
  const logoutResponse = await request.get(saml_provider_logout_url).catch(() => {});

  // Leaving this for now in case it helps debug any issues
  console.log('ssoLogout', logoutResponse); // eslint-disable-line

  // this makes safari more likely to open the new window
  setTimeout(() => {
    const handle = window.open(saml_provider_logout_url, '_blank');

    // if window is blocked as popup, handle will be null
    if (handle) {
      console.log('handle.close()'); // eslint-disable-line
      setTimeout(() => handle.close(), 1000);
    }

    // last resort, just redirect
    else {
      window.location.href = saml_provider_logout_url;
    }
  }, 10);
};

export const logout = () => {
  return async (dispatch) => {
    dispatch(logoutPending());

    await ssoLogout();

    await request.del('/v1/auth/sign_out').catch(handleError);
    handleLogout();
    dispatch(logoutSuccess());
    resetHistory();
  };
};
