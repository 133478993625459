import { adaptToIso, adaptToDisplay } from 'adapters/v1/dates';
import { cellAdapterFactory, cellLinkAdapterFactory } from 'adapters/v2/factories';
import { adaptJsonApiToDetail, adaptToJsonApiRequest } from 'adapters/v1/json_api';

import { colorOptions, iconOptions } from 'components/v2/WorkflowControls/Autocomplete';

import { getCustomFieldPropNames } from 'lib/v1/customFields';
import { getUserDetails } from 'lib/v1/users';

import { getSortedApprovals } from 'components/v2/Approvers/Hooks';

const dateFields = [
  'updated_at',
  'created_at',
  'submitted_at',
  'closed_at',
  'submission_due_at',
  'approved_at',
  'declined_at',
];
const baseAttributes = [
  'submitted_at',
  'submission_due_at',
  'weight',
  'lane',
  'closed_at',
  'card_template_id',
  'active',
  'onboarding_form_email',
];

export const adaptApprovalToRequest = (approver) => {
  return adaptToJsonApiRequest({
    attributes: {},
    relationships: {
      approvers: {
        data: [approver],
      },
    },
    type: 'card',
  });
};

export const adaptToRequest = (formData) => {
  if (!formData) return {};
  const out = {};
  const relationships = {};

  const { template, additional_custom_fields = [] } = formData;

  if (template) out.card_template_id = template.id;

  // model level
  const modelCustomFields = getCustomFieldPropNames('cards');
  // create gets them from the template, update gets them from the card
  const additionalCustomFields = (
    template ? template.instance_custom_fields : additional_custom_fields
  ).map(({ field_name }) => field_name);

  const allCustomFields = [...new Set([...additionalCustomFields, ...modelCustomFields])];

  // Instead of grabbing the entire object, just the items that might need sent
  [...allCustomFields, ...baseAttributes].forEach((attr) => {
    if (formData[attr] === undefined) return;
    const hasLabel = formData[attr] && formData[attr].label !== undefined;
    out[attr] = hasLabel ? formData[attr].label : formData[attr];
  });

  const {
    lane,
    submitter,
    assignee,
    assigneeType,
    reviewerEdit,
    submitterEdit,
    first_name,
    last_name,
    phone,
    email,
  } = formData;

  if (lane) out.lane_id = lane.label;

  if (assignee || assigneeType === 'contactNew') {
    switch (assigneeType) {
      case 'contact':
        relationships.assignees = {
          data: [{ contact_id: assignee.label }],
        };
        break;
      case 'contactNew':
        relationships.assignees = {
          data: [
            {
              contact: {
                email: email,
                first_name: first_name,
                last_name: last_name,
                phone: phone,
              },
            },
          ],
        };
        break;
      default:
        relationships.assignees = {
          data: [{ user_id: assignee.label }],
        };
    }
  }

  if (submitter) out.submitter_id = submitter.label;

  if (submitterEdit) out.submitter_id = submitterEdit.label;

  if (reviewerEdit !== undefined) {
    out.reviewer_id = reviewerEdit ? reviewerEdit.label : null;
    relationships.reviewers = {
      data: reviewerEdit ? [{ user_id: reviewerEdit.label }] : [],
    };
  }

  return adaptToJsonApiRequest({
    attributes: adaptToIso(out, dateFields),
    relationships,
    type: 'card',
  });
};

export const adaptToDetail = (resource) => {
  if (!resource) return {};
  const obj = adaptJsonApiToDetail(resource);

  const {
    created_by,
    reviewers = [],
    approvers = [],
    members = [],
    submitter,
    attachments = [],
  } = obj;

  obj.attachedTrainingAssignments = attachments
    .filter(({ attachable_type }) => attachable_type === 'TrainingAssignment')
    .map(({ attachable, meta, id }) => ({
      ...attachable,
      attachmentId: id,
      training: meta.training,
      user: meta.user,
    }));

  const subjectMember = members.filter(({ kind }) => kind === 'assignee')[0] || {};
  obj.subject_id = subjectMember.user_id;
  obj.subject = subjectMember.name;

  const reviewer = reviewers[0] || {};
  obj.reviewer_id = reviewer.id;
  obj.reviewer = reviewer.name;
  obj.reviewerEdit = { title: obj.reviewer, label: reviewer.id };
  obj.reviewerMember = members.filter(({ kind }) => kind === 'reviewer')[0] || {};

  const sortedApprovals = getSortedApprovals(approvers);
  const approver = sortedApprovals.at(-1) || {};
  obj.approver_id = approver.user_id;
  obj.approver_ids = approvers.map(({ user_id }) => user_id);
  obj.approver = approver.name;
  obj.approverEdit = { title: obj.approver, label: approver.user_id };
  obj.approved_at = approver.approved_at;
  obj.declined_at = approver.declined_at;

  if (obj.lanes && obj.lanes.length) {
    const numLanes = obj.lanes.length;
    obj.lanes = obj.lanes.map(({ id, name }, i) => ({
      name,
      // title: `${name} (step ${i + 1} of ${numLanes})`,
      title: name,
      label: id,
      progress: `(${i + 1}/${numLanes})`,
    }));
    obj.lane = obj.lane_id ? obj.lanes.find(({ label }) => label === obj.lane_id) : {};
    obj.status = obj.lane_id && obj.lane.name;
  }

  let title = obj.active ? 'Active' : 'Closed';

  obj.submitted = !!obj.submitted_at;
  if (!obj.submitted && obj.active) {
    title = 'Draft';
  }

  obj.active_status = title;

  obj.submission_past_due = obj.submission_due_at && new Date(obj.submission_due_at) < Date.now();

  if (created_by && created_by.name) {
    obj.submitted_by = submitter ? submitter.name : created_by.name;
    if (!obj.submitted && obj.submission_due_at) {
      obj.submitted_by += ` by ${obj.submission_due_at}`;
    }
    if (!obj.submitted && submitter) {
      obj.submitterEdit = { title: submitter.name, label: submitter.id };
    } else {
      obj.submitterEdit = { title: created_by.name, label: created_by.id };
    }

    obj.submitter_id = submitter ? submitter.id : created_by.id;

    if (!obj.active) {
      obj.description = `Closed`;
      // the closed_at prop gets set async so won't always be available immediately
      if (obj.closed_at) obj.description += ` on ${obj.closed_at}`;
    } else if (obj.submitted) {
      obj.description = `Submitted on ${obj.submitted_at}`;
    } else {
      obj.description = `Created on ${obj.created_at}`;
    }
  }

  obj.archived = !obj.active;
  obj.templateName = obj.card_template?.name;

  if (obj.card_template?.color) {
    obj.color = colorOptions.find(({ color }) => color === obj.card_template.color);
  }

  if (obj.card_template?.icon) {
    obj.icon = iconOptions.find(({ icon }) => icon === obj.card_template.icon);
  }

  if (obj.subject) {
    obj.name += ` for ${obj.subject}`;
  }

  if (obj.subject_id) {
    obj.subject_user = getUserDetails(obj.subject_id) || {};
  } else if (obj.subject) {
    obj.subject_user = {
      name: subjectMember.name,
      email: subjectMember.email,
      contact: true,
      contact_id: subjectMember.contact_id,
    };
  }

  obj.searchableString = `${obj.id} ${obj.subject}`.toLowerCase();

  // TODO: get reviewers from members as well like assignee is now doing
  obj.reviewer_user = getUserDetails(obj.reviewer_id) || {};

  obj.hasOnboardingData = !!obj.onboarding_data && Object.keys(obj.onboarding_data).length > 0;

  return adaptToDisplay(obj, dateFields);
};

// table adapters
export const cellAdapter = cellAdapterFactory(adaptToDetail);
export const cellLinkAdapter = cellLinkAdapterFactory(adaptToDetail);
