import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { withTheme } from '@mui/styles';

// Components
import NavLink from 'components/v1/SideNavigation/NavLink';
import NavSection from 'components/v1/SideNavigation/NavSection';
import NavWrapper from 'components/v1/SideNavigation/NavWrapper';

import { iconComponents } from 'lib/v2/icons';

// paths
// import inboxV2Paths from 'config/paths/inboxV2';
import peoplePaths from 'config/paths/people';
import reportablesPaths from 'config/paths/reportablesV2';
import reportsPaths from 'config/paths/reports';
import ticketPaths from 'config/paths/tickets';
import trainingPaths from 'config/paths/training';
import travelPaths from 'config/paths/travels';
import workflowControlsPaths from 'config/paths/workflowControls';
import visitRequestsPaths from 'config/paths/visitRequests';

// mappers
import { mapPermissionsToProps } from 'mappers/v1/permissions';
import { mapPreferencesToProps } from 'mappers/v1/preferences';

// Utils
import { canSeeAnalyticsSidebar } from 'lib/v1/looker';

export class SideNavigation extends React.Component {
  state = {};

  // TODO: convert this to a functional component after old profile popup is removed so we don't need these mouse event listeners
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside = (event) => {
    if (this.state.show && this.ref && !this.ref.contains(event.target)) {
      this.setState({ show: false });
    }
  };

  setShow = () => {
    this.setState({ show: !this.state.show });
  };

  renderAnalytics = () => {
    const { profile } = this.props;

    return (
      <NavLink
        to={reportsPaths.root}
        icon={iconComponents('analytics')}
        updated={true}
        label="Analytics"
        allowed={canSeeAnalyticsSidebar(profile)}
        featureCallout={profile.role === 'admin'}
        {...this.props}
      />
    );
  };

  renderAdmin = () => {
    const { permissions } = this.props;

    if (permissions.admin.list) {
      return <NavLink to="/admin" icon={iconComponents('admin')} label="Administration" />;
    }
  };

  render() {
    const { profile, permissions, preferences } = this.props;

    const hasNotifyPermissions =
      permissions.reportable.list ||
      permissions.reportable.create ||
      permissions.visits.list ||
      permissions.travels.list ||
      permissions.actionRequests.list;

    const sections = [
      {
        icon: iconComponents('inbox'),
        label: 'Inbox',
        to: '/inbox',
        allowed: true,
      },
      {
        icon: iconComponents('people'),
        label: 'People',
        to: peoplePaths.root,
        allowed: permissions.people.list,
      },
      {
        icon: iconComponents('travel'),
        label: 'Travel',
        to: travelPaths.root,
        allowed: permissions.travels.list,
      },
      {
        icon: iconComponents('visits'),
        label: 'Visits',
        to: visitRequestsPaths.root,
        allowed: permissions.visits.list,
      },
      {
        icon: iconComponents('notify'),
        label: 'Reports',
        to: reportablesPaths.root,
        allowed: hasNotifyPermissions,
      },
      {
        icon: iconComponents('training'),
        label: 'Training',
        to: trainingPaths.root,
        allowed: preferences.trainings,
      },
      {
        icon: iconComponents('tickets'),
        label: 'Tickets',
        to: ticketPaths.root,
        activePaths: [ticketPaths.root],
        allowed: preferences.workflows,
      },
      {
        icon: iconComponents('workflows'),
        label: 'Workflows',
        to: workflowControlsPaths.root,
        activePaths: [workflowControlsPaths.root],
        allowed: permissions.workflows.list,
        featureCallout: profile.role === 'admin',
      },
    ].filter(({ allowed, featureCallout }) => allowed || featureCallout);

    return (
      <NavWrapper>
        <NavSection>
          {sections.map((section, i) => {
            return <NavLink key={i} {...section} />;
          })}
        </NavSection>

        <NavSection>
          {this.renderAnalytics()}
          {this.renderAdmin()}
        </NavSection>
      </NavWrapper>
    );
  }
}

function mapStateToProps(store) {
  const { profile } = store;
  return {
    ...mapPreferencesToProps({ profile }),
    profile,
    id: profile.id,
    ...mapPermissionsToProps({ profile }),
  };
}

export default withRouter(connect(mapStateToProps)(withTheme(SideNavigation)));
