import React from 'react';

// MUI
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import MuiCard from '@mui/material/Card';
import { styled } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

export const StyledCard = styled(MuiCard)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  margin: '0.5rem',
  cursor: 'pointer',
  border: `1px solid ${theme.palette.divider}`,
  padding: '0.5rem 1rem 0.5rem',
  width: '25rem',
  background: theme.palette.background.paper,
  borderRadius: 4,
}));

export const ActionsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingTop: theme.spacing(1),
  '& > *:last-child': {
    marginLeft: 'auto',
  },
  '& .MuiButton-contained': {
    marginBottom: 7,
  },
}));

export const InfoContainer = styled(List)(({ theme }) => ({
  display: 'flex',
  alignItems: 'start',
  paddingTop: 6,
}));

export const InfoText = styled(Typography)(({ theme }) => ({
  fontSize: '.875rem',
  display: 'flex',
  img: {
    marginRight: '0.3rem',
  },
}));

export const IconContainer = styled(ListItemAvatar)(({ theme }) => ({
  minWidth: 'auto',
}));

const Card = ({ title, subtitle, children, icon, onClick, sx }) => {
  return (
    <StyledCard onClick={onClick} sx={sx}>
      <Box>
        <InfoContainer dense component="div">
          <IconContainer>{icon}</IconContainer>
          <ListItemText
            primary={title}
            primaryTypographyProps={{
              variant: 'body1',
            }}
            secondary={subtitle}
            secondaryTypographyProps={{
              variant: 'caption',
            }}
          />
        </InfoContainer>
      </Box>
      <ActionsContainer>{children}</ActionsContainer>
    </StyledCard>
  );
};

export default Card;
