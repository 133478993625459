import { adaptToIso, adaptToDisplay } from 'adapters/v1/dates';
import {
  cellAdapterFactory,
  cellLinkAdapterFactory,
  tagsCellAdapterFactory,
} from 'adapters/v2/factories';
import { adaptJsonApiToDetail, adaptToJsonApiRequest } from 'adapters/v1/json_api';
import { adaptToCalendarDate } from 'adapters/v1/activity';
import {
  adaptSelectToRequest,
  adaptSingleSelectFieldsToRequest,
  adaptTagsToForm,
} from 'adapters/v1';
import { getCustomFieldPropNames } from 'lib/v1/customFields';

import format from 'lib/v1/format';
import moment from 'moment';

const dateFields = ['created_at', 'updated_at'];
export const countryFields = [
  'country',
  'primary_citizenship',
  'secondary_citizenship',
  'passport_country',
];

const singleSelectFields = [...countryFields, 'location', 'manager_id', 'passport_type'];

// don't send all of these random items to the server
const cleanRequestProps = [
  ...dateFields,
  'active_accesses',
  'active_type_accesses',
  'avatar_url',
  'comments',
  'completed_investigations',
  'contracts',
  'documents',
  'errors',
  'id_confirms',
  'latest_id_confirm',
  'latest_risk_check',
  'looker',
  'loginSecurity',
  'permissions',
  'risk_checks',
  'risk_documents',
  'risk_score',
  'riskColor',
  'riskDescription',
  'riskLabel',
  'riskPercentage',
  'tasks',
  'tag_array',
  'tags',
  'tag_names',
  'type_accesses',
  'user_accesses',
];

const riskColor = (value) => {
  // reverse the range so that the colors start green and go red
  const hue = Math.abs(100 - Math.floor(value));
  return `hsl(${hue}, 100%, 50%)`;
};

const riskDescription = {
  'No information':
    'There is no reportable information collected for this employee. Consider sending a reminder about reporting requirements.',
  Low: 'This employee has a relatively low risk profile. You should continue to monitor the situation for changes in behavior.',
  Caution:
    'We have observed this employee may have exhibited behavior that could be considered risky. Consider further investigation.',
  'Needs attention':
    'We have observed this employee has exhibited behavior that requires further investigation as soon as possible.',
  Urgent:
    'This employee has exhibited one or more behaviors that could pose an insider threat risk and requires your immediate attention.',
};

export const adaptToRequest = (formData, writeableAttrs = {}) => {
  const adapted = {
    ...adaptToIso(formData, dateFields),
    ...adaptSingleSelectFieldsToRequest({ formData, singleSelectFields }),
  };

  // this should only be coming in on create calls to help them pre-add users to facilities
  if (adapted.facilityIds) {
    adapted.facilities = adapted.facilityIds.map(({ label }) => label);
    delete adapted.facilityIds;
  } else delete adapted.facilities;

  // Model Level
  const modelCustomFields = getCustomFieldPropNames('people');

  // Instead of grabbing the entire object, just the items that might need sent
  modelCustomFields.forEach((attr) => {
    if (formData[attr] === undefined) return;
    const hasLabel = formData[attr] && formData[attr].label !== undefined;
    adapted[attr] = hasLabel ? formData[attr].label : formData[attr];
  });

  const attributes = {};

  // In my POC the attribute values object has meta about type
  // I want to see how much I can automate based on that.
  Object.keys(writeableAttrs).forEach((val) => {
    const value = adapted[val];

    if (value === undefined) return;

    attributes[val] = value;
  });

  if (formData.date_of_birth) {
    adapted.date_of_birth = moment(adapted.date_of_birth).format().slice(0, 10);
  }

  if (formData.hired_at) {
    adapted.hired_at = moment(adapted.hired_at).format().slice(0, 10);
  }

  if (formData.terminated_at) {
    adapted.terminated_at = moment(adapted.terminated_at).format().slice(0, 10);
  }

  if (formData.role) {
    // Manually adapt `role` so it does not have to be included in `singleSelectFields`
    // Otherwise the `forEach` below would negate this operation
    adapted.role = adaptSelectToRequest(formData.role);
  }

  // Fill adapted single select attributes if missing
  singleSelectFields.forEach((field) => (adapted[field] ||= formData[field]));

  cleanRequestProps.forEach((prop) => delete adapted[prop]);

  return adaptToJsonApiRequest({
    attributes: !attributes.length ? adapted : attributes,
    type: 'user',
  });
};

const roleName = ({ role }) => (role && role.name ? role.name : role);

export const adaptToAssignableDetail = (resource) => {
  if (!resource) return {};

  const adapted = adaptJsonApiToDetail({ ...resource });

  adapted.role_name = roleName(adapted);

  return adapted;
};

export const adaptToDetail = (resource) => {
  if (!resource) return {};

  const adapted = adaptJsonApiToDetail(resource);

  const { current_sign_in_at } = adapted;

  adapted.current_sign_in_at = current_sign_in_at
    ? adaptToCalendarDate(current_sign_in_at)
    : 'Never';

  const { risk_score, tag_names } = adapted;

  // This is pretty important, we need to persist role ID/ame for the detail view and overwriting this in the table view breaks things.
  adapted.role_name = roleName(adapted);
  adapted.tag_array = tag_names && Array.isArray(tag_names) ? tag_names : [];

  if (risk_score !== undefined) {
    adapted.riskLabel = format.risk_label(risk_score);
    adapted.riskDescription = riskDescription[adapted.riskLabel];
    adapted.riskPercentage = Math.min(risk_score * 10, 100);
    adapted.riskColor = riskColor(adapted.riskPercentage);
  }

  const typeAccesses = (adapted.active_accesses || [])
    .map(({ display_name }) => display_name)
    .filter((d) => !!d)
    .sort((a, b) => a.localeCompare(b))
    .join(', ');

  adapted.active_type_accesses =
    adapted.active_accesses && adapted.active_accesses.length ? typeAccesses : 'None';

  function convertUTCDateToLocalDate(input) {
    const date = new Date(input);
    return new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
  }

  if (adapted.date_of_birth) {
    adapted.date_of_birth = convertUTCDateToLocalDate(adapted.date_of_birth);
    adapted.formattedDob = format.date(adapted.date_of_birth);
  }

  if (adapted.hired_at) {
    adapted.hired_at = convertUTCDateToLocalDate(adapted.hired_at);
  }

  if (adapted.terminated_at) {
    adapted.terminated_at = convertUTCDateToLocalDate(adapted.terminated_at);
  }

  adapted.loginSecurity = !!adapted['otp_enabled?'] ? '2FA' : 'Password';
  if (adapted.sso_required && !adapted.sso_disable) {
    adapted.loginSecurity = 'SSO';
  }

  return adaptToDisplay(adaptTagsToForm(adapted), dateFields);
};

// simplified adapter for assigning facilities without all other profile props
export const adaptFacilityIdsToRequest = ({ facilityIds }) => {
  const facilities = facilityIds ? facilityIds.map(({ label }) => label) : [];

  return adaptToJsonApiRequest({
    attributes: {
      facilities,
    },
    type: 'user',
  });
};

// simplified adapter for assigning contracts without all other profile props
export const adaptContractIdsToRequest = ({ contractIds }) => {
  const contracts = contractIds ? contractIds.map(({ label }) => label) : [];

  return adaptToJsonApiRequest({
    attributes: {
      contracts,
    },
    type: 'user',
  });
};

// simplified adapter for assign facility dialog under profile
export const adaptToFacilityForm = (resource) => {
  if (!resource) return {};

  const adapted = adaptJsonApiToDetail(resource);
  if (adapted.facilities) {
    adapted.facilityIds = adapted.facilities.map(({ id, legal_name }) => ({
      label: id,
      title: legal_name,
    }));
  }

  return {
    facilityIds: adapted.facilityIds || [],
  };
};

// simplified adapter for assign contract dialog under profile
export const adaptToContractForm = (resource) => {
  if (!resource) return {};

  const adapted = adaptJsonApiToDetail(resource);
  if (adapted.contracts) {
    adapted.contractIds = adapted.contracts.map(({ id, contract_name }) => ({
      label: id,
      title: contract_name,
    }));
  }

  return {
    contractIds: adapted.contractIds || [],
  };
};

export const adaptToForm = (resource) => {
  if (!resource) return {};
  const updated = adaptToDisplay(adaptJsonApiToDetail(resource), dateFields);

  const { location, manager, passport_type, role } = updated;

  updated.role = {
    label: role && role.id ? role.id : role,
    title: role && role.name ? role.name : role,
  };

  if (manager) {
    updated.manager_id = { title: manager.name, label: parseInt(manager.id) };
  }

  if (location) updated.location = { label: location, title: location };
  if (passport_type) updated.passport_type = { label: passport_type, title: passport_type };

  return { ...adaptTagsToForm(updated) };
};

export const adaptToBulkTrainingRequest = (formData = {}) => {
  const { training_id, notify } = formData;

  const training_ids = training_id.map((training) => (training = parseInt(training.label)));
  const adapted = {
    training_ids,
    send_notification_email: notify,
    ...adaptToIso(formData, ['due_at']),
  };

  return adapted;
};

export const cellAdapter = cellAdapterFactory(adaptToDetail);
export const cellLinkAdapter = cellLinkAdapterFactory(adaptToDetail);
export const tagsCellAdapter = tagsCellAdapterFactory(adaptToDetail);
