import React from 'react';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import store from 'store';

import { hasWorkflows } from 'permissions/v1/preferences';

// PageElements
import { useQueryString } from 'components/v2/PageElements';
import Route from 'components/v2/PageElements/Routes';

// Redux State Mappers
import { mapPermissionsToProps } from 'mappers/v1/permissions';

// Paths
import paths from 'config/paths/email';
import actionRequestsPaths from 'config/paths/actionRequests';
import assetControlsPaths from 'config/paths/assetControls';
import badgesPaths from 'config/paths/badgeControls';
import contactsPaths from 'config/paths/contacts';
import containersPaths from 'config/paths/containerControls';
import contractsPaths from 'config/paths/contracts';
import controlsPaths from 'config/paths/controls';
import peoplePaths from 'config/paths/people';
import requirementsPaths from 'config/paths/requirements';
import visitRequestsPaths from 'config/paths/visitRequests';
import {
  trainingCoursePaths,
  trainingAssignmentPaths,
  employeeTrainingAssignmentPaths,
} from 'config/paths/training';
import travelPaths from 'config/paths/travels';
import requestTemplatePaths from 'config/paths/requestTemplates';
import reportablePaths from 'config/paths/reportablesV2';
import ticketPaths from 'config/paths/tickets';
import typeAccessPaths from 'config/paths/accesses';
import typeEligibilityPaths from 'config/paths/eligibilities';
import typePolygraphPaths from 'config/paths/polygraphs';
import taskPaths from 'config/paths/tasks';
import destructionEventPaths from 'config/paths/destructionEvents';
import transferEventPaths from 'config/paths/transferEvents';
import workflowPaths from 'config/paths/workflowControls';

const DEFAULT_DEST = '/inbox';

// currently these are only used for admin modules
export const pathsMapper = () => {
  const state = store.getState() || {};
  const { profile } = state;
  const workflowsContext = hasWorkflows(profile);

  return {
    admin: {
      certificate: assetControlsPaths,
      action_request: workflowsContext ? ticketPaths : actionRequestsPaths,
      asset: assetControlsPaths,
      assignment: trainingAssignmentPaths,
      badge: badgesPaths,
      card: workflowsContext ? ticketPaths : actionRequestsPaths,
      card_template: workflowsContext ? workflowPaths : requestTemplatePaths,
      container: containersPaths,
      contact: contactsPaths,
      contract: contractsPaths,
      control: controlsPaths,
      destruction_event: destructionEventPaths,
      evidence: controlsPaths,
      incident: reportablePaths,
      reportable: reportablePaths,
      request_template: workflowsContext ? workflowPaths : requestTemplatePaths,
      requirement: requirementsPaths,
      task: taskPaths,
      ticket: workflowsContext ? ticketPaths : actionRequestsPaths,
      training: trainingCoursePaths,
      transfer_event: transferEventPaths,
      travel: travelPaths,
      type_access: typeAccessPaths,
      type_eligibility: typeEligibilityPaths,
      type_polygraph: typePolygraphPaths,
      user: peoplePaths,
      visitor: visitRequestsPaths,
      visit_request: visitRequestsPaths,
      workflow: workflowsContext ? workflowPaths : requestTemplatePaths,
    },
    employee: {
      assignment: employeeTrainingAssignmentPaths,
      card: workflowsContext ? ticketPaths : actionRequestsPaths,
      task: taskPaths,
      ticket: workflowsContext ? ticketPaths : actionRequestsPaths,
      travel: travelPaths,
      visit_request: visitRequestsPaths,
    },
  };
};

// if we wanted to add some kind of click tracking to email, we could add logic for that in here
export const DetailPath = ({ match = {}, location } = {}) => {
  const { params } = match;
  const { uid } = useQueryString({ history: { location } });
  const context = uid ? 'employee' : 'admin';

  const effectivePaths = pathsMapper()[context][params.module];

  if (!params.id) return DEFAULT_DEST;

  if (effectivePaths && effectivePaths.resource) {
    return effectivePaths.resource.detail(params.id, uid);
  }

  return DEFAULT_DEST;
};

const DetailRedirecter = (props) => {
  return <Redirect to={DetailPath(props)} />;
};

const ListRedirecter = ({ match = {} } = {}) => {
  const { params } = match;
  const effectivePaths = pathsMapper()[params.module];
  const destination = effectivePaths ? effectivePaths.list : DEFAULT_DEST;
  return <Redirect to={destination} />;
};

export const Routes = ({ paths, permissions, ...rest }) => {
  return (
    <Switch>
      <Route permission={true} path={paths.detail} component={DetailRedirecter} />
      <Route permission={true} path={paths.list} component={ListRedirecter} />
    </Switch>
  );
};

export default connect(mapPermissionsToProps, null)((props) => <Routes paths={paths} {...props} />);
