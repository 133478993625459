import { BuildDateRangeFilter } from 'components/v2/Filters/DateRange';
import MultiSelect, {
  ByArchived,
  ByFacility,
  ByTag,
  ByUser,
} from 'components/v2/Filters/MultiSelect';
import MultiSelectFilter from 'components/v2/Filters/MultiSelect';
import selectable from 'adapters/v1/selectable';

import { adaptToSelectableMetaAttributes } from 'adapters/v1/meta';

// import { BuildSearchFilter } from '../Filters/Search';
import { adaptCustomFieldsToLabels } from 'adapters/v1';
import { mapCustomFilterType } from '../Filters/BuildCustomFilter';

export const ByContractType = ({
  fieldName = 'contract_type',
  displayName = 'Contract Type',
  updateQuery,
}) => {
  const mapStateToSelect = ({ contractsV2: { meta } }) => {
    const { contract_type } = adaptToSelectableMetaAttributes(meta);

    return {
      options: contract_type || [],
    };
  };

  const multiSelectProps = {
    name: fieldName,
    displayName,
    updateQuery,
    mapStateToSelect,
  };

  return {
    Component: (props) => <MultiSelect {...multiSelectProps} {...props} />,
    label: displayName,
    value: fieldName,
    icon: 'calendar',
  };
};

export const ByStatus = ({ fieldName = 'status', displayName = 'Status', updateQuery }) => {
  const mapStateToSelect = ({ contractsV2: { meta } }) => {
    const { status } = adaptToSelectableMetaAttributes(meta);

    return {
      options: status || [],
    };
  };

  const multiSelectProps = {
    name: fieldName,
    displayName,
    updateQuery,
    mapStateToSelect,
  };

  return {
    Component: (props) => <MultiSelect {...multiSelectProps} {...props} />,
    label: displayName,
    value: fieldName,
    icon: 'calendar',
  };
};

const dateRangeFilters = [
  { displayName: 'Start Date', name: 'start_date' },
  { displayName: 'End Date', name: 'end_date' },
];

export const ByContractName = ({ updateQuery, fieldName = 'contract_id' }) => {
  const name = fieldName;
  const displayName = 'Contracts';

  const mapStateToSelect = ({ contractsV2 }) => {
    return {
      options: selectable(contractsV2, { value: 'contract_name', key: 'dropdown' }),
      isLoading: false,
      disabled: false,
    };
  };

  const multiSelectProps = {
    name,
    displayName,
    updateQuery,
    mapStateToSelect,
  };

  return {
    Component: (props) => <MultiSelectFilter {...multiSelectProps} {...props} />,
    label: displayName,
    value: name,
    icon: 'calendar',
  };
};

const ByProgramManager = ({ updateQuery }) => {
  return ByUser({
    fieldName: 'program_manager_id',
    displayName: 'Program Manager',
    updateQuery,
  });
};

const filters = [
  ...dateRangeFilters.map((obj) => BuildDateRangeFilter(obj)),
  ByProgramManager,
  ByArchived,
  ByStatus,
  ByFacility,
  ByContractType,
  ByTag,
];

const ContractFilters = ({ custom_fields = [], schema }) => {
  const new_filters = [...filters];

  const customFieldLabels =
    adaptCustomFieldsToLabels({ fields: custom_fields, model: 'contract' }) || [];

  customFieldLabels?.forEach((field) => new_filters.push(mapCustomFilterType({ field, schema })));

  return new_filters;
};

export default ContractFilters;
