import { adaptToIso, adaptToDisplay } from 'adapters/v1/dates';
import { adaptJsonApiToDetail, adaptToJsonApiRequest } from 'adapters/v1/json_api';
import { adaptSelectToRequest } from 'adapters/v1/index';

import { getUserDetails } from 'lib/v1/users';

const dateFields = ['due_at', 'approved_at', 'declined_at', 'created_at', 'updated_at'];

const singleSelectFields = ['user_id'];

export const adaptToRequest = (formData) => {
  if (!formData) return {};
  const adapted = { ...formData };

  singleSelectFields.forEach((key) => {
    adapted[key] = adaptSelectToRequest(adapted ? adapted[key] : {});
  });

  if (adapted.user_id === null) delete adapted.user_id;

  return adaptToJsonApiRequest({
    attributes: adaptToIso(adapted, dateFields),
    type: 'approver',
  });
};

export const adaptToDetail = (resource) => {
  if (!resource) return {};
  const adapted = adaptJsonApiToDetail(resource);

  if (adapted.user_id) {
    adapted.user = getUserDetails(adapted.user_id) || {};
    adapted.user_id = { label: adapted.user_id, title: adapted.user.name };
  }

  // ? Do we need 'approved'
  adapted.approved = resource.approved !== null;

  return adaptToDisplay(adapted, dateFields);
};
