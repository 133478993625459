import React from 'react';

import SortableList from './SortableList';

const SortableComponent = ({
  addLabel,
  allowEmpty = true,
  disabled,
  itemLabel = 'item',
  onChange,
  textFieldProps = {},
  value = [],
}) => {
  // Add id to each item for sorting
  const sortedItems = value.map((item, i) => ({ ...item, id: i }));
  const [items, setItems] = React.useState(sortedItems);
  // Reinitialize items from value
  React.useEffect(() => setItems(sortedItems), [value]); // eslint-disable-line
  const newId = items.length;

  // ? Should we split the sort from the field edit
  const onItemEdit = (id, newValue) => {
    let tempItems = [...items];
    const changedIndex = tempItems.findIndex((item) => item.id === id);
    tempItems[changedIndex] = {
      ...tempItems[changedIndex],
      label: newValue,
    };

    // Filter out empty items to display error
    onChange(tempItems.filter(({ label }) => label));
  };

  const onItemDelete = (id, title) => {
    if (
      String(title).includes('new') ||
      window.confirm(`Are you sure you want to remove this forever?`)
    ) {
      onChange([...items.filter((item) => item.id !== id)]);
    }
  };

  const onItemAdd = () => {
    setItems((prev) => [...prev, { id: newId, label: '', title: `new-${newId}` }]);
  };

  const listProps = {
    addLabel,
    allowEmpty,
    disabled,
    items,
    itemLabel,
    onChange,
    onItemAdd,
    onItemEdit,
    onItemDelete,
    textFieldProps,
  };

  return <SortableList {...listProps} />;
};

export default SortableComponent;
