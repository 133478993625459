import React, { memo } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

// ? User TswAutocomplete instead of Autocomplete
// import { TswAutocomplete } from '../FormElements/Autocomplete';

const noop = () => {};

const Select = ({
  autoFocus = true,
  disablePortal = false,
  disabled = false,
  label,
  name = '',
  onMenuClose = noop,
  placeholder = '',
  callback = noop,
  multi = false,
  options = [],
  ref = null,
  ...rest
}) => {
  const [value, setValue] = React.useState([]);

  const handleOnChange = (event, value) => {
    setValue(value);
    if (callback) callback(value);
    onMenuClose();
  };

  const textFieldProps = {
    autoFocus,
    disabled,
    label,
    name,
    placeholder,
  };

  const selectProps = {
    callback,
    disablePortal,
    disabled,
    id: `${name}-select`,
    label,
    multiple: multi,
    name,
    onBlur: onMenuClose,
    options,
    placeholder,
    ref,
    renderInput: (params) => <TextField {...params} {...textFieldProps} />,
    value,
    ...rest,
    onChange: handleOnChange,
  };

  return <Autocomplete {...selectProps} />;
};

export default memo(Select);
