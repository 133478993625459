import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

// mappers
import { mapPermissionsToProps } from 'mappers/v1/permissions';
import { mapPreferencesToProps } from 'mappers/v1/preferences';
import { mapTableToPropsFactory, mergePropsToTable } from 'mappers/v1/table';

// actions
import actions from 'store/v2/cards/actions';
import contactsActions from 'store/v2/contacts/actions';
import paths from 'config/paths/tickets';

// adapters
import { adaptToDetail as adaptWorkflowToDetail } from 'adapters/v1/cardTemplates/formData';
import { adaptToDetail as adaptCardToDetail } from 'adapters/v1/cards/formData';

export const stateBuilder = () => {
  const mapDataToTable = mapTableToPropsFactory('cards');

  const mapResourceToProps = ({ cards }, ownProps) => {
    const { match, resource } = ownProps;
    const resourceId = match && match.params ? Number(match.params['id']) : resource && resource.id;
    return { resourceId, resource: adaptCardToDetail(cards.data[resourceId]) };
  };

  const mapStateToProps = ({ profile, people, cardTemplates = {}, cards = {} }) => {
    const { activity, loading, schema } = cards;

    const tickets = Object.values(cards.data).map(adaptCardToDetail);
    const workflows = Object.values(cardTemplates.dropdown).map(adaptWorkflowToDetail);

    return {
      activity: Object.values(activity || {}),
      users: people.dropdown,
      loading: loading,
      tickets,
      workflows,
      profile,
      schema,
      ...mapPermissionsToProps({ profile }),
      ...mapPreferencesToProps({ profile }),
    };
  };

  const listInclude = { include: 'approvers,members,lanes,card_template' };
  const include = {
    include:
      'approvers,attached_digital_forms,attachments,card_template,comments,created_by,documents,instance_custom_fields,lane,lanes,lane_transitions,members,reviewers,submitter,tasks',
  };

  const mapDispatchToProps = {
    ...actions,
    list: (queryParams) => actions.list({ ...queryParams, ...listInclude }),
    show: (id) => actions.show(id, { ...include }),
    create: (payload) => actions.create({ ...payload, ...include }),
    update: (id, payload) => actions.update(id, { ...payload, ...include }),
    destroyContact: contactsActions.destroy,
  };

  const withPaths = (Component) => (props) => <Component paths={paths} {...props} />;

  return {
    withFeatureState: (Component) => {
      return connect(mapStateToProps, mapDispatchToProps)(withRouter(withPaths(Component)));
    },
    withResourceState: (Component) => {
      return withRouter(
        connect(
          (store, ownProps) => ({
            ...mapStateToProps(store),
            ...mapDataToTable(store),
            ...mapResourceToProps(store, ownProps),
            ...mapPreferencesToProps(store),
          }),
          mapDispatchToProps,
          mergePropsToTable
        )(withPaths(Component))
      );
    },
  };
};

export const { withFeatureState, withResourceState, withTableState } = stateBuilder();
