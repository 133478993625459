import React, { useState } from 'react';

// Components
import FilterSelect from 'components/v2/Filters/Select';
import Button from 'components/v1/Button';

import { styled } from '@mui/material/styles';

import 'components/v1/Filters/styles.css';

const StyledFilters = styled('div')({
  alignItems: 'center',
  display: 'flex',
  minHeight: '40px',
});

const StyledFiltersMenu = styled('div')({
  display: 'inline-block',
  maxWidth: '300px',
  position: 'relative',
});

const StyledFilterSelect = styled(FilterSelect)(({ theme }) => ({
  background: 'white',
  color: '#333',
  fontSize: ' 14px',
  left: '0',
  position: 'absolute !important',
  top: '50px',
  width: '18em',
  zIndex: '50',
}));

const createAvailable = (filterOptions) =>
  filterOptions.reduce((acc, filter) => {
    acc[filter.value] = filter;
    return acc;
  }, {});

// interface Filters {
//   filterOptions: any[];
//   CustomButton?: React.FC;
// }

const Filters = ({ filterOptions = [], CustomButton }) => {
  const [addFilter, setAddFilter] = useState(false);
  const [active, setActive] = useState(null);

  const onSelectFilter = ({ value }) => setActive(value);
  const onCancelFilter = () => setAddFilter(false);

  const onCancel = (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }

    setActive(null);
  };

  const available = createAvailable(filterOptions);
  const form = available[active];
  const formProps = { onClose: onCancel };

  return (
    <StyledFilters>
      <StyledFiltersMenu>
        {!CustomButton && (
          <Button
            data-filter-button="true"
            css="padding-left: 0;"
            color="twilight"
            onClick={(e) => setAddFilter(true)}
            icon="PlusCircle"
            text="Add a Filter"
            transparent
          />
        )}

        {CustomButton && <CustomButton onClick={(e) => setAddFilter(true)} />}

        {addFilter && (
          <StyledFilterSelect
            autoFocus={true}
            callback={onSelectFilter}
            label="Search filter"
            multi={false}
            name="filter"
            onChange={onSelectFilter}
            onClose={onCancelFilter}
            openMenuOnFocus={true}
            options={filterOptions}
            placeholder="Search filter"
          />
        )}
        {form && <form.Component {...formProps} />}
      </StyledFiltersMenu>
    </StyledFilters>
  );
};

export default Filters;
