import { FEED_ACTIONS } from './constants';

const initialState = {
  feed: [],
  newsFeed: [
    {
      date: '2019-09-12',
      link: 'https://www.threatswitch.com/threatswitchblog/advanced-reports',
      title: 'Introducing Advanced Reports and Analytics',
      description:
        'When a conversation happens over and over with different customers, it is a good indication of its importance. For our team, that repeated conversation has been about reports, and it has been important to everyone from assistant FSOs to C-suite executives. The bottom line is that easy access to data is the cornerstone of how effective businesses run their security program.',
      image: 'https://www.threatswitch.com/hubfs/undraw_data_xmfy.png',
    },
    {
      date: '2019-08-02',
      link: 'https://www.threatswitch.com/threatswitchblog/employee-insights',
      title: 'Employee Insights',
      description:
        'We believe that engaging employees in the industrial security program is an essential part of a healthy and safe reporting environment. But beyond the critical components of insider threat, employees need to be able to quickly know simple things such as the status of an eligibility investigation.',
      image: 'https://www.threatswitch.com/hubfs/undraw_profile_6l1l.png',
    },
    {
      date: '2019-07-31',
      link: 'https://www.threatswitch.com/threatswitchblog/tags',
      title: 'Organize People With Tags',
      description:
        'Do you have a list of KMPs you would like to keep organized? What about a group of employees who require a follow-up? Or maybe you need a list of people who are part time? The bottom line is security managers need lots of lists of people and need to be able to quickly view and export those personnel.',
      image: 'https://www.threatswitch.com/hubfs/undraw_file_searching_duff.png',
    },
    {
      date: '2019-05-10',
      link: 'https://www.threatswitch.com/threatswitchblog/assignments',
      title: 'Improve your workflows with assigned reviewers',
      description:
        'An ongoing challenge for organizations with multiple security managers running their industrial security program is keeping each person informed with their latest tasks and accountable items. Compliance now helps organize these tasks better by giving you the ability to assign items to individual security managers. Items that can be assigned include reportable information, foreign travel, and outgoing visit requests.',
      image: 'https://www.threatswitch.com/hubfs/undraw_task_31wc.png',
    },
    {
      date: '2019-03-29',
      link: 'https://www.threatswitch.com/threatswitchblog/foreign-travel-update',
      title: "You've been upgraded to first-class foreign travel",
      description:
        'With our latest release, Compliance has improved the process of gathering information from employees regarding Foreign Travel. These updates allow for employees to easily submit all important pre & post-travel information to their Security Managers. Meanwhile, Security Managers can easily process these reports and take the appropriate actions and next steps.',
      image: 'https://www.threatswitch.com/hubfs/undraw_journey_lwlj.png',
    },
    {
      date: '2019-02-21',
      link: 'https://www.threatswitch.com/threatswitchblog/employee-visitor-access-request',
      title: 'Employee-Initiated VARs',
      description:
        'Visitor Access Requests are a regular headache for security managers. It is a cumbersome process to collect all the required information from employees (often over email 😭) then file that to DISS or to a recipient directly.  As always, Compliance is here to help.',
      image: 'https://www.threatswitch.com/hs-fs/hubfs/reception.png?width=360&name=reception.png',
    },
    {
      date: '2019-01-18',
      link: 'https://www.threatswitch.com/threatswitchblog/employee-inbox',
      title: 'Faster task completion with the Employee Inbox',
      description:
        'When employee users log into Compliance it is critical that they are able to quickly identify action items such as incomplete trainings and form requests. With the new Employee Inbox, users automatically see their most pressing tasks, in a simple inbox-style to-do list.',
      image: 'https://www.threatswitch.com/hubfs/task.png',
    },
  ],
};

const feed = (state = initialState, { payload, type }) => {
  switch (type) {
    case FEED_ACTIONS.FEED_FETCHED:
      return {
        ...state,
        feed: [...payload.feed],
        totalPages: payload.total_pages,
      };
    case FEED_ACTIONS.NEWS_FEED_FETCHED:
      return {
        ...state,
        newsFeed: payload,
      };
    default:
      return state;
  }
};

export default feed;
