import React from 'react';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import Stack from '@mui/material/Stack';
import AvatarGroup from '@mui/material/AvatarGroup';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { CardActions, Typography } from '@mui/material';
import Icon from 'components/v2/PageElements/Icon';
import Paper from '@mui/material/Paper';
import { hexToHsla } from 'lib/v2/colors';

const ClickableCard = styled(Card)(({ theme }) => ({
  cursor: 'pointer',
  border: '1px solid rgba(0, 0, 0, 0.1)',
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  fontWeight: 500,
  padding: 0,
  lineHeight: 1.3,
  '&.alert': {
    color: theme.palette.alert.main,
  },
}));

const Value = styled(Typography)`
  font-size: 0.875rem;
  font-weight: 400;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const StyledHeader = styled(CardHeader)`
  & .MuiCardHeader-title {
    color: #757575;
    font-size: 1rem;
    line-height: 1.3;
  }
  & .MuiCardHeader-subheader {
    color: #2b2e42;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.3;
  }
`;

const Item = styled(Paper)`
  width: 100%;
  box-shadow: none;
  border-radius: 0;
`;

const StyledActions = styled(CardActions)`
  justify-content: space-between;
  padding: 0 16px 16px;
`;

const StyledAvatarGroup = styled(AvatarGroup)`
  & .MuiAvatarGroup-avatar {
    width: 30px;
    height: 30px;
    font-size: 1rem;
  }
`;

const IconContainer = styled(ListItemAvatar)(({ theme }) => {
  const iconColor = theme.palette.tickets.main;
  const backgroundColor = hexToHsla(iconColor, '0.1');

  return {
    color: iconColor,
    backgroundColor,
    display: 'flex',
    padding: 4,
    marginRight: 0,
    minWidth: 'auto',
    borderRadius: 3,
  };
});

export { Subtitle, Value, Item, StyledAvatarGroup, IconContainer };

const renderIcon = (icon) => {
  if (typeof icon === 'string') {
    return <Icon icon={icon} />;
  }
  return icon;
};

const GenericCard = ({
  icon,
  title,
  subtitle,
  onCardClick,
  children,
  action,
  dateText = 'Last updated',
  dateValue,
  ...props
}) => (
  <ClickableCard onClick={onCardClick} {...props}>
    <StyledHeader avatar={renderIcon(icon)} title={title} subheader={subtitle} action={action} />
    <Stack direction="row" spacing={2} sx={{ padding: '0 16px 16px' }}>
      {children}
    </Stack>
    <StyledActions>
      {(dateText || dateValue) && (
        <Typography variant="body2" color="textSecondary">
          {dateText} {dateValue}
        </Typography>
      )}
      {props.button}
    </StyledActions>
  </ClickableCard>
);

export default GenericCard;
