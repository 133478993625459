import React from 'react';
import { Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { withRouter, matchPath } from 'react-router-dom';
import ProfileContext from 'components/v2/Profile/Context';

// Actions
import actions from 'store/v2/people/actions';

// Config
import paths from 'config/paths/people';
import { buildPaths, sharedRoots } from 'config/paths';

// PageElements
import ReduxManager from 'components/v2/PageElements/ReduxManager';
import Route, { Switch, useRoutingFilters } from 'components/v2/PageElements/Routes';

// Redux State Mappers
import { mapPermissionsToProps } from 'mappers/v1/permissions';
import { mapPreferencesToProps } from 'mappers/v1/preferences';

import { dateRangeLabel } from 'components/v2/Filters/DateRange';

import DigitalFormRequestsRoutes from 'components/v2/DigitalForms/Requests/Routes';

// Legacy Components - Better to have legacy crap functionality and move forward
const DissImport = lazy(() => import('views/users/child-routes/Import'));

// Components
const Detail = lazy(() => import('./Detail'));
const Index = lazy(() => import('./index'));
const ResourceLayout = lazy(() => import('./ResourceLayout'));

// Routes
const AccessEligibility = lazy(() => import('components/v2/Profile/AccessesEligibilities'));
const ActionRequestsRoutes = lazy(() => import('components/v2/ActionRequests/Routes'));
const AssetsRoutes = lazy(() => import('components/v2/AssetControls/Routes'));
const BadgesRoutes = lazy(() => import('components/v2/BadgeControls/Routes'));
const ContractsRoutes = lazy(() => import('components/v2/Contracts/Routes'));
// const DigitalFormRequestsRoutes = lazy(() => import('components/v2/DigitalForms/Requests/Routes'));
const DocumentsRoutes = lazy(() => import('components/v2/Documents/Routes'));
const FacilitiesRoutes = lazy(() => import('components/v2/Facilities/Routes'));
const ReportablesRoutes = lazy(() => import('components/v2/Reportables/Routes'));
const RiskProfileRoutes = lazy(() => import('components/v2/RiskProfile/Routes'));
const TasksRoutes = lazy(() => import('components/v2/Tasks/Routes'));
const TicketsRoutes = lazy(() => import('components/v2/Tickets/Routes'));
const TrainingAssignmentsRoutes = lazy(() => import('components/v2/Training/Assignments/Routes'));
const TagsRoutes = lazy(() => import('components/v2/Tags/Routes'));
const TravelsRoutes = lazy(() => import('components/v2/Travels/Routes'));
const UserAccessRoutes = lazy(() => import('components/v2/Profile/Accesses/Routes'));
const UserEligibilityRoutes = lazy(() => import('components/v2/Profile/Eligibilities/Routes'));
const UserPolygraphRoutes = lazy(() => import('components/v2/Profile/Polygraphs/Routes'));
const VisitRoutes = lazy(() => import('components/v2/VisitRequests/Routes'));

export const PeopleReduxManager = ({ children }) => {
  return (
    <ReduxManager
      data={[
        'contractsDropdown',
        'countries',
        'customRolesDropdown',
        'digitalFormTemplatesDropdown',
        'facilitiesDropdown',
        'peopleDropdown',
        'tagNamesDropdown',
        'trainingsDropdown',
        'typesDropdown',
        'usersSchema',
      ]}
    >
      {children}
    </ReduxManager>
  );
};

export const Routes = ({
  history,
  location,
  match,
  paths,
  permissions,
  preferences,
  profile,
  updateQuery,
}) => {
  useRoutingFilters({ location, updateQuery });
  const { root } = paths;
  const { params, url, path } = matchPath(location.pathname, paths.resource.root) || {};

  const userId = params && Number(params.id);
  const userResourceUrls = buildPaths(url, sharedRoots);
  const userResourcePaths = buildPaths(path, sharedRoots);

  const context = {
    userId,
    isSelf: Number(profile.id) === userId,
  };

  const values =
    location &&
    location.state &&
    location.state.activeFilters &&
    location.state.activeFilters.reinvestigation_at;

  // FIX HOOK
  React.useEffect(() => {
    if (!values || !updateQuery) return;

    updateQuery({
      filters: {
        reinvestigation_at: {
          values,
          ...dateRangeLabel({ displayName: 'Eligibility Reinvestigation Due', range: values }),
        },
      },
    });
  }, []); // eslint-disable-line

  return (
    <ProfileContext.Provider value={context}>
      <PeopleReduxManager>
        <Switch>
          <Route permission={permissions.people.list} exact path={paths.root}>
            <Index paths={paths} />
          </Route>

          <Route permission={permissions.people.create} exact path={paths.dissImport}>
            <DissImport />
          </Route>

          <Suspense fallback={<></>}>
            <Switch>
              <Route permission={true} path={paths.resource.root}>
                <ResourceLayout paths={{ ...userResourceUrls, root }}>
                  <Switch>
                    <Route exact path={paths.resource.detail(':id')} permission={true}>
                      <Detail paths={paths} />
                    </Route>

                    {/* ActionRequests */}
                    <Route path={userResourcePaths['action-requests'].root} permission={true}>
                      <ActionRequestsRoutes {...{ paths: userResourceUrls['action-requests'] }} />
                    </Route>

                    {/* Accesses v2 */}
                    <Route path={userResourcePaths.accesses.root} permission={true}>
                      <UserAccessRoutes
                        {...{
                          paths: userResourceUrls.accesses,
                          accessEligibilityPaths: userResourceUrls['accesses-eligibilities'],
                        }}
                      />
                    </Route>

                    {/* Accesses and Eligibilities */}
                    <Route
                      path={userResourcePaths['accesses-eligibilities'].root}
                      permission={true}
                    >
                      <AccessEligibility
                        {...{
                          userAccessPaths: userResourceUrls.accesses,
                          userEligibilityPaths: userResourceUrls.eligibility,
                        }}
                      />
                    </Route>

                    {/* Assets */}
                    <Route path={userResourcePaths.assets.root} permission={true}>
                      <AssetsRoutes {...{ paths: userResourceUrls.assets }} />
                    </Route>

                    {/* Badges */}
                    <Route path={userResourcePaths.badges.root} permission={true}>
                      <BadgesRoutes
                        {...{ paths: userResourceUrls.badges, location, match, userId }}
                      />
                    </Route>

                    {/* Contracts */}
                    <Route path={userResourcePaths.contracts.root} permission={true}>
                      <ContractsRoutes
                        {...{ paths: userResourceUrls.contracts, location, match, userId }}
                      />
                    </Route>

                    {/* DigitalFormRequests */}
                    <Route path={userResourcePaths.forms.root} permission={true}>
                      <DigitalFormRequestsRoutes {...{ paths: userResourceUrls.forms }} />
                    </Route>

                    {/* Documents */}
                    <Route path={userResourcePaths.files.root} permission={true}>
                      <DocumentsRoutes {...{ paths: userResourceUrls.files }} />
                    </Route>

                    {/* Eligibilities v2 */}
                    <Route path={userResourcePaths.eligibility.root} permission={true}>
                      <UserEligibilityRoutes
                        {...{
                          paths: userResourceUrls.eligibility,
                          accessEligibilityPaths: userResourceUrls['accesses-eligibilities'],
                        }}
                      />
                    </Route>

                    {/* Facilities */}
                    <Route path={userResourcePaths.facilities.root} permission={true}>
                      <FacilitiesRoutes {...{ paths: userResourceUrls.facilities }} />
                    </Route>

                    {/* Polygraphs v2 */}
                    <Route path={userResourcePaths.polygraphsV2.root} permission={true}>
                      <UserPolygraphRoutes
                        {...{ paths: userResourceUrls.polygraphsV2, location, match, userId }}
                      />
                    </Route>

                    {/* Risk Profile */}
                    <Route
                      path={userResourcePaths.risk.root}
                      permission={permissions.people.update}
                    >
                      <RiskProfileRoutes {...{ paths: userResourceUrls.risk }} />
                    </Route>

                    {/* ReportableInfo V2 */}
                    <Route path={userResourcePaths['reportable-incidents'].root} permission={true}>
                      <ReportablesRoutes
                        {...{ paths: userResourceUrls['reportable-incidents'], match, userId }}
                      />
                    </Route>

                    {/* Tags */}
                    <Route
                      path={userResourcePaths.tags.root}
                      permission={permissions.people.update}
                    >
                      <TagsRoutes {...{ paths: userResourceUrls.tags, location, match, userId }} />
                    </Route>

                    {/* Tasks */}
                    <Route path={userResourcePaths.tasks.root} permission={true}>
                      <TasksRoutes {...{ paths: userResourceUrls.tasks }} />
                    </Route>

                    {/* Tickets */}
                    <Route path={userResourcePaths.tickets.root} permission={true}>
                      <TicketsRoutes {...{ paths: userResourceUrls.tickets }} />
                    </Route>

                    {/* TrainingAssignments */}
                    <Route path={userResourcePaths.training.root} permission={true}>
                      <TrainingAssignmentsRoutes {...{ paths: userResourceUrls.training }} />
                    </Route>

                    {/* Travels */}
                    <Route path={userResourcePaths.travels.root} permission={true}>
                      <TravelsRoutes {...{ paths: userResourceUrls.travels }} />
                    </Route>

                    {/* Visits */}
                    <Route path={userResourcePaths.visits.root} permission={true}>
                      <VisitRoutes {...{ paths: userResourceUrls.visits }} />
                    </Route>
                  </Switch>
                </ResourceLayout>
              </Route>
            </Switch>
          </Suspense>
        </Switch>
      </PeopleReduxManager>
    </ProfileContext.Provider>
  );
};

export default withRouter(
  connect(
    ({ profile }) => ({
      profile,
      ...mapPermissionsToProps({ profile }),
      ...mapPreferencesToProps({ profile }),
    }),
    actions
  )((props) => <Routes paths={paths} {...props} />)
);
