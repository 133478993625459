import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import FormHelperText from '@mui/material/FormHelperText';

const Index = ({ label, error, helperText, ...rest }) => {
  // I define the Control component here because I need it to rerender
  // every time to keep up with formData state changes
  const Control = (props) => <Checkbox {...props} {...rest} />;

  return (
    <FormGroup>
      <FormControlLabel control={<Control />} {...{ label }} />
      {error && <FormHelperText error>{error}</FormHelperText>}
      {!error && helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormGroup>
  );
};

export default Index;
