import { useState } from 'react';
import { connect } from 'react-redux';

import store from 'store';
import actions from 'store/v2/facilities/actions';
import { add_message } from 'store/v1/app/actions';
import { logout } from 'store/v1/authentication/actions';

import { defaultMoment, PROMPT_TIMEOUT } from 'config';

import { useInterval } from 'lib/v1/hooks';
import { lastSuccessfulApiRequest } from 'lib/v2/request';

import { mapPreferencesToProps } from 'mappers/v1/preferences';

import ConfirmModal from '../FormElements/ConfirmModal';

const IdleTimerDialog = ({ logout, ping, profile }) => {
  const loggedIn = !!profile?.id;

  const sessionTimeout = defaultMoment
    .duration(profile?.company?.session_timeout, 'seconds')
    .subtract(PROMPT_TIMEOUT, 'milliseconds')
    .subtract(1, 'minute') // safety buffer
    .asMilliseconds();

  const [isOpen, setIsOpen] = useState(false);
  const [countdown, setCountdown] = useState(PROMPT_TIMEOUT);

  useInterval(
    () => {
      // console.log(`idle timer tick, last successful API response was at: ${lastSuccessfulApiRequest}`)

      if (Date.now() > lastSuccessfulApiRequest + sessionTimeout) {
        // console.log(`idle timer warning, countdown: ${countdown} ms`)
        setIsOpen(true);
      }

      if (countdown <= 1) {
        // console.log(`idle timer timeout, logging out`)
        store.dispatch(
          add_message('idle_timeout', ['Automatically signed out due to inactivity.'])
        );
        setIsOpen(false);
        logout();
      }

      if (isOpen) setCountdown(countdown - 1000);
    },
    loggedIn ? 1000 : null // only run interval when logged in
  );

  const onConfirm = () => {
    setCountdown(PROMPT_TIMEOUT);
    setIsOpen(false);
    ping();
  };

  return (
    <ConfirmModal
      confirmText="Continue"
      isOpen={isOpen}
      onConfirm={onConfirm}
      title="Session Timeout"
    >
      Your online session will expire in {Math.trunc(countdown / 1000)} seconds.
      <br />
      Click <i>Continue</i> to keep working or you will be automatically logged out.
    </ConfirmModal>
  );
};

export default connect(({ profile }) => ({ profile, ...mapPreferencesToProps({ profile }) }), {
  logout,
  ping: actions.dropdown,
})(IdleTimerDialog);
