import { isArray } from 'lib/v1/types';
import { v4 as uuidv4 } from 'uuid';
import { relationshipIsArray, relationshipIsValidObject } from 'adapters/v1/json_api';

// Initial State
export const initialState = () => {
  return {
    data: {},
    dropdown: {},
    activity: {},
    meta: { total_count: 0, cohort_count: 0, attributes: { values: {}, schema: {} } },
    result: [], // Why? This helps maintain sort order. It is an array of ids.
    dropdownResult: [],
    loading: false,
    schema: {},
    error: null,
    initialState: true, // This tells us if the store has ever been updated.  This is useful for assistantance with ZeroStates
    query: {
      search: null,
      include: null,
      filters: {},
      sort: null,
      dir: 'asc',
      per_page: 10,
      page: 1,
    },
    filters: [],
    included: [],
    jsonapi: false,
    storeStateId: null,
  };
};

export const toObject = (payload, key = 'id') => {
  if (payload && isArray(payload)) {
    return payload.reduce((obj, item) => {
      obj[item[key]] = item;
      return obj;
    }, {});
  }

  return payload;
};

export const noop = ({ state }) => state;

// Reducer functions
export const pending = ({ state }) => {
  return {
    ...state,
    loading: true,
  };
};

const mapRelationships = (_included, obj) => {
  const { relationships } = obj;
  if (!relationships) return obj;

  Object.entries(relationships).forEach(([type, relation]) => {
    if (relationshipIsValidObject(relation)) {
      const datum = _included[relation.data.type][relation.data.id];

      if (!datum) return;
      obj.relationships[type].data = datum;
    } else if (relationshipIsArray(relation)) {
      const { data, meta } = relation;
      const datum = _included[meta.type] || {};
      obj.relationships[type].data = data ? data.map((obj) => datum[obj.id]) : [];
    }
  });

  return obj;
};

export const jsonSchemaMapper = ({ payload = [], included = [], jsonapi = false } = {}) => {
  if (!jsonapi || !included.length) return { data: toObject(payload) };

  const _included = {};
  included.forEach((obj) => {
    const { type } = obj;

    if (_included[type]) {
      _included[type][obj.id] = obj;
    } else {
      _included[type] = { [obj.id]: obj };
    }
  });

  if (payload && payload.map) {
    const data = payload.map((obj) => mapRelationships(_included, obj));
    return { data: toObject(data) };
  }

  return { data: mapRelationships(_included, payload) };
};

export const list = ({
  state = initialState(),
  payload = [],
  meta = {},
  included = [],
  query = {},
  jsonapi = false,
} = {}) => {
  // don't persist showAll, when needed it will be called again
  if (query.showAll) {
    query = {
      ...query,
      showAll: false,
      page: state.query.page,
      per_page: state.query.per_page,
    };
  }

  return {
    ...state,
    ...jsonSchemaMapper({ included, payload, jsonapi }),
    result: payload && isArray(payload) ? payload.map((obj) => obj.id) : [],
    meta: { ...state.meta, ...meta },
    included,
    loading: false,
    initialState: false,
    query,
    jsonapi,
  };
};

export const activity = ({ included = [], jsonapi = false, payload = [], state = {} } = {}) => {
  jsonSchemaMapper({ included, jsonapi, payload }); // Why does this make it work? ಠ_ಠ
  return {
    ...state,
    activity: toObject(payload),
    loading: false,
    initialState: false,
    jsonapi,
  };
};

export const dropdown = ({
  state = {},
  payload = [],
  meta = { attributes: { values: {} } },
} = {}) => {
  return {
    ...state,
    meta: { ...state.meta, ...meta },
    dropdown: toObject(payload),
    dropdownResult: payload?.map && payload.map(({ id }) => id), // preserves sorted order from backend
    loading: false,
    initialState: false,
  };
};

export const mergeBulkState = ({
  state = initialState(),
  payload = [],
  included,
  jsonapi = false,
} = {}) => {
  let meta = {};

  if (payload && payload.length) {
    const data = toObject(payload);
    const mapped = jsonSchemaMapper({ included, payload, jsonapi });
    state.data = { ...state.data, ...data, ...mapped.data };
    state.dropdown = { ...state.dropdown, ...data };
    state.result = [...new Set(state.result.concat(payload.map((obj) => obj.id)))];

    const total_count = state.meta.total_count
      ? state.meta.total_count + payload.length
      : payload.length;

    meta = { cohort_count: state.result.length, total_count };
  }

  return {
    ...state,
    meta: { ...state.meta, ...meta },
    loading: false,
    initialState: false,
  };
};

// show, update, and create all perform a mergeState operation on success
export const mergeState = ({
  state = initialState(),
  payload = {},
  included = [],
  query = { filters: {} },
  filters = {},
  meta = {},
  schema = {},
  jsonapi = false,
} = {}) => {
  const id = payload && payload.id;
  let _meta = {};

  const result = [...state.result, payload.id]
    .filter((obj) => obj)
    .filter((elem, index, array) => array.indexOf(elem) === index);

  if (result.length > state.result.length) {
    _meta = { cohort_count: state.meta.cohort_count + 1, total_count: state.meta.total_count + 1 };
  }

  const { data } = jsonSchemaMapper({ included, payload, jsonapi });

  let record = id ? { [id]: data } : {};

  // if record exists, extend instead of replacing completely
  if (state.data[id]) {
    const updatedRelationships = { ...record[id].relationships };
    const relationshipKeys = Object.keys(updatedRelationships);

    // we don't want to overwrite the entire relationships object
    if (payload.relationships) {
      delete record[id].relationships;
    }

    record[id] = { ...state.data[id], ...record[id] };

    if (updatedRelationships) {
      // update each updated relationship after the overall updated object
      relationshipKeys.forEach((key) => {
        record[id].relationships[key] = { ...updatedRelationships[key] };
      });
    }
  }

  // Note: If you are merging nested objects you have to explicitly set sub keys
  // otherwise you will overwrite updates to child objects.
  const queryFilters = { ...state.query.filters, ...query.filters };
  const activeFilters = { ...state.filters, ...filters };

  // Also, you've got to explicitly delete query filters
  // set by the activeFilter values set in the store ;/
  Object.entries(activeFilters).forEach(([key, value]) => {
    if (!value) delete queryFilters[key];
  });

  return {
    ...state,
    data: { ...state.data, ...record },
    meta: { ...state.meta, ...meta, ..._meta },
    schema: { ...state.schema, ...schema },
    dropdown: { ...state.dropdown, ...record },
    loading: false,
    query: { ...state.query, ...query, filters: queryFilters },
    result,
    included,
    filters: activeFilters,
    initialState: query.initialState ? query.initialState : false,
    jsonapi,
  };
};

export const fail = ({ state, error }) => {
  return {
    ...state,
    loading: false,
    error: error,
  };
};

export const destroy = ({ state, payload }) => {
  let meta = {};

  const result =
    payload && payload.id ? state.result.filter((id) => id !== payload.id) : state.result;

  if (payload && payload.id && state && state.data) {
    delete state.data[payload.id];

    if (state.dropdown) {
      delete state.dropdown[payload.id];
    }

    const cohort_count = state.meta.cohort_count
      ? state.meta.cohort_count - 1
      : state.meta.cohort_count;

    const total_count = state.meta.total_count
      ? state.meta.total_count - 1
      : state.meta.total_count;

    meta = { total_count: total_count, cohort_count };
  }

  return {
    ...state,
    meta: { ...state.meta, ...meta },
    result,
    loading: false,
    initialState: false,
  };
};

export const destroyBulk = ({ state, payload = {}, meta = {} } = {}) => {
  const { destroyed } = meta;
  // Backwards compatibility for JSONAPI.  JsonAPI spec includes data
  const ids = payload && payload.data ? payload.data.ids : payload.ids;

  if (ids && ids.length) {
    const count = ids.length;

    ids.forEach((id) => {
      delete state.data[id];
      delete state.dropdown[id];
    });

    state.result = state.result.filter((id) => !ids.includes(id));

    const cohort_count = state.meta.cohort_count
      ? state.meta.cohort_count - count
      : state.meta.cohort_count;

    const total_count = state.meta.total_count
      ? state.meta.total_count - count
      : state.meta.total_count;

    meta = { total_count: total_count, cohort_count };
  } else {
    const existing = Object.keys(state.data);
    state.data = {};
    state.result = [];
    state.meta.total_count = 0;

    if (state.meta && state.meta.cohort_count && destroyed) {
      state.meta.cohort_count = state.meta.cohort_count - destroyed;
    }

    existing.forEach((id) => {
      delete state.dropdown[id];
    });
  }

  return {
    ...state,
    meta: { ...state.meta, ...meta },
    loading: false,
    initialState: false,
  };
};

const triggerStateChange = ({ state }) => {
  return { ...state, storeStateId: uuidv4() };
};

const index = (name, customInitialState) => {
  // Allow overwriting of any default reducer function if necessary
  const {
    listPending = pending,
    listSuccess = list,
    listFail = fail,
    dropdownPending = pending,
    dropdownSuccess = dropdown,
    dropdownFail = fail,
    activityPending = pending,
    activitySuccess = activity,
    activityFail = fail,
    schemaPending = pending,
    schemaSuccess = mergeState,
    schemaFail = fail,
    showPending = pending,
    showSuccess = mergeState,
    showFail = fail,
    updatePending = pending,
    updateSuccess = mergeState,
    updateFail = fail,
    updateBulkPending = pending,
    updateBulkSuccess = mergeBulkState,
    updateBulkFail = fail,
    createPending = pending,
    createSuccess = mergeState,
    createFail = fail,
    createBulkPending = pending,
    createBulkSuccess = mergeBulkState,
    createBulkFail = fail,
    destroyPending = pending,
    destroySuccess = destroy,
    destroyFail = fail,
    destroyBulkPending = pending,
    destroyBulkSuccess = destroyBulk,
    destroyBulkFail = fail,
  } = {};

  return (
    state = customInitialState || initialState(),
    { type, payload, meta, activity, included, error, query, filters, schema, jsonapi }
  ) => {
    const actions = {
      [`TRIGGER_${name}_PENDING`]: noop,
      [`TRIGGER_${name}_SUCCESS`]: triggerStateChange,
      [`TRIGGER_${name}_FAILURE`]: fail,
      [`LIST_${name}_PENDING`]: listPending,
      [`LIST_${name}_SUCCESS`]: listSuccess,
      [`LIST_${name}_FAILURE`]: listFail,
      [`FETCH_${name}_PENDING`]: noop,
      [`FETCH_${name}_SUCCESS`]: noop,
      [`FETCH_${name}_FAILURE`]: noop,
      [`SCHEMA_${name}_PENDING`]: schemaPending,
      [`SCHEMA_${name}_SUCCESS`]: schemaSuccess,
      [`SCHEMA_${name}_FAILURE`]: schemaFail,
      [`DROPDOWN_${name}_PENDING`]: dropdownPending,
      [`DROPDOWN_${name}_SUCCESS`]: dropdownSuccess,
      [`DROPDOWN_${name}_FAILURE`]: dropdownFail,
      [`SHOW_${name}_PENDING`]: showPending,
      [`SHOW_${name}_SUCCESS`]: showSuccess,
      [`SHOW_${name}_FAILURE`]: showFail,
      [`SHOW_POLLING_${name}_PENDING`]: noop,
      [`SHOW_POLLING_${name}_SUCCESS`]: noop,
      [`SHOW_POLLING_${name}_FAILURE`]: noop,
      [`SHOW_ACTIVITY_${name}_PENDING`]: activityPending,
      [`SHOW_ACTIVITY_${name}_SUCCESS`]: activitySuccess,
      [`SHOW_ACTIVITY_${name}_FAILURE`]: activityFail,
      [`DESTROY_${name}_PENDING`]: destroyPending,
      [`DESTROY_${name}_SUCCESS`]: destroySuccess,
      [`DESTROY_${name}_FAILURE`]: destroyFail,
      [`DESTROY_BULK_${name}_PENDING`]: destroyBulkPending,
      [`DESTROY_BULK_${name}_SUCCESS`]: destroyBulkSuccess,
      [`DESTROY_BULK_${name}_FAILURE`]: destroyBulkFail,
      [`UPDATE_${name}_PENDING`]: updatePending,
      [`UPDATE_${name}_SUCCESS`]: updateSuccess,
      [`UPDATE_${name}_FAILURE`]: updateFail,
      [`UPDATE_BULK_${name}_PENDING`]: updateBulkPending,
      [`UPDATE_BULK_${name}_SUCCESS`]: updateBulkSuccess,
      [`UPDATE_BULK_${name}_FAILURE`]: updateBulkFail,
      [`CREATE_${name}_PENDING`]: createPending,
      [`CREATE_${name}_SUCCESS`]: createSuccess,
      [`CREATE_${name}_FAILURE`]: createFail,
      [`CREATE_BULK_${name}_PENDING`]: createBulkPending,
      [`CREATE_BULK_${name}_SUCCESS`]: createBulkSuccess,
      [`CREATE_BULK_${name}_FAILURE`]: createBulkFail,
      [`UPDATE_${name}_QUERY_PENDING`]: updatePending,
      [`UPDATE_${name}_QUERY_SUCCESS`]: updateSuccess,
      [`UPDATE_${name}_QUERY_FAILURE`]: updateFail,
    };

    const action = actions[type];
    return action
      ? action({ state, schema, payload, meta, activity, included, error, query, filters, jsonapi })
      : state;
  };
};

export default index;
