import { config } from 'config/index';
import { currentUserCompany } from 'lib/v1/users';

import superagent from 'superagent';
import superagentUse from 'superagent-use';

// Used to track last successful API response for use with idle timer dialog
export let lastSuccessfulApiRequest;

const agent = superagentUse(superagent);

const setUrl =
  ({ prefix = '', host } = {}) =>
  (req) => {
    if (req.url[0] === '/') {
      const effectiveHost = host || config.effectiveApiUrl();
      req.url = `${effectiveHost}${prefix}${req.url}`;
    }

    return req;
  };

function tracker(request) {
  const { preference } = currentUserCompany();
  if (preference?.beta_tester) {
    try {
      const { pathname } = new URL(request.url);
      const tracking = localStorage.getItem('v1_tracker') || '{}';
      const parsedTracking = JSON.parse(tracking);

      if (parsedTracking[pathname]) {
        parsedTracking[pathname] += 1;
      } else {
        parsedTracking[pathname] = 1;
      }
      localStorage.setItem('v1_tracker', JSON.stringify(parsedTracking));
    } catch (e) {
      console.log('V1 tracker error', e); // eslint-disable-line no-console
    }
  }
}

export const newRequest = ({ prefix, host } = {}) => {
  const request = agent.agent();
  request.use(setUrl({ prefix, host }));
  request.withCredentials();
  if (prefix?.includes('v1')) request.use(tracker);

  // Track last successful API response for use with idle timer dialog
  request.on('response', ({ ok }) => {
    if (ok) lastSuccessfulApiRequest = Date.now();
  });

  return request;
};

export default newRequest();
