import React from 'react';

import DateRangeFilter, { BuildDateRangeFilter } from 'components/v2/Filters/DateRange';
import { ByMeta, ByTag, BuildBinarySelectFilter } from 'components/v2/Filters/MultiSelect';
import MultiSelect, { ByUser, ByFacility } from './MultiSelect';
import { RiskSliderDialog } from 'components/v2/Filters/Slider';

import selectable from 'adapters/v1/selectable';

export const ByEligibility = ({
  fieldName = 'user_eligibilities',
  displayName = 'Eligibility Type',
  updateQuery,
}) => {
  const mapStateToSelect = ({ types }) => {
    const { loading } = types;

    return {
      options: selectable(types, { key: 'type_eligibilities', value: 'display_name' }),
      isLoading: loading,
      disabled: loading,
    };
  };

  const multiSelectProps = {
    name: fieldName,
    displayName,
    updateQuery,
    mapStateToSelect,
  };

  return {
    Component: (props) => <MultiSelect {...multiSelectProps} {...props} />,
    label: displayName,
    value: fieldName,
    icon: 'calendar',
  };
};

export const ByAccess = ({
  fieldName = 'user_accesses',
  displayName = 'Access Type',
  updateQuery,
}) => {
  const mapStateToSelect = ({ types }) => {
    const { loading } = types;

    return {
      options: selectable(types, { key: 'type_accesses', value: 'display_name', arrayData: true }),
      isLoading: loading,
      disabled: loading,
    };
  };

  const multiSelectProps = {
    name: fieldName,
    displayName,
    updateQuery,
    mapStateToSelect,
  };

  return {
    Component: (props) => <MultiSelect {...multiSelectProps} {...props} />,
    label: displayName,
    value: fieldName,
    icon: 'calendar',
  };
};

export const ByRole = ({ fieldName = 'role', displayName = 'Role', updateQuery }) => {
  const mapStateToSelect = ({ customRoles }) => {
    const { loading } = customRoles;

    const defaultRolesOptions = [
      { label: 'Administrator', value: 'admin' },
      { label: 'Security Manager', value: 'sec-mngr' },
      { label: 'Assistant Security Manager', value: 'asst-sec-mngr' },
      { label: 'Employee', value: 'employee' },
    ];
    // This is expected to be an Int on the backend filter
    const customRolesOptions = selectable(customRoles, {
      key: 'dropdown',
      value: 'display_name',
    }).map((role) => ({ ...role, value: parseInt(role.value) }));

    return {
      options: defaultRolesOptions.concat(customRolesOptions),
      isLoading: loading,
      disabled: loading,
    };
  };

  const multiSelectProps = {
    name: fieldName,
    displayName,
    updateQuery,
    mapStateToSelect,
  };

  return {
    Component: (props) => <MultiSelect {...multiSelectProps} {...props} />,
    label: displayName,
    value: fieldName,
    icon: 'calendar',
  };
};

export const ByActive = BuildBinarySelectFilter({
  name: 'active',
  displayName: 'Active',
});

export const ByContract = ({ fieldName = 'contract', displayName = 'Contract', updateQuery }) => {
  const mapStateToSelect = ({ contractsV2 }) => {
    const { loading } = contractsV2;

    return {
      options: selectable(contractsV2, { key: 'dropdown', value: 'contract_name' }),
      isLoading: loading,
      disabled: loading,
    };
  };

  const multiSelectProps = {
    name: fieldName,
    displayName,
    updateQuery,
    mapStateToSelect,
  };

  return {
    Component: (props) => <MultiSelect {...multiSelectProps} {...props} />,
    label: displayName,
    value: fieldName,
    icon: 'calendar',
  };
};

// Date range filters aren't particularly reusable, and hired_at is highly specific to User
const HiredDate = ({ updateQuery }) => {
  const name = 'hired_at';
  const displayName = 'Hired Date';

  const dateRangeProps = {
    name,
    displayName,
    updateQuery,
  };

  return {
    Component: (props) => <DateRangeFilter {...dateRangeProps} {...props} />,
    label: displayName,
    value: name,
    icon: 'calendar',
  };
};

const ByCreateDate = ({ updateQuery }) => {
  const name = 'created_at';
  const displayName = 'Created Date';

  const dateRangeProps = {
    name,
    displayName,
    updateQuery,
  };

  return {
    Component: (props) => <DateRangeFilter {...dateRangeProps} {...props} />,
    label: displayName,
    value: name,
    icon: 'calendar',
  };
};

export const ByManager = (props) => {
  return ByUser({
    ...props,
    fieldName: 'manager_id',
    displayName: 'Manager',
  });
};

export const ByTitle = ({ displayName = 'Title', fieldName = 'title', ...rest }) => {
  return ByMeta({
    displayName,
    fieldName,
    metaKey: 'job_title',
    storeKey: 'people',
    ...rest,
  });
};

export const ByLocation = ({ displayName = 'Location', fieldName = 'location', ...rest }) => {
  return ByMeta({
    displayName,
    fieldName,
    metaKey: 'location',
    storeKey: 'people',
    ...rest,
  });
};

const ByRisk = ({ displayName = 'Risk Score', fieldName = 'risk', ...rest }) => {
  const riskProps = {
    displayName,
    name: fieldName,
    ...rest,
  };

  return {
    Component: (props) => <RiskSliderDialog {...riskProps} {...props} />,
    label: displayName,
    value: fieldName,
  };
};

export const ByEligibilityReinvestigationDue = BuildDateRangeFilter({
  displayName: 'Eligibility Reinvestigation Due',
  name: 'reinvestigation_at',
});

export const ByUserLoggedIn = BuildBinarySelectFilter({
  name: 'last_sign_in_at',
  displayName: 'User Logged In',
});

export const ByContinuousEvaluation = BuildBinarySelectFilter({
  name: 'continuous_evaluation',
  displayName: 'Continuous Evaluation',
});

export const ByInvestigation = ({
  fieldName = 'investigation_ids',
  displayName = 'Investigation',
  updateQuery,
}) => {
  const mapStateToSelect = ({ types }) => {
    const { loading } = types;
    return {
      options: selectable(types, { key: 'type_investigations', value: 'display_name' }),
      isLoading: loading,
      disabled: loading,
    };
  };

  const multiSelectProps = {
    name: fieldName,
    displayName,
    updateQuery,
    mapStateToSelect,
  };

  return {
    Component: (props) => <MultiSelect {...multiSelectProps} {...props} />,
    label: displayName,
    value: fieldName,
    icon: 'calendar',
  };
};

const filters = [
  ByAccess,
  ByActive,
  ByCreateDate,
  ByContract,
  ByFacility,
  ByContinuousEvaluation,
  ByEligibility,
  ByEligibilityReinvestigationDue,
  HiredDate,
  ByInvestigation,
  ByManager,
  ByRisk,
  ByRole,
  ByTag,
  ByLocation,
  ByTitle,
  ByUser,
  ByUserLoggedIn,
];

export default filters;
