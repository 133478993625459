import store from 'store';
import PropTypes from 'prop-types';

// adapters
import selectable from 'adapters/v1/selectable';

// factories
import { SelectFactory } from 'components/v2/Filters/Factories';

//////////////////////
// MultiSelect Filter /form base component.

// interface MultiSelectProps {
//   displayName: string;
//   mapDispatchToSelect?: () => void;
//   mapStateToSelect?: () => void;
//   multi?: boolean;
//   name: string;
//   updateQuery: () => void;
// }
//////////////////////

const MultiSelect = ({
  displayName,
  mapDispatchToSelect = {},
  mapStateToSelect = () => {},
  multi = true,
  name,
  updateQuery,
  ...rest
}) => {
  const onSubmit = (options) => {
    const label = multi ? options.map((option) => option.label).join(', ') : options.label;
    const _displayName = `${displayName}: ${label}`;
    const values = multi ? options.map((option) => option.value) : [options.value];

    const filters = { [name]: { values, displayName: _displayName } };

    store.dispatch(updateQuery({ filters: filters }));
  };

  const formProps = { ...rest, onSubmit, displayName };
  const FilterForm = SelectFactory({ mapStateToSelect, mapDispatchToSelect, multi });
  return <FilterForm {...formProps} />;
};

MultiSelect.propTypes = {
  displayName: PropTypes.string.isRequired,
  mapDispatchToSelect: PropTypes.func,
  mapStateToSelect: PropTypes.func,
  name: PropTypes.string.isRequired,
  updateQuery: PropTypes.func.isRequired,
};

export const SingleSelect = (props) => <MultiSelect multi={false} {...props} />;

export const BuildBasicSelectFilter = ({ displayName, name, options }) => {
  return ({ updateQuery }) => {
    const mapStateToSelect = () => {
      return {
        disabled: false,
        isLoading: false,
        options,
      };
    };

    const selectProps = {
      displayName,
      mapStateToSelect,
      name,
      updateQuery,
    };

    return {
      Component: (props) => <SingleSelect {...selectProps} {...props} />,
      label: displayName,
      value: name,
    };
  };
};

const defaultBooleanOptions = [
  { label: 'Yes', value: true },
  { label: 'No', value: false },
];

export const BuildBinarySelectFilter = (props) =>
  BuildBasicSelectFilter({ ...props, options: defaultBooleanOptions });

export const BuildBasicMultiSelectFilter = ({ name, displayName, options, multi }) => {
  return ({ updateQuery }) => {
    const mapStateToSelect = () => {
      return {
        disabled: false,
        isLoading: false,
        options: options ?? defaultBooleanOptions,
      };
    };

    const multiSelectProps = {
      displayName,
      mapStateToSelect,
      multi: multi === undefined ? options !== undefined : multi,
      name,
      updateQuery,
    };

    return {
      Component: (props) => <MultiSelect {...multiSelectProps} {...props} />,
      label: displayName,
      value: name,
    };
  };
};

//////////////////////
// Specialized/connected MultiSelect filters
//////////////////////

export const ByFacility = ({
  displayName = 'CAGE Code',
  fieldName = 'facility_id',
  id = 'id',
  updateQuery,
}) => {
  const mapStateToSelect = ({ facilitiesV2 }) => {
    const { loading } = facilitiesV2;
    return {
      disabled: loading,
      isLoading: loading,
      options: selectable(facilitiesV2, {
        id: id,
        key: 'dropdown',
        value: 'display_name',
      }),
    };
  };

  const multiSelectProps = {
    displayName,
    mapStateToSelect,
    name: fieldName,
    updateQuery,
  };

  return {
    Component: (props) => <MultiSelect {...multiSelectProps} {...props} />,
    label: displayName,
    value: fieldName,
    icon: 'calendar',
  };
};

export const ByUser = ({ displayName = 'User', fieldName = 'user_id', updateQuery }) => {
  const mapStateToSelect = ({ users }) => {
    const { loading } = users;
    return {
      disabled: loading,
      isLoading: loading,
      options: selectable(users),
    };
  };

  const multiSelectProps = {
    displayName,
    mapStateToSelect,
    name: fieldName,
    updateQuery,
  };

  return {
    Component: (props) => <MultiSelect {...multiSelectProps} {...props} />,
    label: displayName,
    value: fieldName,
    icon: 'calendar',
  };
};

export const ByCardTemplate = ({
  displayName = 'Request Type',
  fieldName = 'card_template_id',
  updateQuery,
}) => {
  const mapStateToSelect = ({ cardTemplates }) => {
    const { loading } = cardTemplates;
    return {
      disabled: loading,
      isLoading: loading,
      options: selectable(cardTemplates, { key: 'dropdown' }),
    };
  };

  const multiSelectProps = {
    displayName,
    mapStateToSelect,
    name: fieldName,
    updateQuery,
  };

  return {
    Component: (props) => <MultiSelect {...multiSelectProps} {...props} />,
    icon: 'calendar',
    label: displayName,
    value: fieldName,
  };
};

export const ByTag = ({ displayName = 'Tags', fieldName = 'tag_name_id', updateQuery }) => {
  const mapStateToSelect = ({ tagNames }) => {
    const { loading } = tagNames;
    return {
      disabled: loading,
      isLoading: loading,
      options: selectable(tagNames, { key: 'dropdown' }),
    };
  };

  const multiSelectProps = {
    displayName,
    mapStateToSelect,
    name: fieldName,
    updateQuery,
  };

  return {
    Component: (props) => <MultiSelect {...multiSelectProps} {...props} />,
    icon: 'calendar',
    label: displayName,
    value: fieldName,
  };
};

export const ByMeta = ({ displayName, fieldName, metaKey, storeKey, updateQuery }) => {
  const mapStateToSelect = (store) => {
    const attributes = store?.[storeKey]?.meta?.attributes?.values;
    const options = attributes?.[metaKey]?.data?.map((value) => ({ label: value, value }));

    return {
      disabled: false,
      isLoading: false,
      options: options || [],
    };
  };

  const multiSelectProps = {
    displayName,
    mapStateToSelect,
    name: fieldName,
    updateQuery,
  };

  return {
    Component: (props) => <MultiSelect {...multiSelectProps} {...props} />,
    label: displayName,
    value: fieldName,
  };
};

export const ByArchived = BuildBinarySelectFilter({ displayName: 'Archived', name: 'archived' });

export default MultiSelect;
