import React, { createContext, useMemo } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

// Icons
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

// MUI
import IconButton from '@mui/material/IconButton';
import ListItemIcon from '@mui/material/ListItemIcon';

// Styles
import { styled } from '@mui/material/styles';

// Components
import ListItem from '../ListItem';
import TextField from '../TextField';

const StyledListItemIcon = styled(ListItemIcon)({
  cursor: 'grab',
  width: '1rem',
  padding: '0',
  marginRight: -20,
  marginLeft: -20,
});

const StyledListItem = styled(ListItem)({
  display: 'flex',
  backgroundColor: 'rba(255, 255, 255, 0.8)',
  '& .MuiInputAdornment-root': {
    margin: '0 !important',
  },
  '& .MuiIconButton-root': {
    padding: 0,
  },
  '& .MuiSvgIcon-root': {
    width: '2rem',
  },
});

const SortableItemContext = createContext({
  attributes: {},
  listeners: undefined,
  ref() {},
});

export const DragHandle = () => {
  const { attributes, listeners, ref } = React.useContext(SortableItemContext);

  return (
    <StyledListItemIcon {...attributes} {...listeners} ref={ref}>
      <DragIndicatorIcon />
    </StyledListItemIcon>
  );
};

const noop = () => {};

export const SortableItem = ({
  children,
  disabled,
  onItemDelete = noop,
  onItemEdit = noop,
  showDelete,
  id,
  title,
}) => {
  const {
    attributes,
    isDragging,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
  } = useSortable({ disabled, id });

  const context = useMemo(
    () => ({
      attributes,
      listeners,
      ref: setActivatorNodeRef,
    }),
    [attributes, listeners, setActivatorNodeRef]
  );

  const style = {
    opacity: isDragging ? 0.4 : undefined,
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const handleDelete = (event) => onItemDelete(id, title);

  const DeleteButton = () => (
    <IconButton onClick={handleDelete}>
      <DeleteOutlineIcon />
    </IconButton>
  );

  return (
    <SortableItemContext.Provider value={context}>
      <StyledListItem
        ref={setNodeRef}
        style={style}
        key={`sortable-item-${id}`}
        disabled={disabled}
        onItemEdit={onItemEdit}
      >
        <DragHandle />
        {children}
        {showDelete && <DeleteButton />}
      </StyledListItem>
    </SortableItemContext.Provider>
  );
};

export const SortableTextField = ({ fieldLabel, textFieldProps, value, ...props }) => (
  <TextField
    autoFocus={!value}
    label={fieldLabel}
    key={`${fieldLabel}-textfield`}
    onChange={(event) => props.onItemEdit(props.id, event.target.value)}
    value={value}
    {...props}
    {...textFieldProps}
  />
);

SortableItem.TextField = SortableTextField;

export default SortableItem;
