import raygun from 'raygun4js';
import format from './format';
import options from 'config/constants/options';

const trackers = {
  intercom: {
    boot: (profile) => {
      try {
        const app_id = import.meta.env.REACT_APP_INTERCOM_ID ?? 'naceb5cx';
        /* eslint-disable */
        (function () {
          var w = window;
          var ic = w.Intercom;
          if (typeof ic === 'function') {
            ic('reattach_activator');
            ic('update', w.intercomSettings);
          } else {
            var d = document;
            var i = function () {
              i.c(arguments);
            };
            i.q = [];
            i.c = function (args) {
              i.q.push(args);
            };
            w.Intercom = i;
            function l() {
              var s = d.createElement('script');
              s.crossOrigin = 'anonymous';
              s.type = 'text/javascript';
              s.async = true;
              s.src = `https://widget.intercom.io/widget/${app_id}`;
              var x = d.getElementsByTagName('script')[0];
              x.parentNode.insertBefore(s, x);
            }
            if (w.attachEvent) {
              w.attachEvent('onload', l);
            } else {
              w.addEventListener('load', l, false);
            }
          }
        })();
        /* eslint-disable */
        if (profile) {
          window.Intercom('boot', {
            app_id,
            name: format.first_last(profile),
            email: profile.email,
            'Company Name': profile.company ? profile.company.legal_name : undefined,
            Role: (options.roles.find((d) => profile.role === d.value) || {}).label,
            created_at: new Date().getTime(),
            sis_app: `SIC - ${import.meta.env.REACT_APP_ENVIRONMENT}`,
          });
        } else {
          window.Intercom('boot', { app_id });
        }
      } catch (e) {
        console.error('Could not boot intercom: ' + e);
      }
    },
    update: (email) => {
      try {
        window.Intercom('update', { email });
      } catch (e) {
        console.error('Could not update intercom: ' + e);
      }
    },
    track: (obj) => {
      try {
        window.Intercom('trackEvent', obj.name, obj.details);
      } catch (e) {
        console.error('Could not track event in intercom: ' + e);
      }
    },
  },

  heap: {
    boot: (profile) => {
      try {
        /* eslint-disable */
        (window.heap = window.heap || []),
          (heap.load = function (e, t) {
            (window.heap.appid = e), (window.heap.config = t = t || {});
            var r = t.forceSSL || 'https:' === document.location.protocol,
              a = document.createElement('script');
            (a.crossOrigin = 'anonymous'),
              (a.type = 'text/javascript'),
              (a.async = !0),
              (a.src = (r ? 'https:' : 'http:') + '//cdn.heapanalytics.com/js/heap-' + e + '.js');
            var n = document.getElementsByTagName('script')[0];
            n.parentNode.insertBefore(a, n);
            for (
              var o = function (e) {
                  return function () {
                    heap.push && heap.push([e].concat(Array.prototype.slice.call(arguments, 0)));
                  };
                },
                p = [
                  'addEventProperties',
                  'addUserProperties',
                  'clearEventProperties',
                  'identify',
                  'removeEventProperty',
                  'setEventProperties',
                  'track',
                  'unsetEventProperty',
                ],
                c = 0;
              c < p.length;
              c++
            )
              heap[p[c]] = o(p[c]);
          });
        var id = +import.meta.env.REACT_APP_HEAP_ID;
        heap.load(isNaN(id) ? 4169214759 : id);
        /* eslint-disable */

        const company = profile.company || {};

        window.heap.addUserProperties({
          'Company Name': company.legal_name,
          'Account Type': company.account_type,
          Role: profile.role,
        });
      } catch (e) {
        console.error('Could not boot Heap: ' + e);
      }
    },
    update: (name, obj) => {
      try {
        heap.track(name, obj);
      } catch (e) {
        console.error('Could not log event to Heap: ' + name + ' - ' + JSON.stringify(obj));
      }
    },
  },

  raygun: {
    boot: (profile) => {
      if (import.meta.env.REACT_APP_RAYGUN_ENABLED && profile?.id && profile?.email) {
        raygun('setUser', {
          identifier: profile.id,
          isAnonymous: false,
          email: profile.email,
          fullName: profile.first_name + ' ' + profile.last_name,
          firstName: profile.first_name,
        });
      }
    },
  },

  google: {
    boot: () => {
      try {
        /* eslint-disable */
        (function (i, s, o, g, r, a, m) {
          i['GoogleAnalyticsObject'] = r;
          (i[r] =
            i[r] ||
            function () {
              (i[r].q = i[r].q || []).push(arguments);
            }),
            (i[r].l = 1 * new Date());
          (a = s.createElement(o)), (m = s.getElementsByTagName(o)[0]);
          a.async = 1;
          a.crossOrigin = 'anonymous';
          a.src = g;
          m.parentNode.insertBefore(a, m);
        })(window, document, 'script', 'https://www.google-analytics.com/analytics.js', 'ga');
        ga('create', 'UA-112220972-1', 'auto');
        ga('send', 'pageview');
        /* eslint-disable */
      } catch (e) {
        console.error('Could not boot Google Analytics: ' + e);
      }
    },
  },
};

export const start = (profile) => {
  // Temporarily disable analyics for a specific client
  const available = profile?.company?.id === 74 ? ['intercom'] : Object.keys(trackers);

  available.forEach((d) => {
    trackers[d].boot(profile);
  });
};

export default {
  start,
  ...trackers,
};
