import { Suspense } from 'react';
import { Route, Redirect, Switch } from 'react-router-dom';

import { ThreatSwitchRoute } from 'routes';
import { withPermissionsAndPreferences } from 'lib/v1/hooks';
import AdminLayout, { adminMenuData } from 'layouts/v1/AdminLayout';

// Routes.js based implementation - v2
import ActionRequestsRoutes from 'components/v2/ActionRequests/Routes';
import AssetControlsRoutes from 'components/v2/AssetControls/Routes';
import BadgeControlsRoutes from 'components/v2/BadgeControls/Routes';
import CompanyRoutes from 'components/v2/Company/Routes';
import ContactsRoutes from 'components/v2/Contacts/Routes';
import ContainerControlsRoutes from 'components/v2/ContainerControls/Routes';
import ContractsRoutes from 'components/v2/Contracts/Routes';
import DashboardRoutes from 'components/v2/Dashboard/Routes';
import DestructionEventsRoutes from 'components/v2/AssetControls/DestructionEvents/Routes';
import DigitalFormsRoutes from 'components/v2/DigitalForms/Routes';
import DocumentsRoutes from 'components/v2/Documents/Routes';
import FacilitiesRoutes from 'components/v2/Facilities/Routes';
import ImportsRoutes from 'components/v2/Imports/Routes';
import IntegrationsRoutes from 'components/v2/Integrations/Routes';
import LibraryRoutes from 'components/v2/Library/Routes';
import ReportableTemplatesRoutes from 'components/v2/ReportableTemplates/Routes';
import RequestTemplatesRoutes from 'components/v2/RequestTemplates/Routes';
import RequirementControlsRoutes from 'components/v2/RequirementControls/Routes';
import RiskRoutes from 'components/v2/RiskProfile/Routes';
import RolesRoutes from 'components/v2/Roles/Routes';
import TagsRoutes from 'components/v2/Tags/Routes';
import TagNamesRoutes from 'components/v2/TagNames/Routes';
import TasksRoutes from 'components/v2/Tasks/Routes';
import TransferEventsRoutes from 'components/v2/AssetControls/TransferEvents/Routes';
import VisitsRoutes from 'components/v2/VisitRequests/Routes';

// paths
import { adminPaths as actionRequestsPaths } from 'config/paths/actionRequests';
import assetControlsPaths from 'config/paths/assetControls';
import badgeControlsPaths from 'config/paths/badgeControls';
import companyPaths from 'config/paths/company';
import contactsPaths from 'config/paths/contacts';
import containerControlsPaths from 'config/paths/containerControls';
import contractsPaths from 'config/paths/contracts';
import dashboardPaths from 'config/paths/dashboard';
import destructionEventsPaths from 'config/paths/destructionEvents';
import digitalFormsPaths from 'config/paths/digitalForms';
import facilitiesPaths from 'config/paths/facilities';
import importsPaths from 'config/paths/imports';
import integrationsPaths from 'config/paths/integrations';
import libraryPaths from 'config/paths/library';
import reportableTemplatesPaths from 'config/paths/reportableTemplates';
import requestTemplatesPaths from 'config/paths/requestTemplates';
import requirementControlsPaths from 'config/paths/requirementControls';
import { adminPaths as riskPaths } from 'config/paths/risk';
import customRolesPaths from 'config/paths/customRoles';
import tagsPaths from 'config/paths/tags';
import tagNamesPaths from 'config/paths/tagNames';
import { adminPaths as tasksPaths } from 'config/paths/tasks';
import transferEventsPaths from 'config/paths/transferEvents';
import { adminPaths as visitsPaths } from 'config/paths/visitRequests';

import documentsPaths from 'config/paths/documents';
const adminRoot = '/admin';

const AdminRoutes = ({ permissions, preferences }) => {
  const { defaultPath = '/' } = adminMenuData({ permissions, preferences });

  return (
    <Suspense fallback={<></>}>
      <AdminLayout>
        <Switch>
          <Route exact path={adminRoot}>
            <Redirect to={defaultPath} />
          </Route>

          {/* ActionRequests */}
          <ThreatSwitchRoute path={actionRequestsPaths.root}>
            <ActionRequestsRoutes />
          </ThreatSwitchRoute>

          {/* AssetControls */}
          <ThreatSwitchRoute path={assetControlsPaths.root}>
            <AssetControlsRoutes />
          </ThreatSwitchRoute>

          {/* BadgeControls */}
          <ThreatSwitchRoute path={badgeControlsPaths.root}>
            <BadgeControlsRoutes />
          </ThreatSwitchRoute>

          {/* Company */}
          <ThreatSwitchRoute path={companyPaths.root}>
            <CompanyRoutes />
          </ThreatSwitchRoute>

          {/* Contacts */}
          <ThreatSwitchRoute path={contactsPaths.root}>
            <ContactsRoutes />
          </ThreatSwitchRoute>

          {/* Contracts */}
          <ThreatSwitchRoute path={contractsPaths.root}>
            <ContractsRoutes />
          </ThreatSwitchRoute>

          {/* ContainerControls */}
          <ThreatSwitchRoute path={containerControlsPaths.root}>
            <ContainerControlsRoutes />
          </ThreatSwitchRoute>

          {/* Admin Dashboard */}
          <ThreatSwitchRoute path={dashboardPaths.root}>
            <DashboardRoutes />
          </ThreatSwitchRoute>

          {/* DestructionEvents */}
          <ThreatSwitchRoute path={destructionEventsPaths.root}>
            <DestructionEventsRoutes />
          </ThreatSwitchRoute>

          {/* DigitalForms */}
          <ThreatSwitchRoute path={digitalFormsPaths.root}>
            <DigitalFormsRoutes />
          </ThreatSwitchRoute>

          {/* Documents */}
          <ThreatSwitchRoute path={documentsPaths.root}>
            <DocumentsRoutes />
          </ThreatSwitchRoute>

          {/* Facilities */}
          <ThreatSwitchRoute path={facilitiesPaths.root}>
            <FacilitiesRoutes />
          </ThreatSwitchRoute>

          {/* Imports */}
          <ThreatSwitchRoute path={importsPaths.root}>
            <ImportsRoutes />
          </ThreatSwitchRoute>

          {/* Integrations */}
          <ThreatSwitchRoute path={integrationsPaths.root}>
            <IntegrationsRoutes />
          </ThreatSwitchRoute>

          {/* Library */}
          <ThreatSwitchRoute path={libraryPaths.root}>
            <LibraryRoutes />
          </ThreatSwitchRoute>

          {/* ReportableTemplates */}
          <ThreatSwitchRoute path={reportableTemplatesPaths.root}>
            <ReportableTemplatesRoutes />
          </ThreatSwitchRoute>

          {/* RequestTemplates */}
          <ThreatSwitchRoute path={requestTemplatesPaths.root}>
            <RequestTemplatesRoutes />
          </ThreatSwitchRoute>

          {/* RequirementControls */}
          <ThreatSwitchRoute path={requirementControlsPaths.root}>
            <RequirementControlsRoutes />
          </ThreatSwitchRoute>

          {/* Risk */}
          <ThreatSwitchRoute path={riskPaths.root}>
            <RiskRoutes />
          </ThreatSwitchRoute>

          {/* Roles */}
          <ThreatSwitchRoute path={customRolesPaths.root}>
            <RolesRoutes />
          </ThreatSwitchRoute>

          {/* Tags */}
          <ThreatSwitchRoute path={tagsPaths.root}>
            <TagsRoutes />
          </ThreatSwitchRoute>

          {/* TagNames */}
          <ThreatSwitchRoute path={tagNamesPaths.root}>
            <TagNamesRoutes />
          </ThreatSwitchRoute>

          {/* Tasks */}
          <ThreatSwitchRoute path={tasksPaths.root}>
            <TasksRoutes />
          </ThreatSwitchRoute>

          {/* TransferEvents */}
          <ThreatSwitchRoute path={transferEventsPaths.root}>
            <TransferEventsRoutes />
          </ThreatSwitchRoute>

          {/* Visit Requests */}
          <ThreatSwitchRoute path={visitsPaths.root}>
            <VisitsRoutes />
          </ThreatSwitchRoute>
        </Switch>
      </AdminLayout>
    </Suspense>
  );
};

export default withPermissionsAndPreferences(AdminRoutes);
