import moment from 'moment-timezone';
moment.tz.setDefault('UTC');
export const defaultMoment = moment;

export const S3_TIMEOUT = 1000 * 60; // 1 hour
export const REQUEST_TIMEOUT = 5000;
export const PROMPT_TIMEOUT = 1000 * 60 * 1; //  1 min

export const displayDateFormat = 'MM/DD/YYYY';
export const displayDateTimeFormat = 'MMMM D, YYYY [at] h:mm A zz';

export const isTest = import.meta.env.REACT_APP_ENVIRONMENT === 'test';
export const isDevelopment = import.meta.env.REACT_APP_ENVIRONMENT === 'development';
export const isLabs = import.meta.env.REACT_APP_ENVIRONMENT === 'labs';
export const isProduction = import.meta.env.REACT_APP_ENVIRONMENT === 'production';
export const isNotProduction = !isProduction;

const getApiHosts = () => {
  let apiHosts = [];
  switch (import.meta.env.REACT_APP_ENVIRONMENT) {
    case 'staging':
      apiHosts = ['https://api-staging.threatswitch.com', 'https://oci-staging.threatswitch.com'];
      break;
    case 'development':
      apiHosts = ['http://localhost:3000'];
      break;
    case 'test':
      apiHosts = ['http://localhost:3000'];
      break;
    case 'labs':
      apiHosts = ['http://localhost:3000'];
      break;
    case 'beta':
      apiHosts = ['https://api-beta.threatswitch.com'];
      break;
    default:
      apiHosts = ['https://api.threatswitch.com', 'https://oci.threatswitch.com'];
      break;
  }

  return apiHosts;
};
export const apiHosts = getApiHosts();

const effectiveApiUrl = () => {
  // circle is being weird, so just return the first/only test host
  if (isTest) return apiHosts[0];

  // default to first host
  let apiUrl = apiHosts[0];
  const authHost = window.localStorage.getItem('auth_host');

  if (authHost && apiHosts.includes(authHost)) apiUrl = authHost;
  return apiUrl;
};

// TODO: annoyingly a lot of tests are hardcoded to mock this, so leaving apiUrl as default for now
export const config = {
  apiUrl: effectiveApiUrl(),
  effectiveApiUrl,
};

export const signatureRequestPdfUrl = (id) =>
  `${effectiveApiUrl()}/v1/forms/signature_requests/${id}/pdf`;
