import { connect } from 'react-redux';

import FormLayout from './FormLayout';

import DateRangePicker from 'components/v2/DateRangePicker';
import Select from 'components/v2/Filters/Select';

const FilterFormFactory = (Component) => {
  return (props) => {
    return (
      <FormLayout {...props}>
        <Component />
      </FormLayout>
    );
  };
};

export const SelectFactory = ({ mapDispatchToSelect, mapStateToSelect, multi = false }) => {
  const selectProps = {
    placeholder: 'Select',
    mapDispatchToSelect,
    mapStateToSelect,
    multi,
  };

  const innerMapStateToProps = (store) => ({ ...selectProps, ...mapStateToSelect(store) });

  return FilterFormFactory(connect(innerMapStateToProps, mapDispatchToSelect)(Select));
};

export const DateRangeFactory = () => FilterFormFactory(DateRangePicker);

export default FilterFormFactory;
