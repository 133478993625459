import React from 'react';

// Material UI
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { styled } from '@mui/material/styles';

import Icon from 'components/v2/PageElements/Icon';
import Button from 'components/v2/FormElements/Button';

import { hexToHsla } from 'lib/v2/colors';

// having trouble getting the icon to cooperate, so just grabbing the styled components for now
import { ActionsContainer, StyledCard, InfoContainer } from 'components/v2/PageElements/Card';

// state
import { withFeatureState } from './state';

export const WorkflowIcon = ({ icon, color }) => {
  const hasCustomIcon = !!icon?.Icon;

  const [CustomIcon] = React.useState(icon?.Icon);

  const StyledAvatar = styled(ListItemAvatar)(({ theme }) => {
    const iconColor = color?.color || theme.palette.tickets.main;
    const backgroundColor = hasCustomIcon ? hexToHsla(iconColor, '0.1') : 'transparent';

    return {
      color: iconColor,
      backgroundColor,
      display: 'flex',
      padding: 4,
      minWidth: 'auto',
      borderRadius: 3,
    };
  });

  return (
    <StyledAvatar>
      {!hasCustomIcon && <Icon icon="Workflow" />}
      {hasCustomIcon && <CustomIcon fontSize="large" />}
    </StyledAvatar>
  );
};

const WorkflowStyledCard = styled(StyledCard)({
  justifyContent: 'space-between',
});

const WorkflowInfoContainer = styled(InfoContainer)({
  display: 'flex',
  flexDirection: 'column',
});

const WorkflowCard = ({
  workflow,
  history,
  paths,
  onClick,
  permissions,
  preferences,
  simple = false,
}) => {
  const { id, name } = workflow;

  const handleClick = (e) => {
    e.stopPropagation();
    if (onClick) return onClick(workflow);
    history.push(paths.resource.detail(id));
  };

  const onClickSettings = (e) => {
    e.stopPropagation();
    history.push(paths.resource.edit(id));
  };

  return (
    <WorkflowStyledCard onClick={handleClick}>
      <Box>
        <WorkflowInfoContainer dense component="div">
          <ListItem disableGutters>
            <WorkflowIcon {...workflow} />
            <ListItemText
              primary={name}
              primaryTypographyProps={{
                variant: 'body1',
              }}
            />
          </ListItem>
          {preferences?.betaTester && (
            <ListItem>
              <ListItemText
                secondary={workflow?.summary}
                secondaryTypographyProps={{
                  variant: 'subtitle2',
                  style: {
                    // overflow: 'hidden',
                    // textOverflow: 'ellipsis',
                    textWrap: 'auto',
                  },
                }}
              />
            </ListItem>
          )}
        </WorkflowInfoContainer>
      </Box>
      {!simple && (
        <ActionsContainer>
          {permissions.workflows.update && (
            <Button variant="text" color="tickets" onClick={onClickSettings}>
              Settings
            </Button>
          )}
          <Button variant="text" color="primary" onClick={handleClick}>
            View Tickets
          </Button>
        </ActionsContainer>
      )}
    </WorkflowStyledCard>
  );
};

export default withFeatureState(WorkflowCard);
