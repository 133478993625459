import { get } from 'lib/v1/objects';
import { adaptToOriginalRowIds } from 'adapters/v1/table/rows';

export const relationshipHasExpectedMeta = (relationship, type) => {
  return (
    relationship &&
    relationship.meta &&
    relationship.meta.type &&
    relationship.meta.data &&
    relationship.meta.data === type
  );
};

export const relationshipIsObject = (relationship) => {
  return relationshipHasExpectedMeta(relationship, 'object');
};

export const relationshipIsValidObject = (relationship) => {
  return (
    relationshipIsObject(relationship) && relationship.data && relationship.data.id !== undefined
  );
};

export const relationshipIsValidObjectWithAttributes = (relationship) => {
  return relationshipIsValidObject(relationship) && relationship.data.attributes;
};

export const relationshipIsArray = (relationship) => {
  return relationshipHasExpectedMeta(relationship, 'array');
};

export const relationshipIsValidArray = (relationship) => {
  return (
    relationshipHasExpectedMeta(relationship, 'array') &&
    relationship.data &&
    Array.isArray(relationship.data)
  );
};

export const getCount = (resource, relationship) => {
  return get(resource, ['relationships', relationship, 'meta', 'count']) || 0;
};

export const adaptToAttributes = (resource) => {
  if (!resource) return {};

  // Relationship attributes
  if (resource && resource.data && resource.data.attributes) {
    return { ...resource.data.attributes, id: resource.data.id };
  }

  const updated =
    resource && resource.attributes ? { ...resource.attributes, id: resource.id } : { ...resource };

  return updated;
};

export const adaptAttributesToDetail = (resource) => {
  if (!resource) return {};
  const { attributes } = resource;

  if (!attributes || !resource.id) return resource;
  return { id: resource.id, ...resource.attributes };
};

export const adaptRelationshipsToDetail = (resource) => {
  if (!resource) return {};
  const { relationships } = resource;

  if (!relationships) return {};

  const payload = {};

  Object.entries(relationships).forEach(([key, value]) => {
    if (relationshipIsValidArray(value)) {
      payload[key] = value.data.map(adaptAttributesToDetail);
    } else if (relationshipIsValidObject(value)) {
      payload[key] = value && value.data ? adaptAttributesToDetail(value.data) : {};
    }
  });
  return payload;
};

export const adaptToBulkUpdateRequest = ({ attributes, ids, type }) => {
  const data = {
    attributes,
    ids,
    type,
  };

  return { data };
};

export const adaptSelectedToBulkUpdateRequest = ({ attributes, selected = [], type } = {}) => {
  const ids = adaptToOriginalRowIds(selected);

  const data = {
    attributes,
    ids,
    type,
  };

  return adaptToBulkUpdateRequest(data);
};

// Primary Detail Adapter
export const adaptJsonApiToDetail = (resource) => {
  const attributes = adaptAttributesToDetail(resource);
  const { json_fields, ...rest } = attributes;
  const custom_field_values = json_fields ? json_fields : {};
  // auto convert ? attributes from ruby to js
  Object.keys(attributes).forEach((key) => {
    const lastChar = key.at(-1);
    if (lastChar === '?') {
      const newKey = key.slice(0, -1);
      rest[newKey] = attributes[key];
    }
  });
  return { ...rest, ...adaptRelationshipsToDetail(resource), ...custom_field_values };
};

export const adaptTagsToRequest = ({ formData = {}, type = '' }) => {
  const adapted = formData;
  const relatedTagNames = adapted.tags && Array.isArray(adapted.tags) ? adapted.tags : [];
  const relationships = {
    tag_names: {
      data: relatedTagNames.map((t) => ({ type: 'tag_name', id: t.label })),
    },
  };

  return adaptToJsonApiRequest({ relationships, type });
};

// This nomenclature should be phased out, adapt...ToRequest is most idiomatic
export const adaptToJsonApiParams = ({ id, attributes = {}, type, relationships }) => {
  const data = {
    attributes,
    type,
  };

  if (data.attributes.id) {
    delete data.attributes.id;
  }

  if (id) {
    data.id = id;
  }

  if (relationships) {
    data.relationships = relationships;
  }

  return { data };
};

// Primary HTTP Request adapter
export const adaptToJsonApiRequest = adaptToJsonApiParams;
