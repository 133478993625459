import React, { useEffect } from 'react';
import { connect } from 'react-redux';

// MUI
import AssignmentIcon from '@mui/icons-material/Assignment';
import ListSubheader from '@mui/material/ListSubheader';
import { styled } from '@mui/material/styles';

// Components
import AddAttachment from './AddAttachment';
import AttachmentItem from './Item';
import FormItem from './FormItem';
import EmptyStatePlaceholder from 'components/v2/PageElements/EmptyStatePlaceholder';
import SidebarHeader from '../Sidebar/Header';
import SidebarList from 'components/v2/PageElements/Sidebar/List';
import SidebarListItem from 'components/v2/PageElements/Sidebar/ListItem';
import TrainingItem from './TrainingItem';
import FormDialog from './FormDialog';

// Actions
import documentActions from 'store/v2/documents/actions';
import assignmentActions from 'store/v2/digitalFormRequests/actions';

// Adapters
import { adaptToDetail as adaptDocumentToDetail } from 'adapters/v1/documents/formData';
import { adaptToDetail as adaptFormToDetail } from 'adapters/v1/attachedDigitalForms/formData';

// Redux State Mappers
import { mapPreferencesToProps } from 'mappers/v1/preferences';

// Paths
import { userFormsPath } from 'config/paths/userDigitalForms';

const AddButtons = styled(SidebarListItem)(({ theme }) => ({
  justifyContent: 'flex-end',
}));

export const AttachmentList = ({
  attached_digital_forms,
  attachedTrainingAssignments = [],
  data = [],
  destroyAssignment,
  destroyDocument,
  documentable_id,
  documentable_type,
  formable_id,
  formable_type,
  history,
  includeForm,
  includeTraining = false,
  isReviewer = false,
  jsonApi = false,
  permissions = {},
  preferences = {},
  profile,
  reload,
  subjects = [],
  updateQuery = () => {},
  ...rest
} = {}) => {
  const [assignments, setAssignments] = React.useState([]);
  const enableForm = preferences.forms && includeForm;

  const mapAssignments = (forms) => {
    if (forms && forms.length > 0) {
      setAssignments((prev) =>
        forms
          .map((form) => {
            return adaptFormToDetail(form).assignments;
          })
          .flat()
      );
    }
  };

  useEffect(() => {
    mapAssignments(attached_digital_forms);
  }, [attached_digital_forms]);

  const mapDocumentRelationships = (payload) => {
    if (!jsonApi) return payload;

    payload.relationships = {
      documents: {
        data: payload.documents,
        meta: {
          data: 'array',
          type: 'document',
        },
      },
    };
    delete payload.documents;
    return payload;
  };

  const mapFormRelationships = (payload) => {
    if (!jsonApi) return payload;

    payload.relationships = {
      attached_digital_forms: {
        data: payload.attached_digital_forms,
        meta: {
          data: 'array',
          type: 'digital_form',
        },
      },
    };
    delete payload.attached_digital_forms;

    return payload;
  };

  const callbackDocument = (newDoc) => {
    const payload = {
      id: documentable_id,
      documents: [...data, newDoc],
    };

    updateQuery({ payload: mapDocumentRelationships(payload) });
  };

  const callbackTraining = () => {
    reload();
  };

  const callbackForm = async (newForm, newAssignment) => {
    newForm.assignments.push(newAssignment);

    const payload = {
      id: formable_id,
      attached_digital_forms: [...attached_digital_forms, newForm],
    };

    await updateQuery({ payload: mapFormRelationships(payload) });

    await reload();
  };

  const handleDocumentDestroy = async (id) => {
    if (!window.confirm(`Are you sure you want to delete this attachment?`)) return;

    const { success } = await destroyDocument(id);

    if (success) {
      const payload = {
        id: documentable_id,
        documents: [...data].filter((doc) => doc.id !== id),
      };

      updateQuery({ payload: mapDocumentRelationships(payload) });
    }
  };

  const handleFormDestroy = async (id, attached_digital_form_id) => {
    if (!window.confirm(`Are you sure you want to delete this attachment?`)) return;

    const form = attached_digital_forms.find(
      (el) => el.id.toString() === attached_digital_form_id.toString()
    );
    if (!form) return;

    const { success } = await destroyAssignment(id);

    if (success) {
      form.assignments = form.assignments.filter((form) => form.id.toString() !== id.toString());

      const payload = {
        id: formable_id,
        attached_digital_forms: [...attached_digital_forms],
      };

      await updateQuery({ payload: mapFormRelationships(payload) });

      await reload();
    }
  };

  const handleFormShow = async (user_id, id) => {
    if (profile.id !== user_id && profile.role !== 'admin') return;
    history.push(userFormsPath(user_id, id));
  };

  const zeroStateMessage = () => {
    if (!permissions.create) return 'No attachments';
    return enableForm ? 'Attach a file, link, or form below' : 'Attach a file or link below';
  };

  const documentableProps = {
    callbackDocument,
    callbackTraining,
    documentable_type,
    documentable_id,
  };
  const formableProps = {
    callbackForm,
    formable_type,
    formable_id,
    preferences,
    subjects,
    ...rest,
  };

  return (
    <>
      <SidebarList>
        {!data.length && !assignments.length && (
          <EmptyStatePlaceholder>{zeroStateMessage()}</EmptyStatePlaceholder>
        )}
        {data.length > 0 && <ListSubheader>Files</ListSubheader>}
        {data.map((document) => (
          <AttachmentItem
            attachment={adaptDocumentToDetail(document)}
            destroy={handleDocumentDestroy}
            key={`attached-document-${document.id}`}
            permissions={permissions}
            documentable_type={documentable_type}
          />
        ))}
      </SidebarList>
      {enableForm && assignments.length > 0 && (
        <SidebarList>
          <ListSubheader>Forms</ListSubheader>
          <FormDialog />
          {assignments.map((form) => (
            <FormItem
              attachment={form}
              destroy={handleFormDestroy}
              show={handleFormShow}
              icon={AssignmentIcon}
              key={`form-${form && form.id}`}
              permissions={permissions}
              isReviewer={isReviewer}
            />
          ))}
        </SidebarList>
      )}
      {includeTraining && attachedTrainingAssignments.length > 0 && (
        <SidebarList>
          <ListSubheader>Training</ListSubheader>
          <FormDialog />
          {attachedTrainingAssignments.map((trainingAssignment) => (
            <TrainingItem
              trainingAssignment={trainingAssignment}
              permissions={permissions}
              reload={reload}
              resourceable_type={documentable_type}
            />
          ))}
        </SidebarList>
      )}
      <SidebarList>
        <AddButtons>
          {permissions.create && (
            <>
              <AddAttachment
                {...documentableProps}
                {...formableProps}
                includeForm={enableForm}
                includeTraining={includeTraining}
              />
            </>
          )}
        </AddButtons>
      </SidebarList>
    </>
  );
};

const Attachments = ({ title = 'Attachments', includeHeader = true, ...props }) => {
  return (
    <div className="attachmentsModule">
      {includeHeader && <SidebarHeader>{title}</SidebarHeader>}
      <AttachmentList {...props} />
    </div>
  );
};

const actionCreators = {
  destroyAssignment: assignmentActions.destroy,
  destroyDocument: documentActions.destroy,
};

export default connect(
  ({ profile }) => ({
    ...mapPreferencesToProps({ profile }),
    profile,
  }),
  actionCreators
)(Attachments);
