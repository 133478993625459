import { adaptToIso, adaptToDisplay } from 'adapters/v1/dates';
import {
  cellAdapterFactory,
  cellLinkAdapterFactory,
  chipCellAdapterFactory,
} from 'adapters/v2/factories';
import {
  adaptToJsonApiRequest,
  adaptJsonApiToDetail,
  adaptTagsToRequest,
} from 'adapters/v1/json_api';

const dateFields = ['created_at', 'submitted_at', 'completed_at', 'updated_at'];

export const adaptFormTagsToRequest = (formData) =>
  adaptTagsToRequest({ formData, type: 'digital_form_template' });

export const adaptToRequest = (formData) => {
  const adapted = adaptToIso(formData, dateFields);

  if (adapted.signer_roles) {
    adapted.signer_roles = adapted.signer_roles.map(({ label }, index) => ({
      name: label,
      order: index + 1,
    }));
  }

  return adaptToJsonApiRequest({ attributes: adapted, type: 'digital_form_template' });
};

export const adaptToDetail = (resource) => {
  if (!resource) return {};
  const updated = adaptToDisplay(adaptJsonApiToDetail(resource), dateFields);
  const { tag_names } = updated;

  updated.tag_array = tag_names && Array.isArray(tag_names) ? tag_names.map((obj) => obj.name) : [];

  return updated;
};

export const adaptToForm = (resource) => {
  if (!resource) return {};
  const updated = adaptToDisplay(adaptJsonApiToDetail(resource), dateFields);
  const { tag_names } = updated;

  if (tag_names && Array.isArray(tag_names)) {
    updated.tags = tag_names.map((obj) => ({ title: obj.name, label: obj.id }));
  }

  return { ...updated };
};

export const cellAdapter = cellAdapterFactory(adaptToDetail);
export const cellLinkAdapter = cellLinkAdapterFactory(adaptToDetail);
export const chipCellAdapter = chipCellAdapterFactory(adaptToDetail);
