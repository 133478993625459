import React from 'react';

import { connect } from 'react-redux';

// mui
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import { useForm } from 'components/v2/FormElements';
import TextField from 'components/v2/FormElements/TextField';
import { SubmitButton, CancelButton } from 'components/v2/FormElements/Buttons';
import { login } from 'store/v1/authentication/actions';

const Form = ({
  accountEmail,
  setLoading,
  login,
  authConfig = {},
  handlePasswordReset,
  handleCancel,
  loading,
}) => {
  const showMessage = authConfig && authConfig.message;
  const showPassword = accountEmail && !authConfig.sso && !showMessage;
  const showSubmit = (!accountEmail || !authConfig.sso) && !showMessage;
  const [requiresMfa, setMfaEnabled] = React.useState(false);

  const { getTextFieldHandlerProps, formData } = useForm({
    resource: { email: accountEmail },
  });

  const onClick = async (e) => {
    if (e && e.preventDefault) e.preventDefault();
    if (e && e.stopPropagation) e.stopPropagation();
    setLoading(true);
    const { mfaEnabled } = await login(formData);
    if (mfaEnabled) setMfaEnabled(true);
    setLoading(false);
  };

  let ssoLabel = 'SSO Login';
  if (authConfig.sso_provider) ssoLabel += ' with ' + authConfig.sso_provider;

  return (
    <form onSubmit={onClick}>
      {accountEmail && (
        <>
          <Typography variant="body1" align="center">
            {accountEmail}
          </Typography>

          {!requiresMfa && showPassword && (
            <TextField
              password
              {...getTextFieldHandlerProps('password')}
              autoComplete="current-password"
              label="Password"
            />
          )}

          {requiresMfa && (
            <>
              <TextField
                {...getTextFieldHandlerProps('code')}
                label="One Time Password"
                autoComplete="one-time-code"
              />
              {/* TODO: this isn't working consistently and not sure we want to keep it around */}
              {/* <Checkbox
                {...getSwitchHandlerProps('remember')}
                label="Remember this device for 30 days"
              /> */}
            </>
          )}
        </>
      )}

      {showMessage && (
        <Typography variant="body1" align="center">
          {authConfig.message}
        </Typography>
      )}

      {showSubmit && (
        <SubmitButton
          onClick={onClick}
          fullWidth
          startIcon={null}
          style={{ fontSize: 20, marginTop: '1rem' }}
          disabled={loading}
        >
          {accountEmail ? (requiresMfa ? 'Verify' : 'Login') : 'Next'}
        </SubmitButton>
      )}

      {authConfig.sso_url && !showMessage && (
        <>
          {!authConfig.sso && (
            <Typography
              variant="H5"
              display="block"
              style={{ textAlign: 'center', fontWeight: 'bold', margin: '1rem 0' }}
            >
              OR
            </Typography>
          )}

          <SubmitButton
            fullWidth
            style={{ fontSize: 20 }}
            color="secondary"
            startIcon={null}
            onClick={() => (window.location.href = authConfig.sso_url)}
          >
            {ssoLabel}
          </SubmitButton>
          <Typography
            variant="caption"
            display="block"
            style={{ textAlign: 'center' }}
          ></Typography>
        </>
      )}

      {accountEmail && (
        <Stack spacing={1} direction="row" sx={{ mt: 2 }}>
          {showPassword && (
            <CancelButton fullWidth onClick={handlePasswordReset} variant="text" disabled={loading}>
              Forgot Password?
            </CancelButton>
          )}

          <CancelButton fullWidth onClick={handleCancel} variant="text" disabled={loading}>
            Change account
          </CancelButton>
        </Stack>
      )}
      <Typography variant="caption" align="center" as="p" sx={{ mt: 2, p: 0 }}>
        By logging into Compliance, you agree to the
        <a
          href="https://www.signincompliance.com/terms-and-conditions"
          target="_blank"
          rel="noopener noreferrer"
        >{` Terms and Conditions `}</a>
        and
        <a
          href="https://www.signincompliance.com/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >{` Privacy Policy`}</a>
        .
        {authConfig.sso_url && !showMessage && (
          <>&nbsp;By using SSO, you will be logged in using the email from your SSO provider.</>
        )}
      </Typography>
    </form>
  );
};

export default connect(null, { login })(Form);
