import { withPermissionsAndPreferences } from 'lib/v1/hooks';

// Components
import AppContent from 'layouts/v2/AppContent';
import SecondaryNavLayout from 'layouts/v2/SecondaryNav';

import paths from 'config/paths/admin';
import { adminPaths as actionRequestsPaths } from 'config/paths/actionRequests';
import assetControlsPaths from 'config/paths/assetControls';
import badgeControlsPaths from 'config/paths/badgeControls';
import companyPaths from 'config/paths/company';
import contactsPaths from 'config/paths/contacts';
import containerControlsPaths from 'config/paths/containerControls';
import dashboardPaths from 'config/paths/dashboard';
import digitalFormsPaths from 'config/paths/digitalForms';
import digitalFormRequestsPaths from 'config/paths/digitalFormRequests';
import documentsPaths from 'config/paths/documents';
import importsPaths from 'config/paths/imports';
import integrationsPaths from 'config/paths/integrations';
import libraryPaths from 'config/paths/library';
import { adminPaths as riskPaths } from 'config/paths/risk';
import requirementControlsPaths from 'config/paths/requirementControls';
import requestTemplatesPaths from 'config/paths/requestTemplates';
import reportableTemplatesPaths from 'config/paths/reportableTemplates';
import rolesPaths from 'config/paths/customRoles';
import tagNamesPaths from 'config/paths/tagNames';
import { adminPaths as tasksPaths } from 'config/paths/tasks';
// import { adminPaths as visitPaths } from 'config/paths/visitRequests';

export const adminMenuData = ({ permissions, preferences }) => {
  const grouped = () => {
    return {
      data: [
        {
          label: 'Dashboard',
          endpoint: dashboardPaths.root,
          allowed: true,
        },
        {
          label: 'Action Requests',
          endpoint: actionRequestsPaths.root,
          allowed: permissions.actionRequests.view,
        },
        {
          label: 'Assets',
          endpoint: assetControlsPaths.root,
          allowed: permissions.assetControls.view,
        },
        {
          label: 'Badges',
          endpoint: badgeControlsPaths.root,
          allowed: permissions.badgeControls.view,
        },
        {
          label: 'Containers',
          endpoint: containerControlsPaths.root,
          allowed: permissions.containerControls.view,
        },
        {
          label: 'Contracts',
          endpoint: paths.contracts,
          allowed: permissions.contracts.view,
        },
        {
          label: 'Controls Nav',
          endpoint: requirementControlsPaths.root,
          allowed: permissions.requirementControls.view,
        },
        {
          label: 'Digital Forms',
          // Only admins can view the digital form templates
          endpoint: permissions.digitalFormTemplates.view
            ? digitalFormsPaths.root
            : digitalFormRequestsPaths.root,
          allowed: permissions.admin.view,
        },
        {
          label: 'Facilities',
          endpoint: paths.facilities,
          allowed: permissions.facilities.view,
        },
        {
          label: 'Request Templates',
          endpoint: requestTemplatesPaths.root,
          allowed: permissions.requestTemplates.view,
        },
        {
          label: 'Risk Insights',
          endpoint: riskPaths.root,
          allowed: preferences.riskChecks && permissions.admin.view,
        },
        {
          label: 'Tasks',
          endpoint: tasksPaths.root,
          allowed: permissions.tasks.update,
        },
      ],
      settings: [
        {
          label: 'Company',
          endpoint: companyPaths.root,
          allowed: permissions.company.view,
        },
        {
          label: 'Contact Users',
          endpoint: contactsPaths.root,
          allowed: permissions.contacts.view,
        },
        {
          label: 'Files',
          endpoint: documentsPaths.root,
          allowed: permissions.documents.view,
        },
        {
          label: 'Imports',
          endpoint: importsPaths.root,
          allowed: permissions.imports.view,
        },
        {
          label: 'Integrations',
          endpoint: integrationsPaths.root,
          allowed: permissions.secretKeys.list || permissions.clientCredentials.list,
        },
        {
          label: 'Library',
          endpoint: libraryPaths.root,
          allowed: permissions.library.list,
        },
        {
          label: 'Reportable Types',
          endpoint: reportableTemplatesPaths.root,
          allowed: permissions.reportableTemplates.view,
        },
        {
          label: 'Roles',
          endpoint: rolesPaths.root,
          allowed: permissions.customRoles.view,
        },
        {
          label: 'Tags',
          endpoint: tagNamesPaths.root,
          allowed: permissions.tagNames.view,
        },
      ],
    };
  };

  const allItems = () => {
    const { data, settings } = grouped();
    return [...data, ...settings].filter((route) => route.allowed);
  };

  return {
    allItems,
    grouped,
    defaultPath: dashboardPaths.root,
  };
};

export const AdminLayout = ({ children, permissions, preferences }) => {
  const { grouped } = adminMenuData({ permissions, preferences });

  return (
    <AppContent>
      <SecondaryNavLayout groupedItems={grouped()}>{children}</SecondaryNavLayout>
    </AppContent>
  );
};

export default withPermissionsAndPreferences(AdminLayout);
