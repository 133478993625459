import { withFeatureState } from './state';
import ApprovalIcon from '@mui/icons-material/Approval';

import GenericCard, {
  Subtitle,
  Value,
  Item,
  StyledAvatarGroup,
} from 'components/v2/PageElements/GenericCard';
import Tooltip from 'components/v2/FormElements/Tooltip';
import Button from 'components/v2/FormElements/Button';
import Avatar from 'components/v2/PageElements/Avatar';
import { WorkflowIcon } from 'components/v2/WorkflowControls/WorkflowCard';

const TicketCard = ({ ticket, history, paths }) => {
  const {
    approved,
    card_template = {},
    closed_at,
    color,
    icon,
    id,
    lane,
    subject,
    subject_user,
    submitted_at,
    submission_due_at,
    updated_at,
  } = ticket;

  const { name: templateName, approval_flow_enable } = card_template;

  const status = lane && lane.name;

  const approveColor = approved !== null ? (approved ? 'success' : 'error') : 'action';
  const approveStatus = approved !== null ? (approved ? 'Approved' : 'Denied') : 'Pending';
  const tooltipColor = (user) => (user ? 'hsl(0 0% 30%)' : 'hsl(0 0% 70%)');

  const onClick = () => {
    history.push(paths.resource.detail(id));
  };

  const isOverdue = () => {
    if (submission_due_at && !submitted_at) {
      return new Date(submission_due_at) < new Date();
    }
    return false;
  };

  const dateTitle = () => {
    if (!submitted_at) {
      return 'Due Date';
    } else if (closed_at) {
      return 'Closed';
    }

    return 'Submitted';
  };

  const dateValue = () => {
    if (!submitted_at) {
      return submission_due_at ? submission_due_at : 'None';
    } else if (closed_at) {
      return closed_at;
    }

    return submitted_at;
  };

  const dateContent = () => {
    return (
      <>
        <Subtitle className={isOverdue() ? 'alert' : ''}>{dateTitle()}</Subtitle>
        <Value>{dateValue()}</Value>
      </>
    );
  };

  const statusValue = () => {
    if (!submitted_at) {
      return 'Draft';
    } else if (closed_at) {
      return 'Closed';
    } else if (status) {
      return status;
    }

    return 'Active';
  };

  const statusContent = () => {
    return (
      <>
        <Subtitle>Status</Subtitle>
        <Value>{statusValue()}</Value>
      </>
    );
  };

  return (
    <GenericCard
      icon={<WorkflowIcon color={color} icon={icon} />}
      title={templateName}
      subtitle={subject}
      onCardClick={onClick}
      dateValue={updated_at}
      action={
        approval_flow_enable && (
          <Tooltip
            arrow
            background={'white'}
            color={tooltipColor(approved !== null)}
            title={`Approval Status: ${approveStatus}`}
          >
            <div style={{ display: 'flex' }}>
              <ApprovalIcon aria-label="approver" color={approveColor} />
            </div>
          </Tooltip>
        )
      }
      button={
        !submitted_at ? (
          <Button variant="contained" color="tickets">
            Finish Submission
          </Button>
        ) : (
          <Button variant="text">See Details</Button>
        )
      }
    >
      <Item>{dateContent()}</Item>
      <Item>{statusContent()}</Item>
      <Item>
        <StyledAvatarGroup sx={{ paddingTop: '4px' }} max={4}>
          <Avatar sm rightAlign profile={subject_user}></Avatar>
        </StyledAvatarGroup>
      </Item>
    </GenericCard>
  );
};

export default withFeatureState(TicketCard);
