import React from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import raygun from 'raygun4js';

import LoginPageV2 from 'components/v2/Authentication/Login';

import { auth_failure, add_message } from 'store/v1/app/actions';
import { DetailPath } from 'components/v2/Email/Routes';
import { loginSuccess } from 'store/v1/authentication/actions';
import { displayError } from 'lib/v1/error-handling';
import Loader from 'components/v2/Loader';

import { newRequest } from 'lib/v2/request';
import { apiHosts } from 'config/index';

import { defaultPath } from 'config/paths';

export const getSsoRedirectCookiePath = () => {
  let value;
  let module = '';
  let id = '';

  try {
    value = document.cookie
      .split('; ')
      .find((row) => row.startsWith('_redirect='))
      .split('=')[1];
  } catch (error) {}

  if (value) [module, id] = value?.split('-');

  const path = DetailPath({
    match: {
      params: {
        id,
        module,
      },
    },
  });

  return value ? path : null;
};

export const clearSsoRedirectCookiePath = () => {
  const cookieDomain = import.meta.env.REACT_APP_COOKIE_DOMAIN ?? 'localhost';
  console.log('clearing sso on cookieDomain:', cookieDomain); // eslint-disable-line
  document.cookie = `_redirect=; Domain=${cookieDomain}; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
};

const getParamMap = () => {
  const params = {};
  if (window.location.search) {
    const fromUrl = window.location.search.substring(1);
    const parts = fromUrl.split('&');
    for (let i = 0; i < parts.length; i++) {
      const nv = parts[i].split('=');
      if (!nv[0]) {
        continue;
      }
      params[nv[0]] = nv[1] || true;
    }
  }
  return params;
};

const SAMLAutoLogin = ({ history, dispatch }) => {
  const [error, setError] = React.useState(false);

  // componentDidMount
  React.useEffect(() => {
    const params = getParamMap();

    if (!params) {
      history.push('/');
      return;
    }

    if (params?.errors) {
      let errors = JSON.parse(decodeURIComponent(params.errors));
      errors = errors.map((e) => e.replace('+', ' '));
      //history.push('/');
      setError(true);
      dispatch(add_message('login_error', errors));
    } else if (!params || params.state !== 'success' || !params.user) {
      setError(true);
      const loginErrorTxt = 'Error Logging in';
      dispatch(displayError(loginErrorTxt));
      dispatch(auth_failure(loginErrorTxt));
      dispatch(add_message('login_error', loginErrorTxt));
      history.push('/');
    } else if (params.state === 'success' && params.user) {
      setError(false);
      const user = JSON.parse(decodeURIComponent(params.user));
      const app = {
        ...user,
        uid: params.uid,
        authenticated: true,
      };

      Object.keys(app).forEach((key) => {
        window.localStorage.setItem(key, app[key]);
      });

      window.localStorage.setItem('app', JSON.stringify(app));
      window.localStorage.setItem('samlIsAuthenticating', true);

      const requests = apiHosts.map((host) => newRequest({ host }).get(`/v1/users/${user.id}`));
      Promise.allSettled(requests)
        .then((responses) => {
          const allRequestsFailed = responses.map((r) => r.status).every((s) => s === 'rejected');
          if (allRequestsFailed)
            throw new Error('SAMLAutoLogin: all user lookup API requests failed');

          const firstSuccessfulResponse = responses.find((r) => r.status === 'fulfilled');
          const userData = firstSuccessfulResponse.value.body.user.data;

          const authServer = new URL(firstSuccessfulResponse.value.req.url).origin;
          window.localStorage.setItem('auth_host', authServer); // Needed to determine which API to use: AWS vs OCI
          window.localStorage.setItem('auth_email', userData.email);

          window.localStorage.removeItem('samlIsAuthenticating');
          window.localStorage.setItem('profile', JSON.stringify(userData));
          dispatch(loginSuccess(userData));
          const redirectPath = getSsoRedirectCookiePath();
          setTimeout(clearSsoRedirectCookiePath, 5);
          history.push(redirectPath || defaultPath);
        })
        .catch((e) => {
          console.error(e); // eslint-disable-line
          raygun('setUser', {
            identifier: user.id,
            isAnonymous: false,
            email: user.email,
          });
          raygun('send', e);
          dispatch(displayError(e, 'error'));
        });
    }
  }, [history, dispatch]);

  return (
    <>
      {!error && <Loader text={'Authenticating...'} rotate={true} />}
      {error && <LoginPageV2 history={history} />}
    </>
  );
};

export default connect()(withRouter(SAMLAutoLogin));
